<template>
  <div class="card flex justify-content-center">
    <Dialog
      modal
      header="Validate CSV File"
      :style="{ width: '50vw' }"
      :visible="is_visible"
      closeIcon="false"
    >
      <div class="card flex mt-2">
        <InputText type="text" placeholder="Job Name" v-model="label" />
      </div>
      <div class="card flex mt-2">
        <InputText type="text" placeholder="API Key" v-model="api_key" />
      </div>
      <div class="flex align-items-center mt-2">
        <Checkbox v-model="debug" inputId="debug" name="debug" :binary="true" />
        <label for="debug" class="ml-2"> Debug </label>
      </div>
      <div class="flex align-items-center mt-2">
        <Checkbox v-model="cache" inputId="cache" name="cache" :binary="true" />
        <label for="cache" class="ml-2"> No Cache</label>
      </div>

      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="this.cancel" text />
        <Button
          label="Select CSV"
          icon="pi pi-check"
          @click="process_csv"
          autofocus
        />
      </template>
    </Dialog>
    <template>
      <input
        id="validate_csv_selector"
        type="file"
        @change="this.submit"
        accept="*.csv"
      />
    </template>
    <Toast />
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Toast from "primevue/toast";

import * as API from "../../api";
const IPQS = new API.ipqs();
const CSV = new API.csv();
const LOGS = new API.logs();
const REPORTS = new API.reports();

// import { toRaw } from "vue";

export default {
  name: "ValidateCSVCard",
  components: {
    Dialog,
    Button,
    InputText,
    Checkbox,
    Toast,
  },
  data() {
    return {
      visible: true,
      ipqs_data: "",
      api_key: "",
      debug: false,
      cache: false,
      label: "",
    };
  },
  mounted() {
    this.api_key = this.store_api_key;
  },
  // created: {},
  computed: {
    is_visible() {
      return this.$store.getters.get_is_validate_csv_modal_visible;
    },
    store_api_key() {
      return this.$store.getters.get_api_key;
    },
    throttle() {
      return parseInt(process.env.VUE_APP_API_THROTTLE);
    },
    fingerprint() {
      return this.$store.getters.get_fingerprint;
    },
  },
  watch: {
    store_api_key(new_value, old_value) {
      if (new_value !== old_value) {
        this.api_key = new_value;
      }
    },
  },
  methods: {
    process_csv() {
      document.getElementById("validate_csv_selector").value = null;
      document.getElementById("validate_csv_selector").click();
    },
    cancel() {
      this.$store.commit("set_is_validate_csv_modal_visible");
    },
    async submit(event) {
      this.$store.commit("enable_is_progress_bar_visible");
      this.$store.commit("set_is_validate_csv_modal_visible");
      this.display_toast("Getting data");
      let total_validation_types = 0;
      let converted_data = await CSV.convert_csv_to_object(event);
      LOGS.log_to_console("converted_data", converted_data);
      const IP_ADDRESSES = await this.seperate_data(
        converted_data,
        "ip_address"
      );
      const PHONE_NUMBERS = await this.seperate_data(converted_data, "phone");
      const EMAIL_ADDRESSES = await this.seperate_data(
        converted_data,
        "email_address"
      );

      if (IP_ADDRESSES.length > 0) total_validation_types++;
      if (PHONE_NUMBERS.length > 0) total_validation_types++;
      if (EMAIL_ADDRESSES.length > 0) total_validation_types++;

      if (total_validation_types > 0) {
        LOGS.log_to_console("IP_ADDRESSES", IP_ADDRESSES);
        LOGS.log_to_console("PHONE_NUMBERS", PHONE_NUMBERS);
        LOGS.log_to_console("EMAIL_ADDRESSES", EMAIL_ADDRESSES);

        if (IP_ADDRESSES.length > 0) {
          converted_data = await this.combine_data(
            IP_ADDRESSES,
            "ip",
            converted_data,
            "ip_address"
          );
        }

        if (PHONE_NUMBERS.length > 0) {
          converted_data = await this.combine_data(
            PHONE_NUMBERS,
            "phone",
            converted_data,
            "phone"
          );
        }

        if (EMAIL_ADDRESSES.length > 0) {
          converted_data = await this.combine_data(
            EMAIL_ADDRESSES,
            "email",
            converted_data,
            "email_address"
          );
        }

        LOGS.log_to_console("converted_data", converted_data);
        const FORMATTED_DATA = await this.format_ipqs_response(converted_data);
        const FILTERED_DATA = FORMATTED_DATA.filter((entry) => {
          if (entry !== undefined) return entry;
        });
        LOGS.log_to_console("FORMATTED_DATA", FORMATTED_DATA);
        LOGS.log_to_console("FILTERED_DATA", FILTERED_DATA);

        const REPORT = await REPORTS.create_reports(FILTERED_DATA);
        REPORT.ip_reports.raw_data = FILTERED_DATA;
        REPORT.ip_reports.label = this.label;
        if (total_validation_types > 1) {
          REPORT.ip_reports.combined_raw_data = converted_data;
          REPORT.ip_reports.is_combined_report = true;
        }
        LOGS.log_to_console("REPORT", REPORT);
        this.$store.commit("set_reports", REPORT);
        this.display_toast("Complete");
        this.$store.commit("disable_is_progress_bar_visible");
      } else {
        this.display_toast(
          "Nothing to validate. Make sure CSV headers are labeled properly."
        );
      }
    },
    /* eslint-disable */
    combine_data(raw_data, validation_type, converted_data, key) {
      return new Promise(async (resolve) => {
        const data = await this.validate_data(
          [],
          raw_data,
          validation_type,
          this.throttle
        );
        for (let outside = 0; outside < data.length; outside++) {
          for (let inside = 0; inside < data[outside].length; inside++) {
            for (
              let data_entry = 0;
              data_entry < converted_data.length;
              data_entry++
            ) {
              if (
                data[outside][inside]["data"] ===
                converted_data[data_entry][key]
              ) {
                const ENTRY = {
                  [key]: converted_data[data_entry][key],
                  ipqs_response: data[outside][inside],
                };
                converted_data[data_entry][key] = ENTRY;
              }
            }
          }
          if (outside === data.length - 1) {
            resolve(converted_data);
          }
        }
      });
    },
    seperate_data(data, key) {
      return new Promise(async (resolve) => {
        const FILTERED_DATA = [];
        await Promise.all(
          data.map(async (entry, index) => {
            if (entry[key]) {
              if (
                !entry[key].includes("N/A") &&
                !entry[key].includes("/r") &&
                entry[key] !== "" &&
                entry[key] !== null &&
                entry[key] !== undefined
              ) {
                FILTERED_DATA.push(entry[key]);
              }
            }
          })
        );
        resolve(
          FILTERED_DATA.filter(function (a) {
            return a !== "";
          })
        );
      });
    },
    validate_data(response_buffer, ipqs_data, endpoint, throttle) {
      return new Promise(async (resolve) => {
        if (ipqs_data.length > 0) {
          let progress_bar_value = parseInt(
            (response_buffer.length / ipqs_data.length) * 100
          );
          this.$store.commit("set_progress_bar", progress_bar_value);
          let data;
          if (throttle > ipqs_data.length) {
            data = ipqs_data.splice(0, ipqs_data.length);
          } else {
            data = ipqs_data.splice(0, throttle);
          }
          const PAYLOAD = {
            data: data,
            api_key: this.api_key,
            debug: this.debug,
            cache: this.cache,
            endpoint: endpoint,
            fingerprint: this.fingerprint,
          };
          const RESPONSE_DATA = await IPQS.validate_data(PAYLOAD);
          if (RESPONSE_DATA.length > 0) {
            const IS_ERROR = RESPONSE_DATA.find((key) => {
              if (key.status && key.status === "fail") {
                return true;
              }
            });
            if (IS_ERROR !== undefined && IS_ERROR.status === "fail") {
              this.display_toast(IS_ERROR.message);
              resolve([]);
            } else {
              response_buffer.push(RESPONSE_DATA);
              const RESPONSE = this.validate_data(
                response_buffer,
                ipqs_data,
                endpoint,
                throttle
              );
              resolve(RESPONSE);
            }
          } else {
            const RESPONSE = this.validate_data(
              response_buffer,
              ipqs_data,
              endpoint,
              throttle
            );
            resolve(RESPONSE);
          }
        } else {
          resolve(response_buffer);
        }
      });
    },
    format_ipqs_response(data) {
      return new Promise((resolve) => {
        const RESPONSE = [];
        for (let array_entry = 0; array_entry < data.length; array_entry++) {
          if (data[array_entry].ip_address)
            RESPONSE.push(data[array_entry].ip_address.ipqs_response);
          if (data[array_entry].email_address)
            RESPONSE.push(data[array_entry].email_address.ipqs_response);
          if (data[array_entry].phone)
            RESPONSE.push(data[array_entry].phone.ipqs_response);
          if (array_entry === data.length - 1) {
            resolve(RESPONSE);
          }
        }
      });
    },
    display_toast(message) {
      this.$toast.add({
        severity: "info",
        detail: message,
        life: 6200,
      });
    },
  },
};
</script>

<style></style>
