const SERVICE = require("../services/list_service");
/* eslint-disable */
async function fetch_list_data(data) {
  return new Promise(async (resolve) => {
    const RESPONSE = await SERVICE.fetch_list_data(data);
    resolve(RESPONSE);
  });
}

// async function fetch_bulk_list_data(payload) {
//   return new Promise(async (resolve) => {
//     const DATA = payload.data;
//     const API_KEY = payload.api_key;
//     const DEBUG = payload.debug;
//     const CACHE = payload.cache;
//     const RESPONSE = await SERVICE.bulk_validate_IPQS_data(
//       DATA,
//       API_KEY,
//       DEBUG,
//       CACHE
//     );
//     resolve(RESPONSE);
//   });
// }

function format_list_response(data) {
  return new Promise((resolve) => {
    const RESPONSE = [];
    for (let array_entry = 0; array_entry < data.length; array_entry++) {
      for (let line = 0; line < data[array_entry].length; line++) {
        RESPONSE.push(data[array_entry][line].ipqs);
      }
      if (array_entry === data.length - 1) {
        resolve(RESPONSE);
      }
    }
  });
}

module.exports = {
  fetch_list_data,
  // fetch_bulk_list_data,
  format_list_response,
};
