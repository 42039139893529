const axios = require("axios");
const store = require("../../store/index.js");

const INTERVAL_MS = 10;

const api = axios.create({});
let PENDING_REQUESTS = 0;
/* eslint-disable */

/**
 * Axios Request Interceptor
 */
api.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < process.env.VUE_APP_API_THROTTLE) {
        PENDING_REQUESTS++;
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_MS);
  });
});
/**
 * Axios Response Interceptor
 */
api.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response);
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(error);
  }
);
/* eslint-disable */
async function validate_data(data) {
  return new Promise(async (resolve) => {
    const ORIGINAL_DATA = data.data;
    const API_KEY = data.api_key;
    const DEBUG = data.debug;
    const CACHE = data.cache;
    const IPQS_ENDPOINT = data.endpoint;
    const ENDPOINT = store.default.getters.get_endpoint;
    // Not included in data
    let URL = `${ENDPOINT}/ipqs/`;
    if (DEBUG || CACHE) {
      URL = `${URL}fetch_auth`;
    } else {
      URL = `${URL}fetch`;
    }
    const IPQS_DATA = await Promise.all(
      ORIGINAL_DATA.map(async (entry) => {
        const DATA = entry;
        const BASE_IPQS_URL = `https://www.ipqualityscore.com/api/json`;
        const ASSEMBLED_IPQS_URL = `${BASE_IPQS_URL}/${IPQS_ENDPOINT}/${API_KEY}/${DATA}`;
        const DEBUG_CACH_PARAMS = `?debug=${DEBUG}&nocache=${CACHE}`;
        const PAYLOAD = {
          debug: DEBUG,
          cache: CACHE,
          url: ASSEMBLED_IPQS_URL,
          debut_cache_params: DEBUG_CACH_PARAMS,
          fingerprint: data.fingerprint,
        };
        console.log("PAYLOAD: ", PAYLOAD);
        try {
          const IPQS_RESPONSE = await axios.post(URL, PAYLOAD);
          IPQS_RESPONSE.data.data = DATA;
          return IPQS_RESPONSE.data;
        } catch (error) {
          const RESPONSE = {
            code: "1",
            status: "fail",
            endpoint: "/auth",
            message: "Error calling API",
          };
          return RESPONSE;
        }
      })
    );

    resolve(IPQS_DATA);
  });
}

module.exports = {
  validate_data,
};
