<template>
  <div class="card flex justify-content-center">
    <Dialog
      modal
      header="Enter List Info"
      :style="{ width: '50vw' }"
      :visible="is_visible"
      closeIcon="false"
    >
      <div class="card m-4" v-if="display_progressbar">
        <ProgressBar :value="progressbar_value"></ProgressBar>
      </div>
      <div class="card flex mt-2">
        <InputText
          type="text"
          placeholder="Report Name"
          v-model="report_name"
        />
      </div>
      <div class="card flex mt-2">
        <InputText type="text" placeholder="API Key" v-model="api_key" />
      </div>

      <!-- <div class="flex w-full">
        <div class="card flex mt-2 mr-2">
          <InputText
            type="number"
            placeholder="Max Fraud Score"
            v-model="max_fraud"
            min="0"
            max="100"
          />
        </div>
        <h4>Max Fraud Score</h4>
      </div>

      <div class="flex w-full">
        <div class="card flex mt-2 mr-2">
          <InputText
            type="number"
            placeholder="Min Fraud Score"
            v-model="min_fraud"
            min="0"
            max="100"
          />
        </div>
        <h4>Min Fraud Score</h4>
      </div> -->

      <span class="p-float-label mt-2">
        <Calendar
          v-model="start_date"
          dateFormat="yy-mm-dd"
          showIcon
          inputId="start_date"
        />
        <label for="start_date">Start Date</label>
      </span>
      <span class="p-float-label mt-2">
        <Calendar
          v-model="end_date"
          dateFormat="yy-mm-dd"
          showIcon
          inputId="end_date"
        />
        <label for="end_date">Start Date</label>
      </span>
      <div class="card flex mt-2" v-if="this.type !== 'mobiletracker'">
        <Textarea
          v-model="conditions"
          rows="10"
          cols="50"
          placeholder="Conditions (Eg. device ID, IP, custom variable, etc...), all same type, one per line. Leave blank for none."
        />
      </div>
      <div
        class="flex flex-wrap gap-3 mt-2"
        v-if="this.type !== 'mobiletracker'"
      >
        <div class="flex w-full">
          <h4>Condition Type</h4>
        </div>
        <div class="flex align-items-center">
          <RadioButton
            v-model="condition_type"
            inputId="condition_type1"
            name="IP"
            value="ip"
          />
          <label for="condition_type1" class="ml-2">IP</label>
        </div>
        <div class="flex align-items-center">
          <RadioButton
            v-model="condition_type"
            inputId="condition_type2"
            name="device_id"
            value="device_id"
          />
          <label for="condition_type2" class="ml-2">Device ID</label>
        </div>
      </div>
      <div class="flex flex-wrap gap-3 mt-2">
        <div class="flex w-full">
          <h4>Data Type</h4>
        </div>
        <div class="flex align-items-center">
          <RadioButton
            v-model="type"
            inputId="type1"
            name="proxy"
            value="proxy"
          />
          <label for="type1" class="ml-2">proxy</label>
        </div>
        <div class="flex align-items-center">
          <RadioButton
            v-model="type"
            inputId="type2"
            name="email"
            value="email"
          />
          <label for="type2" class="ml-2">email</label>
        </div>
        <div class="flex align-items-center">
          <RadioButton
            v-model="type"
            inputId="type3"
            name="phone"
            value="phone"
          />
          <label for="type3" class="ml-2">phone</label>
        </div>
        <div class="flex align-items-center">
          <RadioButton
            v-model="type"
            inputId="type4"
            name="devicetracker"
            value="devicetracker"
          />
          <label for="type4" class="ml-2">device tracker</label>
        </div>
        <div class="flex align-items-center">
          <RadioButton
            v-model="type"
            inputId="type5"
            name="mobiletracker"
            value="mobiletracker"
          />
          <label for="type5" class="ml-2">mobile tracker</label>
        </div>
      </div>

      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="this.cancel" text />
        <Button
          label="Submit"
          icon="pi pi-check"
          @click="this.submit"
          autofocus
        />
      </template>
    </Dialog>
    <Toast />
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import RadioButton from "primevue/radiobutton";
import Textarea from "primevue/textarea";
import Calendar from "primevue/calendar";
import Toast from "primevue/toast";
import ProgressBar from "primevue/progressbar";

// import { toRaw } from "vue";
import * as API from "./../../api";
// const CSV = new API.csv();
const REPORTS = new API.reports();
const LIST = new API.list();

export default {
  name: "ListCard",
  components: {
    Dialog,
    Button,
    InputText,
    RadioButton,
    Textarea,
    Calendar,
    Toast,
    ProgressBar,
  },
  data() {
    return {
      progressbar_value: 0,
      display_progressbar: false,
      visible: true,
      debug: false,
      cache: false,
      type: "proxy",
      api_key: "",
      conditions: "",
      condition_type: "ip",
      start_date: "",
      end_date: "",
      report_name: "",
      // min_fraud: 0,
      // max_fraud: 100,
      current_page: 1,
      max_page: 1,
      total_records: 0,
      pulled_records: 0,
      is_working_pages: false,
    };
  },
  mounted() {},
  // created: {},
  computed: {
    is_visible() {
      return this.$store.getters.get_is_list_modal_visible;
    },
    store_api_key() {
      return this.$store.getters.get_api_key;
    },
  },
  watch: {
    store_api_key(new_value, old_value) {
      if (new_value !== old_value) {
        this.api_key = new_value;
      }
    },
  },
  methods: {
    async submit() {
      this.page = 1;
      let does_pass = true;
      let message = "Error:  ";

      if (this.max_fraud < this.min_fraud) {
        does_pass = false;
        message = `${message} Max fraud score is lowering then min fraud score. `;
      }
      if (this.end_date === "") {
        does_pass = false;
        message = `${message} Missing end date. `;
      }
      if (this.start_date === "") {
        does_pass = false;
        message = `${message} Missing start date. `;
      }
      if (this.end_date < this.start_date) {
        does_pass = false;
        message = `${message} End date is earlier than start date. `;
      }
      if (this.api_key === "") {
        does_pass = false;
        message = `${message} Missing API key. `;
      }
      if (does_pass) {
        this.display_progressbar = true;
        const CONDITIONS = this.conditions.split("\n");
        this.total_records = CONDITIONS.length;
        const RAW_DATA = await this.fetch_data(CONDITIONS, 0, []);
        if (RAW_DATA.length > 0) {
          console.log("RAW_DATA: ", RAW_DATA);
          const DATA = await this.normalize_results(RAW_DATA);
          console.log("DATA: ", DATA);
          this.$store.commit("set_raw_data", DATA);
          const REPORT = await REPORTS.create_reports(DATA);
          REPORT.ip_reports.raw_data = DATA;
          REPORT.ip_reports.label = this.report_name;
          console.log("DATA: ", DATA);
          this.$toast.add({
            severity: "success",
            detail: "Data import successful",
            life: 3200,
          });

          this.$store.commit("set_reports", REPORT);
        }
        this.display_progressbar = false;
        this.$store.commit("set_is_list_modal_visible");
      } else {
        this.display_toast(message);
      }
    },
    /* eslint-disable */
    normalize_results(data) {
      return new Promise(async (resolve) => {
        let temp_buffer = [];
        for (let block = 0; block < data.length; block++) {
          for (let line = 0; line < data[block].length; line++) {
            temp_buffer.push(data[block][line]);
          }
          if (block === data.length - 1) {
            resolve(temp_buffer);
          }
        }
      });
    },
    fetch_data(DATA, rotation, buffer) {
      return new Promise(async (resolve) => {
        if (
          rotation < DATA.length ||
          (this.is_working_pages && this.current_page <= this.max_page)
        ) {
          const CONDITION = DATA[rotation];
          const PAYLOAD = {
            api_key: this.api_key,
            start_date: this.start_date,
            end_date: this.end_date,
            min_fraud: this.min_fraud,
            max_fraud: this.max_fraud,
            condition_type: this.condition_type,
            condition: CONDITION,
            type: this.type,
            page: this.current_page,
          };
          const IPQS_DATA = await LIST.fetch_list_data(PAYLOAD);
          if (IPQS_DATA.success) {
            this.pulled_records =
              this.pulled_records + IPQS_DATA.requests.length;
            this.progressbar_value = parseInt(
              (this.pulled_records / this.total_records) * 100
            );
            this.max_page = IPQS_DATA.total_pages;
            if (this.current_page === 1 || this.total_records === 0) {
              this.total_records =
                this.total_records + IPQS_DATA.total_requests;
            }
          } else {
            this.display_toast(
              `Error fetching page ${this.current_page}. Attempting next page.`
            );
          }

          this.current_page++;
          if (this.current_page <= this.max_page) {
            this.is_working_pages = true;
          } else {
            this.is_working_pages = false;
            this.current_page = 1;
            this.max_page = 1;
          }
          if (!this.is_working_pages) {
            rotation++;
          }
          buffer.push(IPQS_DATA.requests);
          const RESPONSE = await this.fetch_data(DATA, rotation, buffer);
          resolve(RESPONSE);
        } else {
          resolve(buffer);
        }
      });
    },
    cancel() {
      this.$store.commit("set_is_list_modal_visible");
    },
    display_toast(message) {
      this.$toast.add({
        severity: "info",
        detail: message,
        life: 6200,
      });
    },
  },
};
</script>

<style></style>
