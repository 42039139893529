<template>
  <div>
    <Card class="mt-2 shadow-5">
      <template #content>
        <div
          class="flex justify-content-center flex-wrap card-container mt-4 mb-3"
        >
          <div class="flex justify-content-left mr-4">
            <Tag
              severity="warning"
              class="pr-4 pl-4"
              :value="this.report_label"
            ></Tag>
          </div>
          <div
            v-for="(record, key) in this.totalRecords"
            :key="key"
            class="align-items-center justify-content-center ml-2 mr-2"
          >
            <Button
              label="Primary"
              text
              raised
              @click="update_page(key)"
              :class="this.is_selected(key)"
            >
              {{ record }}
            </Button>
          </div>
        </div>
        <div class="card">
          <TabView>
            <TabPanel header="Raw Data">
              <DataTable
                :value="table_data"
                ref="dt"
                showGridlines
                stripedRows
                tableStyle="min-width: 50rem"
                paginator
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50, 100]"
                :class="`p-datatable-sm`"
                resizableColumns
                columnResizeMode="expand"
                exportFilename="Raw Data"
              >
                <template #header>
                  <div class="grid w-auto">
                    <div style="text-align: left"></div>
                    <h3 class="ml-3 mr-3">Raw Data Table</h3>
                    <div style="text-align: right">
                      <Button
                        icon="pi pi-external-link"
                        size="large"
                        link
                        @click="exportCSV($event)"
                      />
                    </div>
                  </div>
                </template>
                <Column
                  v-for="(key, index) of selected_columns"
                  :key="key + '_' + index"
                  :field="key"
                  :header="key"
                  sortable
                >
                  <template
                    #body="slotProps"
                    v-if="this.is_alertable_field(key)"
                  >
                    <div :class="is_alertable(slotProps.data)">
                      {{ slotProps.data[key] }}
                    </div>
                  </template>
                  <template #body="slotProps" v-else-if="key === 'debug_log'">
                    <div>
                      <Button
                        text
                        @click="display_debug_log(slotProps.data[key])"
                        >View Log</Button
                      >
                    </div>
                  </template>
                </Column>
              </DataTable>
            </TabPanel>
            <TabPanel
              header="Combined Raw Data"
              :disabled="this.is_combined_report_tab_enabled"
            >
              <div class="card">
                <DataView
                  :value="this.combined_table_data"
                  paginator
                  paginatorPosition="both"
                  :rows="10"
                >
                  <template #list="slotProps">
                    <div class="col-12">
                      <div class="card">
                        <Splitter style="height: 180px" class="mb-5">
                          <SplitterPanel
                            class="flex align-items-center justify-content-left"
                          >
                            <div
                              v-if="slotProps.data.email_address.email_address"
                              class="m-2"
                            >
                              <h3>
                                {{ slotProps.data.email_address.email_address }}
                              </h3>
                              <div>
                                <h4>
                                  Fraud Score:
                                  {{
                                    slotProps.data.email_address.ipqs_response
                                      .fraud_score
                                  }}
                                </h4>
                                <Button
                                  outlined
                                  size="small"
                                  @click="
                                    display_data_table(
                                      slotProps.data.email_address.ipqs_response
                                    )
                                  "
                                >
                                  View Data
                                </Button>
                              </div>
                            </div>
                          </SplitterPanel>

                          <SplitterPanel
                            class="flex align-items-center justify-content-left"
                          >
                            <div v-if="slotProps.data.phone.phone" class="m-2">
                              <h4>
                                {{ slotProps.data.phone.phone }}
                              </h4>
                              <div>
                                <h4>
                                  Fraud Score:
                                  {{
                                    slotProps.data.phone.ipqs_response
                                      .fraud_score
                                  }}
                                </h4>
                                <Button
                                  outlined
                                  size="small"
                                  @click="
                                    display_data_table(
                                      slotProps.data.phone.ipqs_response
                                    )
                                  "
                                >
                                  View Data
                                </Button>
                              </div>
                            </div>
                          </SplitterPanel>

                          <SplitterPanel
                            class="flex align-items-center justify-content-left"
                          >
                            <div
                              v-if="slotProps.data.ip_address.ip_address"
                              class="m-2"
                            >
                              <h4>
                                {{ slotProps.data.ip_address.ip_address }}
                              </h4>
                              <div>
                                <h4>
                                  Fraud Score:
                                  {{
                                    slotProps.data.ip_address.ipqs_response
                                      .fraud_score
                                  }}
                                </h4>
                                <Button
                                  outlined
                                  size="small"
                                  @click="
                                    display_data_table(
                                      slotProps.data.ip_address.ipqs_response
                                    )
                                  "
                                >
                                  View Data
                                </Button>
                              </div>
                            </div>
                          </SplitterPanel>
                        </Splitter>
                      </div>
                    </div>
                  </template>
                </DataView>
              </div>
            </TabPanel>
          </TabView>
        </div>
      </template>
    </Card>
    <Dialog
      v-model:visible="visible"
      modal
      header="Debug Log"
      :style="{ width: '80vw' }"
    >
      <table class="w-full p-4">
        <tr
          v-for="(entry, id) in this.debug_log"
          :key="id"
          class="w-full mt-16"
        >
          <th
            class="w-4 text-left border-bottom-2 m-2 pt-4 pb-2"
            v-if="entry.System"
          >
            {{ entry.System }}
          </th>
          <th class="w-4 text-left border-bottom-2 m-2 pt-4 pb-2" v-else>
            {{ entry.child }}
          </th>
          <th class="w-8 text-left border-bottom-2 m-2 pt-4 pb-2">
            {{ entry.Message }}
          </th>
        </tr>
      </table>
    </Dialog>
    <Dialog
      v-model:visible="is_data_table_visible"
      modal
      header="IPQS Response"
      :style="{ width: '80vw' }"
    >
      <table style="text-align: left">
        <tr v-for="(line, id) in this.table_data_view" :key="id">
          <th class="w-6 m-1">{{ id }}</th>
          <th class="w-6 m-1">{{ line }}</th>
        </tr>
      </table>
    </Dialog>
  </div>
</template>

<script>
import { toRaw } from "vue";
// @ is an alias to /src
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Card from "primevue/card";
import Button from "primevue/button";
import Tag from "primevue/tag";
import Dialog from "primevue/dialog";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import DataView from "primevue/dataview";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";

export default {
  name: "RawDataView",
  components: {
    DataTable,
    Column,
    Card,
    Button,
    Tag,
    Dialog,
    TabView,
    TabPanel,
    DataView,
    Splitter,
    SplitterPanel,
  },
  data() {
    return {
      debug_log: [],
      is_data_table_visible: false,
      table_data_view: {},
      is_combined_report_tab_enabled: true,
      visible: false,
      entry: 0,
      report_label: "",
      raw_data: [],
      table_data: [],
      combined_table_data: [],
      buttonWidth: 200,
      keys: [],
      selected_columns: null,
      columns: null,
      alertable_fields: ["fraud_score", "vpn", "bot_status", "proxy"],
    };
  },
  mounted() {
    if (this.$route.query.entry) {
      this.entry = this.$route.query.entry;
    }
    this.set_data();
  },
  // created: {},
  computed: {
    selected_data() {
      return toRaw(this.$store.getters.get_reports[this.entry]);
    },
    totalRecords() {
      return toRaw(this.$store.getters.get_reports.length);
    },
  },
  watch: {
    selected_data: {
      handler(newValue, oldValue) {
        console.log("watch_test");
        if (newValue !== oldValue) {
          this.set_data();
        }
      },
      deep: true,
    },
  },
  methods: {
    display_data_table(data) {
      this.is_data_table_visible = true;
      this.table_data_view = data;
    },
    display_debug_log(data) {
      this.debug_log = data;
      this.visible = true;
    },
    update_page(page) {
      this.update_page_count();
      this.set_data();
      this.entry = page;
    },
    update_page_count() {
      if (this.$store.getters.get_reports) {
        this.totalRecords = toRaw(this.$store.getters.get_reports).length;
      }
    },
    is_selected(key) {
      if (key === this.entry) {
        return " bg-primary";
      } else {
        return "";
      }
    },
    set_data() {
      if (this.$store.getters.get_reports.length > 0) {
        this.update_page_count();
        if (this.selected_data.ip_reports.is_combined_report) {
          this.is_combined_report_tab_enabled = false;
          this.combined_table_data =
            this.selected_data.ip_reports.combined_raw_data;
          console.log("this.combined_table_data: ", this.combined_table_data);
        } else {
          this.is_combined_report_tab_enabled = true;
        }
        this.report_label = this.selected_data.ip_reports.label;
        this.table_data = this.selected_data.ip_reports.raw_data;
        this.get_keys();
        this.is_table_visible = true;
      }
    },
    is_alertable_field(key) {
      return this.alertable_fields.includes(key);
    },
    is_alertable(data) {
      for (let key = 0; key < this.keys.length; key++) {
        // if (data[key] || parseInt(data[key]) > 90) {
        return [
          "",
          {
            "bg-red-100 text-black-900": parseInt(data.fraud_score) >= 90,
            "bg-yellow-100 text-black-900":
              parseInt(data.fraud_score) >= 80 &&
              parseInt(data.fraud_score) < 90,
          },
        ];
        // }
      }
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    get_keys() {
      if (this.table_data.length > 0) {
        this.keys = Object.keys(this.table_data[0]);
        this.selected_columns = Object.keys(this.table_data[0]);
      }
    },
  },
};
</script>

<style>
.dataAlert {
  background-color: red;
}
.tableHover:hover {
  background-color: #e6e6e6;
}
.popOutMenu {
  position: absolute;
  /* left: v-bind(mouseX);
  top: v-bind(mouseY); */
  left: v-bind(mouseX);
  top: v-bind(mouseY);
  z-index: 1500;
}
</style>
