<template>
  <div class="card flex justify-content-center">
    <Dialog
      modal
      header="Enter API Key"
      :style="{ width: '50vw' }"
      :visible="is_visible"
      closeIcon="false"
    >
      <div class="card flex mt-2">
        <InputText type="text" placeholder="API Key" v-model="api_key" />
      </div>

      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="this.cancel" text />
        <Button
          label="Submit"
          icon="pi pi-check"
          @click="this.submit"
          autofocus
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
// import { toRaw } from "vue";
import * as API from "./../../api";

const LOCAL_STORAGE = new API.local_storage();

export default {
  name: "APIKeyCard",
  components: { Dialog, Button, InputText },
  data() {
    return {
      visible: true,
      api_key: "",
      debug: false,
      cache: false,
    };
  },
  mounted() {},
  // created: {},
  computed: {
    is_visible() {
      return this.$store.getters.get_is_api_key_card_visible;
    },
  },
  watch: {},
  methods: {
    async submit() {
      this.$store.commit("set_is_api_key_card_visible");
      this.$store.commit("set_api_key", this.api_key);
      const FINGERPRINT = this.$store.getters.get_fingerprint;
      if (FINGERPRINT.device_id) {
        LOCAL_STORAGE.save_data(FINGERPRINT, this.api_key, "key");
      }
    },
    cancel() {
      this.$store.commit("set_is_api_key_card_visible");
    },
  },
};
</script>

<style></style>
