<template>
  <div class="card flex justify-content-center">
    <Dialog
      modal
      header="Enter Data To Validate"
      :style="{ width: '50vw' }"
      :visible="is_visible"
      closeIcon="false"
    >
      <div class="card flex mt-2">
        <InputText type="text" placeholder="Job Name" v-model="label" />
      </div>
      <div class="card flex mt-2">
        <InputText type="text" placeholder="API Key" v-model="api_key" />
      </div>
      <div class="card flex mt-2">
        <Textarea
          v-model="ipqs_data"
          rows="5"
          cols="30"
          placeholder="Data to validate, one entry per line"
          class="mt-2"
        />
      </div>
      <div class="flex align-items-center mt-2">
        <Checkbox v-model="debug" inputId="debug" name="debug" :binary="true" />
        <label for="debug" class="ml-2"> Debug </label>
      </div>
      <div class="flex align-items-center mt-2">
        <Checkbox v-model="cache" inputId="cache" name="cache" :binary="true" />
        <label for="cache" class="ml-2"> No Cache</label>
      </div>
      <div class="flex flex-wrap gap-3 mt-2">
        <div class="flex align-items-center">
          <RadioButton
            v-model="endpoint"
            inputId="email"
            name="email"
            value="email"
          />
          <label for="email" class="ml-2">Email</label>
        </div>
        <div class="flex align-items-center">
          <RadioButton v-model="endpoint" inputId="ip" name="ip" value="ip" />
          <label for="ip" class="ml-2">IP</label>
        </div>
        <div class="flex align-items-center">
          <RadioButton
            v-model="endpoint"
            inputId="phone"
            name="phone"
            value="phone"
          />
          <label for="phone" class="ml-2">Phone</label>
        </div>
      </div>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="this.cancel" text />
        <Button label="Get" icon="pi pi-check" @click="this.submit" autofocus />
      </template>
    </Dialog>
    <Toast />
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Toast from "primevue/toast";
import Textarea from "primevue/textarea";
import RadioButton from "primevue/radiobutton";

import * as API from "../../api";
const IPQS = new API.ipqs();
const REPORTS = new API.reports();
const IP = new API.ip();

// import { toRaw } from "vue";

export default {
  name: "IPQSValidationCard",
  components: {
    Dialog,
    Button,
    InputText,
    Checkbox,
    Toast,
    Textarea,
    RadioButton,
  },
  data() {
    return {
      visible: true,
      ipqs_data: "",
      api_key: "",
      debug: false,
      cache: false,
      endpoint: "",
      label: "",
    };
  },
  mounted() {},
  // created: {},
  computed: {
    is_visible() {
      return this.$store.getters.get_is_ipqs_validation_card_visible;
    },
    store_api_key() {
      return this.$store.getters.get_api_key;
    },
    throttle() {
      return parseInt(process.env.VUE_APP_API_THROTTLE);
    },
    fingerprint() {
      return this.$store.getters.get_fingerprint;
    },
  },
  watch: {
    store_api_key(new_value, old_value) {
      if (new_value !== old_value) {
        this.api_key = new_value;
      }
    },
  },
  methods: {
    cancel() {
      this.$store.commit("set_is_ipqs_validation_card_visible");
    },
    async submit() {
      this.$store.commit("enable_is_progress_bar_visible");
      this.$store.commit("set_is_ipqs_validation_card_visible");
      this.display_toast("Getting data");
      let data = this.ipqs_data.split("\n");
      if (this.endpoint === "ip") {
        data = await IP.split_subnet(data);
      }
      const RAW_DATA = await this.validate_data([], data);
      console.log(RAW_DATA);
      if (RAW_DATA.length > 0) {
        const FORMATTED_DATA = await this.format_ipqs_response(RAW_DATA);
        const REPORT = await REPORTS.create_reports(FORMATTED_DATA);
        REPORT.ip_reports.raw_data = FORMATTED_DATA;
        REPORT.ip_reports.label = this.label;
        this.$store.commit("set_reports", REPORT);
        this.display_toast("Complete");
      }
      this.$store.commit("disable_is_progress_bar_visible");
    },
    /* eslint-disable */
    validate_data(response_buffer, ipqs_data) {
      return new Promise(async (resolve) => {
        if (ipqs_data.length > 0) {
          let progress_bar_value = parseInt(
            (response_buffer.length / ipqs_data.length) * 100
          );
          this.$store.commit("set_progress_bar", progress_bar_value);
          let data;
          if (this.throttle > ipqs_data.length) {
            data = ipqs_data.splice(0, ipqs_data.length);
          } else {
            data = ipqs_data.splice(0, this.throttle);
          }
          const PAYLOAD = {
            data: data,
            api_key: this.api_key,
            debug: this.debug,
            cache: this.cache,
            endpoint: this.endpoint,
            fingerprint: this.fingerprint,
          };
          const RESPONSE_DATA = await IPQS.validate_data(PAYLOAD);
          if (RESPONSE_DATA.length > 0) {
            const IS_ERROR = RESPONSE_DATA.find((key) => {
              if (key.status && key.status === "fail") {
                return true;
              }
            });
            if (IS_ERROR !== undefined && IS_ERROR.status === "fail") {
              this.display_toast(IS_ERROR.message);
              resolve([]);
            } else {
              response_buffer.push(RESPONSE_DATA);
              const RESPONSE = this.validate_data(response_buffer, ipqs_data);
              resolve(RESPONSE);
            }
          } else {
            const RESPONSE = this.validate_data(response_buffer, ipqs_data);
            resolve(RESPONSE);
          }
        } else {
          resolve(response_buffer);
        }
      });
    },
    format_ipqs_response(data) {
      return new Promise((resolve) => {
        const RESPONSE = [];
        for (let array_entry = 0; array_entry < data.length; array_entry++) {
          for (let line = 0; line < data[array_entry].length; line++) {
            RESPONSE.push(data[array_entry][line]);
          }
          if (array_entry === data.length - 1) {
            resolve(RESPONSE);
          }
        }
      });
    },
    display_toast(message) {
      this.$toast.add({
        severity: "info",
        detail: message,
        life: 6200,
      });
    },
  },
};
</script>

<style></style>
