const MODEL = [
  { brand: "Xiaomi", model: "MI 9 SE", cpu: "-" },
  { brand: "Motorola", model: "Motorola One Vision", cpu: "-" },
  { brand: "asus", model: "ASUS_I003DD", cpu: "-" },
  { brand: "Xiaomi", model: "MI 10", cpu: "-" },
  {
    brand: "-",
    model: "-",
    cpu: "11th Gen Intel(R) Core(TM) i3-1115G4 @ 3.00GHz",
  },
  {
    brand: "49ed4deba047c5d7",
    model: "6a7df280f97df3e3",
    cpu: "Android virtual processor",
  },
  { brand: "A-gold", model: "BV9700Pro", cpu: "-" },
  { brand: "A-gold", model: "BV9800", cpu: "-" },
  {
    brand: "Acer",
    model: "Acer One 10 T8-129L",
    cpu: "Android virtual processor",
  },
  { brand: "AGM", model: "A8", cpu: "-" },
  { brand: "AGM", model: "AGM G2", cpu: "-" },
  { brand: "AGM", model: "AGM X3", cpu: "-" },
  { brand: "Alldocube", model: "iPlay_30", cpu: "-" },
  { brand: "Alldocube", model: "iPlay_40H", cpu: "-" },
  { brand: "ALLVIEW", model: "X8_Soul_Style", cpu: "-" },
  { brand: "ALLVIEW", model: "X6_Soul_mini", cpu: "-" },
  { brand: "along", model: "KP1-A101", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "alps", model: "L663", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "alps", model: "SOYES XS11", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "alps", model: "S21+ Ultra", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "ANT DAO TECHNOLOGY LIMITED", model: "Air1 Ultra+", cpu: "-" },
  { brand: "ANT DAO TECHNOLOGY LIMITED", model: "B1", cpu: "-" },
  { brand: "ANT DAO TECHNOLOGY LIMITED", model: "Air1 Pro", cpu: "-" },
  { brand: "apple", model: "Pro_Max14", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Archos", model: "Archos T101 HD 4G", cpu: "ARMv8 Processor" },
  {
    brand: "archos",
    model: "Archos 101b Oxygen",
    cpu: "AArch64 Processor rev 3 (aarch64)",
  },
  {
    brand: "Archos",
    model: "Archos 55 Helium",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  {
    brand: "Asus",
    model: "ASUS_Z01QD",
    cpu: "Intel(R) Core(TM) i7-4790 CPU @ 3.60GHz",
  },
  {
    brand: "Asus",
    model: "ASUS_Z01QD",
    cpu: "Intel(R) Core(TM) i7-8650U CPU @ 1.90GHz",
  },
  { brand: "asus", model: "ASUS_I006D", cpu: "-" },
  { brand: "asus", model: "ASUS_AI2203_C", cpu: "-" },
  { brand: "asus", model: "ASUS_X00DD", cpu: "-" },
  {
    brand: "Asus",
    model: "ASUS_Z01QD",
    cpu: "Intel(R) Core(TM) i5-10300H CPU @ 2.50GHz",
  },
  { brand: "asus", model: "ASUS_X00PD", cpu: "-" },
  {
    brand: "asus",
    model: "ASUS_X008D",
    cpu: "AArch64 Processor rev 4 (aarch64)",
  },
  { brand: "asus", model: "ASUS_X00TD", cpu: "-" },
  { brand: "asus", model: "ASUS_X00TDB", cpu: "-" },
  { brand: "asus", model: "ASUS_I002D", cpu: "-" },
  { brand: "asus", model: "ASUS_Z017D", cpu: "-" },
  { brand: "asus", model: "ASUS_I003D", cpu: "-" },
  { brand: "asus", model: "ASUS_X00QD", cpu: "-" },
  { brand: "asus", model: "ASUS_I004D", cpu: "-" },
  {
    brand: "Asus",
    model: "ASUS_I003DD",
    cpu: "AMD Ryzen 9 5900X 12-Core Processor",
  },
  { brand: "asus", model: "ASUS_X01AD", cpu: "-" },
  {
    brand: "Asus",
    model: "ASUS_I005DA",
    cpu: "Intel(R) Core(TM) i7-8650U CPU @ 1.90GHz",
  },
  {
    brand: "Asus",
    model: "ASUS_I003DD",
    cpu: "Intel(R) Core(TM) i7-8700K CPU @ 3.70GHz",
  },
  { brand: "asus", model: "ASUS_X00HD", cpu: "-" },
  {
    brand: "Asus",
    model: "ASUS_I003DD",
    cpu: "AMD Ryzen 7 5700G with Radeon Graphics",
  },
  { brand: "asus", model: "ASUS_Z01QD", cpu: "-" },
  { brand: "asus", model: "ASUS_I01WD", cpu: "-" },
  { brand: "asus", model: "ASUS_AI2202", cpu: "-" },
  { brand: "asus", model: "P00A", cpu: "AArch64 Processor rev 3 (aarch64)" },
  { brand: "asus", model: "ASUS_I001DE", cpu: "-" },
  {
    brand: "asus",
    model: "ASUS_X018D",
    cpu: "AArch64 Processor rev 2 (aarch64)",
  },
  { brand: "asus", model: "ASUS_I001D", cpu: "-" },
  { brand: "asus", model: "ASUS_X017DA", cpu: "-" },
  {
    brand: "Asus",
    model: "ASUS_I005DA",
    cpu: "Intel(R) Xeon(R) CPU E3-1240 v6 @ 3.70GHz",
  },
  { brand: "asus", model: "ASUS_AI2201_C", cpu: "-" },
  { brand: "asus", model: "ASUS_Z01KS", cpu: "-" },
  { brand: "asus", model: "ASUS_X00AD", cpu: "-" },
  {
    brand: "Asus",
    model: "ASUS_I005DA",
    cpu: "Intel(R) Core(TM) i7-10700 CPU @ 2.90GHz",
  },
  {
    brand: "Asus",
    model: "ASUS_I005DA",
    cpu: "11th Gen Intel(R) Core(TM) i7-11700F @ 2.50GHz",
  },
  { brand: "asus", model: "ASUS_Z016S", cpu: "-" },
  { brand: "asus", model: "ASUS_I005DA", cpu: "-" },
  { brand: "asus", model: "ASUS_I005D", cpu: "-" },
  { brand: "Asus", model: "ASUS_I003DD", cpu: "-" },
  { brand: "asus", model: "ASUS_Z01GS", cpu: "-" },
  { brand: "asus", model: "ASUS_X01BDA", cpu: "-" },
  { brand: "asus", model: "ASUS_X00QDA", cpu: "-" },
  {
    brand: "Asus",
    model: "ASUS_Z01QD",
    cpu: "11th Gen Intel(R) Core(TM) i7-11800H @ 2.30GHz",
  },
  { brand: "Beafon Mobile GmbH", model: "M7", cpu: "-" },
  { brand: "Billion", model: "Capture+", cpu: "-" },
  { brand: "BlackBerry", model: "BBG100-1", cpu: "-" },
  { brand: "BlackBerry", model: "BBE100-2", cpu: "-" },
  { brand: "BlackBerry", model: "BBE100-4", cpu: "-" },
  { brand: "blackshark", model: "SKR-H0", cpu: "-" },
  { brand: "blackshark", model: "SHARK PRS-H0", cpu: "-" },
  { brand: "blackshark", model: "SHARK PAR-H0", cpu: "-" },
  { brand: "blackshark", model: "SHARK KSR-H0", cpu: "-" },
  { brand: "blackshark", model: "SHARK KLE-H0", cpu: "-" },
  { brand: "blackshark", model: "SHARK KTUS-H0", cpu: "-" },
  { brand: "blackshark", model: "SKW-H0", cpu: "-" },
  { brand: "Blackview", model: "BV9900", cpu: "-" },
  { brand: "Blackview", model: "A85", cpu: "-" },
  { brand: "Blackview", model: "BV9200", cpu: "-" },
  { brand: "Blackview", model: "A55", cpu: "-" },
  { brand: "Blackview", model: "Tab8", cpu: "-" },
  { brand: "Blackview", model: "A100", cpu: "-" },
  { brand: "Blackview", model: "BV9600Pro", cpu: "-" },
  { brand: "Blackview", model: "BV4900Pro", cpu: "-" },
  { brand: "Blackview", model: "Tab 7 Pro", cpu: "-" },
  { brand: "Blackview", model: "A55 Pro", cpu: "-" },
  { brand: "Blackview", model: "BL5000", cpu: "-" },
  { brand: "Blackview", model: "Tab 11", cpu: "-" },
  { brand: "Blackview", model: "BV4900", cpu: "-" },
  { brand: "Blackview", model: "BV7100", cpu: "-" },
  { brand: "Blackview", model: "BV6100", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Blackview", model: "A60", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "Blackview", model: "BV6600", cpu: "-" },
  { brand: "Blackview", model: "BV5300 Pro", cpu: "-" },
  { brand: "Blackview", model: "BV5900", cpu: "-" },
  { brand: "Blackview", model: "BV6600Pro", cpu: "-" },
  { brand: "Blackview", model: "A80Pro", cpu: "-" },
  { brand: "Blackview", model: "BV5200 Pro", cpu: "-" },
  {
    brand: "Blackview",
    model: "A7Pro",
    cpu: "AArch64 Processor rev 4 (aarch64)",
  },
  { brand: "Blackview", model: "A80Plus", cpu: "-" },
  { brand: "Blackview", model: "A52", cpu: "ARMv8 Processor" },
  { brand: "Blackview", model: "A53", cpu: "-" },
  {
    brand: "Blackview",
    model: "BV6800Pro",
    cpu: "AArch64 Processor rev 2 (aarch64)",
  },
  { brand: "Blackview", model: "Tab 7 WiFi", cpu: "-" },
  { brand: "Blackview", model: "Tab 12 Pro", cpu: "-" },
  {
    brand: "Blackview",
    model: "BV8000Pro",
    cpu: "AArch64 Processor rev 4 (aarch64)",
  },
  { brand: "Blackview", model: "BV9900Pro", cpu: "-" },
  { brand: "Blackview", model: "BL8800Pro", cpu: "-" },
  { brand: "Blackview", model: "BV7200", cpu: "-" },
  { brand: "BLU", model: "B140DL", cpu: "-" },
  { brand: "BLU", model: "Vivo XI+", cpu: "-" },
  {
    brand: "Bopel",
    model: "Like 11 Panda Pro 2022",
    cpu: "ARMv7 Processor rev 5 (v7l)",
  },
  { brand: "bq", model: "Aquaris X Pro", cpu: "-" },
  { brand: "bq", model: "Aquaris U Lite", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "bq", model: "Aquaris X5 Plus", cpu: "ARMv7 Processor rev 0 (v7l)" },
  { brand: "bq", model: "Aquaris U Plus", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "bq", model: "Aquaris X", cpu: "-" },
  { brand: "bq", model: "Aquaris M5", cpu: "ARMv7 Processor rev 1 (v7l)" },
  { brand: "bq", model: "Aquaris VS", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "bq", model: "Aquaris V Plus", cpu: "-" },
  { brand: "bq", model: "Aquaris U2", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "bq", model: "Aquaris U2 Lite", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "bq", model: "Aquaris V", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "bq", model: "Aquaris X2 Pro", cpu: "-" },
  { brand: "bq", model: "Aquaris M5.5", cpu: "ARMv7 Processor rev 1 (v7l)" },
  { brand: "bq", model: "Aquaris X2", cpu: "-" },
  { brand: "bq", model: "Aquaris U", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "bq", model: "Aquaris C", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Brigmton", model: "BTPC-1023OC4G", cpu: "-" },
  { brand: "BRONDI", model: "MIDNIGHT_SKY", cpu: "ARMv8 Processor" },
  { brand: "Bullitt", model: "Motorola Defy", cpu: "-" },
  {
    brand: "BullittGroupLimited",
    model: "S41",
    cpu: "AArch64 Processor rev 4 (aarch64)",
  },
  { brand: "BullittGroupLimited", model: "S61", cpu: "-" },
  {
    brand: "BullittGroupLimited",
    model: "S31",
    cpu: "ARMv7 Processor rev 5 (v7l)",
  },
  { brand: "BUNDY", model: "A62", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "Cat", model: "S53", cpu: "-" },
  { brand: "Cat", model: "S75", cpu: "-" },
  { brand: "Cat", model: "S62 Pro", cpu: "-" },
  { brand: "Cat", model: "S42", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Cat", model: "S52", cpu: "-" },
  { brand: "Cat", model: "S22 FLIP", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Chinoe", model: "C20 Pro", cpu: "ARMv8 Processor" },
  { brand: "Chinoe", model: "BV4900s", cpu: "ARMv8 Processor" },
  { brand: "Chinoe", model: "BV6600E", cpu: "-" },
  { brand: "Chinoe", model: "C20", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "Chinoe", model: "A70 Pro", cpu: "-" },
  { brand: "CHUWI", model: "HiPadX", cpu: "-" },
  { brand: "CHUWI", model: "HiPad Max", cpu: "-" },
  {
    brand: "Coolpad",
    model: "Coolpad E502",
    cpu: "AArch64 Processor rev 4 (aarch64)",
  },
  { brand: "Crosscall", model: "Core-X4", cpu: "-" },
  { brand: "CROSSCALL", model: "CORE-M4", cpu: "-" },
  { brand: "CROSSCALL", model: "Action-X3 Pro", cpu: "-" },
  { brand: "Crosscall", model: "Action-X5", cpu: "-" },
  { brand: "Crosscall", model: "Core-M5", cpu: "-" },
  { brand: "CROSSCALL", model: "Core-X3", cpu: "-" },
  {
    brand: "Crosscall",
    model: "TREKKER-M1 CORE",
    cpu: "ARMv7 Processor rev 5 (v7l)",
  },
  { brand: "CROSSCALL", model: "Action-X3", cpu: "-" },
  { brand: "CUBOT", model: "KING KONG CS", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "CUBOT", model: "NOTE 20 PRO", cpu: "-" },
  { brand: "CUBOT", model: "J5", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "CUBOT", model: "MAX 3", cpu: "-" },
  { brand: "CUBOT", model: "P60", cpu: "-" },
  { brand: "CUBOT", model: "NOTE 7", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "CUBOT", model: "KINGKONG 5 Pro", cpu: "-" },
  { brand: "CUBOT", model: "QUEST", cpu: "-" },
  { brand: "CUBOT", model: "P40", cpu: "-" },
  { brand: "CUBOT", model: "X20 PRO", cpu: "-" },
  { brand: "CUBOT", model: "X30", cpu: "-" },
  { brand: "CUBOT", model: "NOTE 20", cpu: "-" },
  { brand: "CUBOT", model: "P30", cpu: "-" },
  { brand: "CUBOT", model: "C30", cpu: "-" },
  { brand: "CUBOT", model: "KINGKONG 5", cpu: "-" },
  { brand: "CUBOT", model: "X19 S", cpu: "-" },
  { brand: "CUBOT", model: "X19", cpu: "-" },
  { brand: "CUBOT", model: "P80", cpu: "-" },
  { brand: "CUBOT", model: "KINGKONG MINI 3", cpu: "-" },
  { brand: "DANEW", model: "Konnect_556", cpu: "ARMv8 Processor" },
  { brand: "Danew", model: "Konnect_607", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "DOOGEE", model: "X96Pro", cpu: "-" },
  { brand: "DOOGEE", model: "X98Pro", cpu: "-" },
  { brand: "DOOGEE", model: "S96Pro", cpu: "-" },
  { brand: "DOOGEE", model: "N20", cpu: "-" },
  { brand: "DOOGEE", model: "X98", cpu: "-" },
  { brand: "DOOGEE", model: "S98", cpu: "-" },
  { brand: "DOOGEE", model: "S100", cpu: "-" },
  { brand: "DOOGEE", model: "X97", cpu: "-" },
  { brand: "DOOGEE", model: "S51", cpu: "-" },
  { brand: "DOOGEE", model: "S86Pro", cpu: "-" },
  { brand: "DOOGEE", model: "S55", cpu: "AArch64 Processor rev 2 (aarch64)" },
  { brand: "DOOGEE", model: "S40Pro", cpu: "-" },
  { brand: "DOOGEE", model: "X95", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "DOOGEE", model: "X97Pro", cpu: "-" },
  { brand: "DOOGEE", model: "S40", cpu: "-" },
  { brand: "DOOGEE", model: "S88Pro", cpu: "-" },
  { brand: "DOOGEE", model: "V30T", cpu: "-" },
  { brand: "DOOGEE", model: "S99", cpu: "-" },
  { brand: "DOOGEE", model: "N20Pro", cpu: "-" },
  { brand: "DOOGEE", model: "S59", cpu: "-" },
  { brand: "DOOGEE", model: "V30", cpu: "-" },
  { brand: "DOOGEE", model: "S86", cpu: "-" },
  { brand: "DOOGEE", model: "S96GT", cpu: "-" },
  { brand: "DOOGEE", model: "S88Plus", cpu: "-" },
  { brand: "DOOGEE", model: "S89", cpu: "-" },
  { brand: "DOOGEE", model: "N30", cpu: "-" },
  { brand: "DOOGEE", model: "S58Pro", cpu: "-" },
  { brand: "DOOGEE", model: "V20", cpu: "-" },
  { brand: "DOOGEE", model: "S41Pro", cpu: "-" },
  { brand: "DOOGEE", model: "V Max", cpu: "-" },
  { brand: "DOOGEE", model: "X96", cpu: "ARMv8 Processor" },
  { brand: "DOOGEE", model: "S98Pro", cpu: "-" },
  { brand: "DOOGEE", model: "X5pro", cpu: "-" },
  { brand: "DOOGEE", model: "N40Pro", cpu: "-" },
  { brand: "DOOGEE", model: "S59Pro", cpu: "-" },
  { brand: "DOOGEE", model: "X95Pro", cpu: "-" },
  { brand: "DOOGEE", model: "T20", cpu: "-" },
  { brand: "DOOGEE", model: "S95Pro", cpu: "-" },
  { brand: "DOOGEE", model: "S97Pro", cpu: "-" },
  { brand: "DOOGEE", model: "S61Pro", cpu: "-" },
  { brand: "DOOGEE", model: "N50", cpu: "-" },
  { brand: "DOOGEE", model: "S89Pro", cpu: "-" },
  { brand: "DOOGEE", model: "S61", cpu: "-" },
  { brand: "Doro", model: "DSB-0220", cpu: "-" },
  { brand: "Droi", model: "V31D", cpu: "-" },
  { brand: "DUODUOGO", model: "A70", cpu: "AArch64 Processor rev 4 (aarch64)" },
  { brand: "DUODUOGO", model: "T30 Pro", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "EL", model: "D68", cpu: "-" },
  { brand: "Fairphone", model: "FP4", cpu: "-" },
  { brand: "Fairphone", model: "FP3", cpu: "-" },
  { brand: "FCNT", model: "F-51B", cpu: "-" },
  { brand: "FIGI", model: "Note_11_Pro", cpu: "-" },
  { brand: "FISE", model: "KL602", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "FISE", model: "A70", cpu: "-" },
  { brand: "FONOS", model: "O3", cpu: "-" },
  {
    brand: "Fortune Ship",
    model: "SMART 2",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  {
    brand: "Fortune Ship",
    model: "SPC SMART PLUS",
    cpu: "ARMv7 Processor rev 5 (v7l)",
  },
  { brand: "Fortune Ship", model: "SMART 3", cpu: "-" },
  { brand: "Fortune Ship", model: "Le Wave", cpu: "ARMv8 Processor" },
  {
    brand: "Genymotion",
    model: "Samsung Galaxy S9",
    cpu: "11th Gen Intel(R) Core(TM) i5-1135G7 @ 2.40GHz",
  },
  { brand: "Gigaset", model: "E940-2849-00", cpu: "-" },
  { brand: "Gigaset", model: "GS195", cpu: "-" },
  {
    brand: "Gigaset",
    model: "GS370_Plus",
    cpu: "AArch64 Processor rev 2 (aarch64)",
  },
  { brand: "Gigaset", model: "GS190", cpu: "-" },
  { brand: "GIONEE", model: "GIONEE M7", cpu: "-" },
  { brand: "Google", model: "Pixel 6a", cpu: "-" },
  { brand: "Google", model: "Pixel 7 Pro", cpu: "-" },
  { brand: "Google", model: "Pixel 6 Pro", cpu: "-" },
  { brand: "Google", model: "Pixel 7", cpu: "-" },
  { brand: "Google", model: "Pixel 4a", cpu: "-" },
  {
    brand: "Google",
    model: "PIXEL 2 XL",
    cpu: "Intel(R) Xeon(R) CPU E5-2699 v4 @ 2.20GHz",
  },
  { brand: "Google", model: "Pixel 2 XL", cpu: "-" },
  { brand: "Google", model: "Pixel 3a", cpu: "-" },
  {
    brand: "Google",
    model: "octopus",
    cpu: "Intel(R) Celeron(R) N4020 CPU @ 1.10GHz",
  },
  { brand: "Google", model: "Pixel 5", cpu: "-" },
  {
    brand: "Google",
    model: "PIXEL 2 XL",
    cpu: "AMD Ryzen 7 5800H with Radeon Graphics",
  },
  { brand: "Google", model: "Pixel 4", cpu: "-" },
  {
    brand: "Google",
    model: "sdk_gphone_x86",
    cpu: "Intel(R) Core(TM) i9-9880H CPU @ 2.30GHz",
  },
  { brand: "Google", model: "Pixel 6", cpu: "-" },
  { brand: "Google", model: "Pixel 7a", cpu: "-" },
  {
    brand: "Google",
    model: "octopus",
    cpu: "Intel(R) Celeron(R) N4120 CPU @ 1.10GHz",
  },
  { brand: "Google", model: "Pixel 2", cpu: "-" },
  { brand: "Google", model: "Pixel 4a (5G)", cpu: "-" },
  { brand: "Google", model: "Pixel 3 XL", cpu: "-" },
  {
    brand: "Google",
    model: "coral",
    cpu: "Intel(R) Celeron(R) CPU N3350 @ 1.10GHz",
  },
  {
    brand: "Google",
    model: "sdk_goog3_x86_64",
    cpu: "Android virtual processor",
  },
  { brand: "Google", model: "Pixel XL", cpu: "-" },
  {
    brand: "Google",
    model: "Android SDK built for x86",
    cpu: "Android virtual processor",
  },
  {
    brand: "Google",
    model: "sdk_goog3_gpc_x86_64",
    cpu: "Android virtual processor",
  },
  { brand: "Google", model: "sdk_gphone64_x86_64", cpu: "Virtual CPU" },
  { brand: "Google", model: "mainline", cpu: "-" },
  { brand: "Google", model: "jacuzzi", cpu: "-" },
  { brand: "Google", model: "trogdor", cpu: "-" },
  {
    brand: "Google",
    model: "PIXEL 2 XL",
    cpu: "Intel(R) Core(TM) i7-4770 CPU @ 3.40GHz",
  },
  { brand: "Google", model: "Pixel 4 XL", cpu: "-" },
  { brand: "Google", model: "sdk_gphone64_arm64", cpu: "-" },
  { brand: "Google", model: "sdk_gphone_arm64", cpu: "-" },
  {
    brand: "Google",
    model: "Android SDK built for x86",
    cpu: "Android 32-bit virtual processor",
  },
  {
    brand: "Google",
    model: "zork",
    cpu: "AMD Athlon Silver 3050C with Radeon Graphics",
  },
  {
    brand: "Google",
    model: "octopus",
    cpu: "Intel(R) Celeron(R) N4000 CPU @ 1.10GHz",
  },
  { brand: "Google", model: "Pixel 3", cpu: "-" },
  {
    brand: "google",
    model: "G011A",
    cpu: "AMD Ryzen 7 4800H with Radeon Graphics",
  },
  {
    brand: "Google",
    model: "sdk_gphone64_x86_64",
    cpu: "Intel(R) Xeon(R) CPU E3-1505M v5 @ 2.80GHz",
  },
  {
    brand: "Google",
    model: "PIXEL 2 XL",
    cpu: "Intel(R) Core(TM) i7-10700 CPU @ 2.90GHz",
  },
  {
    brand: "Google",
    model: "Pixel 5",
    cpu: "Intel(R) Xeon(R) CPU E3-1505M v5 @ 2.80GHz",
  },
  {
    brand: "Google",
    model: "sdk_gphone64_x86_64",
    cpu: "Android virtual processor",
  },
  {
    brand: "Google",
    model: "rammus",
    cpu: "Intel(R) Core(TM) m3-8100Y CPU @ 1.10GHz",
  },
  { brand: "Google", model: "Pixel 3a XL", cpu: "-" },
  { brand: "Google", model: "sdk_gphone_x86_64", cpu: "Virtual CPU" },
  {
    brand: "Google",
    model: "dedede",
    cpu: "Intel(R) Pentium(R) Silver N6000 @ 1.10GHz",
  },
  {
    brand: "Google",
    model: "dedede",
    cpu: "Intel(R) Celeron(R) N4500 @ 1.10GHz",
  },
  {
    brand: "google",
    model: "G011A",
    cpu: "AMD Ryzen 7 5800H with Radeon Graphics",
  },
  { brand: "HAEHNE", model: "A863K_A17", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HAFURY", model: "M20", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "HAFURY", model: "NOTE 10", cpu: "-" },
  { brand: "hena", model: "G12", cpu: "ARMv7 Processor rev 3 (v7l)" },
  {
    brand: "Hisense",
    model: "Hisense F27",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "HMD Global", model: "Nokia 4.2", cpu: "-" },
  { brand: "HMD Global", model: "TA-1021", cpu: "-" },
  { brand: "HMD Global", model: "Nokia G20", cpu: "-" },
  { brand: "HMD Global", model: "Nokia 5.1 Plus", cpu: "-" },
  { brand: "HMD Global", model: "Nokia 7.1", cpu: "-" },
  {
    brand: "HMD Global",
    model: "Nokia 2.4",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "HMD Global", model: "Nokia 2.2", cpu: "-" },
  { brand: "HMD Global", model: "Nokia G10", cpu: "-" },
  { brand: "HMD Global", model: "Nokia 5.4", cpu: "-" },
  { brand: "HMD Global", model: "Nokia G50", cpu: "-" },
  { brand: "HMD Global", model: "Nokia 7.2", cpu: "-" },
  { brand: "HMD Global", model: "Nokia 8.3 5G", cpu: "-" },
  {
    brand: "HMD Global",
    model: "TA-1032",
    cpu: "AArch64 Processor rev 4 (aarch64)",
  },
  { brand: "HMD Global", model: "Nokia X10", cpu: "-" },
  { brand: "HMD Global", model: "Nokia 9", cpu: "-" },
  { brand: "HMD Global", model: "Nokia C20", cpu: "ARMv8 Processor" },
  { brand: "HMD Global", model: "Nokia 3.1 Plus", cpu: "-" },
  { brand: "HMD Global", model: "TA-1053", cpu: "-" },
  { brand: "HMD Global", model: "Nokia 3.4", cpu: "-" },
  {
    brand: "HMD Global",
    model: "Nokia C1",
    cpu: "ARMv7 Processor rev 5 (v7l)",
  },
  { brand: "HMD Global", model: "Nokia 8 Sirocco", cpu: "-" },
  { brand: "HMD Global", model: "Nokia G21", cpu: "-" },
  {
    brand: "HMD Global",
    model: "Nokia 1.3",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  {
    brand: "HMD Global",
    model: "Nokia 2.3",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "HMD Global", model: "TA-1024", cpu: "-" },
  { brand: "HMD Global", model: "TA-1004", cpu: "-" },
  { brand: "HMD Global", model: "Nokia 6.1", cpu: "-" },
  { brand: "HMD Global", model: "Nokia X20", cpu: "-" },
  { brand: "HMD Global", model: "Nokia G11", cpu: "-" },
  {
    brand: "HMD Global",
    model: "Nokia C30",
    cpu: "ARMv8 Processor rev 0 (v8l)",
  },
  { brand: "HMD Global", model: "Nokia C31", cpu: "-" },
  { brand: "HMD Global", model: "Nokia C01 Plus", cpu: "ARMv8 Processor" },
  { brand: "HMD Global", model: "Nokia 1", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "HMD Global", model: "Nokia T20", cpu: "-" },
  { brand: "HMD Global", model: "Nokia 3.2", cpu: "-" },
  { brand: "HMD Global", model: "Nokia G60 5G", cpu: "-" },
  { brand: "HMD Global", model: "Nokia 6.2", cpu: "-" },
  { brand: "HMD Global", model: "Nokia 5.3", cpu: "-" },
  {
    brand: "HMD Global",
    model: "Nokia 1.4",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "HMD Global", model: "Nokia 6.1 Plus", cpu: "-" },
  {
    brand: "HMD Global",
    model: "Nokia C2 2nd Edition",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "HMD Global", model: "TA-1033", cpu: "-" },
  { brand: "HMD Global", model: "Nokia 7 plus", cpu: "-" },
  { brand: "HMD Global", model: "Nokia X30 5G", cpu: "-" },
  { brand: "HMD Global", model: "Nokia G22", cpu: "-" },
  {
    brand: "HMD Global",
    model: "Nokia C02",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "Honeywell", model: "EDA51", cpu: "-" },
  { brand: "hongyu", model: "Stylo_521", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "HONOR", model: "RMO-NX1", cpu: "-" },
  { brand: "HONOR", model: "FNE-NX9", cpu: "-" },
  { brand: "HONOR", model: "CMA-LX1", cpu: "-" },
  { brand: "HONOR", model: "RBN-NX1", cpu: "-" },
  { brand: "HONOR", model: "TFY-LX1", cpu: "-" },
  { brand: "HONOR", model: "NTH-NX9", cpu: "-" },
  { brand: "HONOR", model: "VNE-N41", cpu: "-" },
  { brand: "HONOR", model: "CRT-LX1", cpu: "-" },
  { brand: "HONOR", model: "ANY-LX1", cpu: "-" },
  { brand: "HONOR", model: "ANY-NX1", cpu: "-" },
  { brand: "HONOR", model: "VNE-LX1", cpu: "-" },
  { brand: "HONOR", model: "LGE-NX9", cpu: "-" },
  { brand: "HONOR", model: "PGT-N19", cpu: "-" },
  { brand: "HONOR", model: "NTN-LX1", cpu: "-" },
  { brand: "HONOR", model: "RKY-LX1", cpu: "-" },
  { brand: "HONOR", model: "VNE-LX3", cpu: "-" },
  { brand: "HONOR", model: "TFY-LX3", cpu: "-" },
  { brand: "HONOR", model: "CMA-LX2", cpu: "-" },
  { brand: "HONOR", model: "RKY-LX3", cpu: "-" },
  { brand: "HONOR", model: "CRT-LX3", cpu: "-" },
  { brand: "HONOR", model: "CMA-LX3", cpu: "-" },
  { brand: "HONOR", model: "CRT-NX1", cpu: "-" },
  { brand: "HOTWAV", model: "Note 12", cpu: "-" },
  { brand: "HTC", model: "HTC U11", cpu: "-" },
  { brand: "HTC", model: "HTC U11 plus", cpu: "-" },
  { brand: "HTC", model: "J One", cpu: "-" },
  { brand: "HUAWEI", model: "ALE-L21", cpu: "-" },
  { brand: "HUAWEI", model: "STK-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "YAL-L21", cpu: "-" },
  { brand: "HUAWEI", model: "MAR-LX1A", cpu: "-" },
  { brand: "HUAWEI", model: "VOG-L29", cpu: "-" },
  { brand: "HUAWEI", model: "POT-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "LYA-L29", cpu: "-" },
  { brand: "HUAWEI", model: "VTR-L29", cpu: "-" },
  { brand: "HUAWEI", model: "EML-L09", cpu: "-" },
  { brand: "HUAWEI", model: "ANE-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "PRA-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "NAM-LX9", cpu: "-" },
  { brand: "HUAWEI", model: "CLT-L29", cpu: "-" },
  { brand: "HUAWEI", model: "STF-L09", cpu: "-" },
  { brand: "HUAWEI", model: "SNE-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "MAR-LX1B", cpu: "-" },
  { brand: "HUAWEI", model: "FIG-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "DUB-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "STK-L21", cpu: "-" },
  { brand: "HUAWEI", model: "EML-L29", cpu: "-" },
  { brand: "HUAWEI", model: "EVA-L19", cpu: "-" },
  { brand: "HUAWEI", model: "PCT-L29", cpu: "-" },
  { brand: "HUAWEI", model: "CDY-NX9A", cpu: "-" },
  { brand: "HUAWEI", model: "DRA-L21", cpu: "-" },
  { brand: "HUAWEI", model: "JSN-L21", cpu: "-" },
  { brand: "HUAWEI", model: "CLT-L09", cpu: "-" },
  { brand: "HUAWEI", model: "FLA-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "JAT-L41", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "BLA-L29", cpu: "-" },
  { brand: "HUAWEI", model: "DRA-LX9", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "POT-LX1A", cpu: "-" },
  { brand: "HUAWEI", model: "BLA-L09", cpu: "-" },
  { brand: "HUAWEI", model: "VIE-L09", cpu: "-" },
  { brand: "HUAWEI", model: "ART-L29", cpu: "-" },
  { brand: "HUAWEI", model: "EVA-L09", cpu: "-" },
  { brand: "HUAWEI", model: "HRY-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "JNY-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "LYA-L09", cpu: "-" },
  { brand: "HUAWEI", model: "HLK-L41", cpu: "-" },
  { brand: "HUAWEI", model: "ELS-NX9", cpu: "-" },
  { brand: "HUAWEI", model: "LDN-L01", cpu: "-" },
  { brand: "HUAWEI", model: "PPA-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "SNE-LX3", cpu: "-" },
  { brand: "HUAWEI", model: "POT-LX1AF", cpu: "-" },
  { brand: "HUAWEI", model: "ANA-NX9", cpu: "-" },
  { brand: "HUAWEI", model: "JSN-AL00a", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "HUAWEI NXT-L29", cpu: "-" },
  { brand: "HUAWEI", model: "RNE-L21", cpu: "-" },
  { brand: "HUAWEI", model: "PAR-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "ALP-L09", cpu: "-" },
  { brand: "HUAWEI", model: "ELE-L29", cpu: "-" },
  { brand: "HUAWEI", model: "LLD-L31", cpu: "-" },
  { brand: "HUAWEI", model: "WAS-LX1A", cpu: "-" },
  {
    brand: "HUAWEI",
    model: "HPB-AN00",
    cpu: "11th Gen Intel(R) Core(TM) i5-11400F @ 2.60GHz",
  },
  { brand: "HUAWEI", model: "POT-LX1T", cpu: "-" },
  { brand: "HUAWEI", model: "HMA-L09", cpu: "-" },
  { brand: "HUAWEI", model: "DUA-L22", cpu: "-" },
  { brand: "HUAWEI", model: "ELE-L09", cpu: "-" },
  { brand: "HUAWEI", model: "BAH4-W09", cpu: "-" },
  { brand: "HUAWEI", model: "MRD-LX1", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "VTR-L09", cpu: "-" },
  { brand: "HUAWEI", model: "VKY-L09", cpu: "-" },
  { brand: "HUAWEI", model: "PPA-LX2", cpu: "-" },
  { brand: "HUAWEI", model: "AMN-LX3", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "ATU-L31", cpu: "-" },
  { brand: "HUAWEI", model: "ANE-LX2", cpu: "-" },
  { brand: "HUAWEI", model: "MAR-LX1M", cpu: "-" },
  { brand: "HUAWEI", model: "AGS2-W09", cpu: "-" },
  { brand: "HUAWEI", model: "PIC-LX9", cpu: "-" },
  { brand: "HUAWEI", model: "RNE-L03", cpu: "-" },
  { brand: "HUAWEI", model: "LLD-L31", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "MHA-L09", cpu: "-" },
  { brand: "HUAWEI", model: "MED-LX9N", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "AMN-LX9", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "JNY-LX2", cpu: "-" },
  { brand: "HUAWEI", model: "AQM-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "JSN-L22", cpu: "-" },
  { brand: "HUAWEI", model: "JLN-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "AMN-LX1", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "INE-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "HRY-LX1T", cpu: "-" },
  { brand: "HUAWEI", model: "LDN-L21", cpu: "-" },
  { brand: "HUAWEI", model: "KSA-LX9", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "ELS-N39", cpu: "-" },
  { brand: "HUAWEI", model: "KOB2-W09", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "BKK-LX2", cpu: "-" },
  { brand: "HUAWEI", model: "PRA-LX1", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "KOB-W09", cpu: "-" },
  { brand: "HUAWEI", model: "GLK-AL00", cpu: "-" },
  { brand: "HUAWEI", model: "LYA-AL00", cpu: "-" },
  { brand: "HUAWEI", model: "HUAWEI MLA-L11", cpu: "-" },
  { brand: "HUAWEI", model: "ALT-L29", cpu: "-" },
  { brand: "HUAWEI", model: "HUAWEI VNS-L31", cpu: "-" },
  { brand: "HUAWEI", model: "FRD-L09", cpu: "-" },
  { brand: "HUAWEI", model: "VOG-AL00", cpu: "-" },
  { brand: "HUAWEI", model: "MRD-LX3", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "MRD-LX1F", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "RVL-AL09", cpu: "-" },
  { brand: "HUAWEI", model: "MGA-LX9N", cpu: "-" },
  { brand: "HUAWEI", model: "ANE-LX3", cpu: "-" },
  { brand: "HUAWEI", model: "HUAWEI RIO-L01", cpu: "-" },
  { brand: "HUAWEI", model: "ATU-L21", cpu: "-" },
  { brand: "HUAWEI", model: "PLK-L01", cpu: "-" },
  {
    brand: "HUAWEI",
    model: "MYA-L41",
    cpu: "AArch64 Processor rev 3 (aarch64)",
  },
  { brand: "HUAWEI", model: "AGS-W09", cpu: "-" },
  { brand: "HUAWEI", model: "EVR-N29", cpu: "-" },
  { brand: "HUAWEI", model: "MRD-LX1N", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "MHA-L29", cpu: "-" },
  { brand: "HUAWEI", model: "BND-L21", cpu: "-" },
  { brand: "HUAWEI", model: "AUM-L29", cpu: "-" },
  { brand: "HUAWEI", model: "YAL-L41", cpu: "-" },
  { brand: "HUAWEI", model: "COL-L29", cpu: "-" },
  { brand: "HUAWEI", model: "ATU-L11", cpu: "-" },
  { brand: "HUAWEI", model: "HUAWEI GRA-L09", cpu: "-" },
  { brand: "HUAWEI", model: "PPA-LX3", cpu: "-" },
  { brand: "HUAWEI", model: "ARE-AL00", cpu: "-" },
  { brand: "HUAWEI", model: "HMA-L29", cpu: "-" },
  { brand: "HUAWEI", model: "BAH-L09", cpu: "-" },
  { brand: "HUAWEI", model: "INE-LX1r", cpu: "-" },
  { brand: "HUAWEI", model: "NEN-L22", cpu: "-" },
  { brand: "HUAWEI", model: "MAR-LX2", cpu: "-" },
  { brand: "HUAWEI", model: "JAT-L29", cpu: "ARMv8 Processor rev 4 (v8l)" },
  {
    brand: "HUAWEI",
    model: "MYA-L11",
    cpu: "AArch64 Processor rev 3 (aarch64)",
  },
  { brand: "HUAWEI", model: "FLA-LX2", cpu: "-" },
  { brand: "HUAWEI", model: "NEM-L21", cpu: "-" },
  { brand: "HUAWEI", model: "NEN-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "MAR-LX1H", cpu: "-" },
  { brand: "HUAWEI", model: "TRT-LX1", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "HUAWEI", model: "COR-L29", cpu: "-" },
  { brand: "HUAWEI", model: "LIO-L29", cpu: "-" },
  { brand: "HUAWEI", model: "VOG-TL00", cpu: "-" },
  { brand: "HUAWEI", model: "MNA-LX9", cpu: "-" },
  { brand: "HUAWEI", model: "JSN-AL00a", cpu: "-" },
  { brand: "HUAWEI", model: "BLL-L21", cpu: "-" },
  { brand: "HUAWEI", model: "INE-LX2", cpu: "-" },
  { brand: "HUAWEI", model: "HUAWEI VNS-L21", cpu: "-" },
  { brand: "HUAWEI", model: "WAS-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "CMR-AL09", cpu: "-" },
  { brand: "HUAWEI", model: "JLN-LX3", cpu: "-" },
  { brand: "HUAWEI", model: "JAD-LX9", cpu: "-" },
  { brand: "HUAWEI", model: "DRA-L01", cpu: "-" },
  { brand: "HUAWEI", model: "EVR-L29", cpu: "-" },
  { brand: "HUAWEI", model: "AGS-L09", cpu: "-" },
  { brand: "HUAWEI", model: "FIG-LX2", cpu: "-" },
  { brand: "HUAWEI", model: "MAR-LX3A", cpu: "-" },
  { brand: "HUAWEI", model: "GLA-LX1", cpu: "-" },
  { brand: "HUAWEI", model: "AGS2-L09", cpu: "-" },
  { brand: "HUAWEI", model: "JEF-NX9", cpu: "-" },
  { brand: "HUAWEI", model: "STK-LX3", cpu: "-" },
  { brand: "HUAWEI", model: "JKM-LX1", cpu: "-" },
  {
    brand: "HUAWEI",
    model: "JMM-AL00",
    cpu: "AArch64 Processor rev 2 (aarch64)",
  },
  { brand: "HUAWEI", model: "STK-L22", cpu: "-" },
  { brand: "HUAWEI", model: "RNE-L01", cpu: "-" },
  { brand: "HUAWEI", model: "NOH-NX9", cpu: "-" },
  { brand: "HUAWEI", model: "KIW-AL10", cpu: "-" },
  { brand: "HUAWEI", model: "HUAWEI MLA-L01", cpu: "-" },
  { brand: "HUAWEI", model: "BLN-L21", cpu: "-" },
  { brand: "HUAWEI", model: "BAH2-W19", cpu: "-" },
  { brand: "HUAWEI", model: "JKM-LX3", cpu: "-" },
  { brand: "HUAWEI", model: "VOG-L09", cpu: "-" },
  { brand: "HUAWEI", model: "DIG-L01", cpu: "-" },
  { brand: "HUAWEI", model: "JSN-L23", cpu: "-" },
  { brand: "HUAWEI", model: "CAM-L21", cpu: "-" },
  { brand: "HUAWEI", model: "HUAWEI CAM-L21", cpu: "-" },
  { brand: "HUAWEI", model: "ART-L29N", cpu: "-" },
  { brand: "HUAWEI", model: "BAL-AL00", cpu: "-" },
  { brand: "HUAWEI", model: "INE-LX1", cpu: "ARMv8 Processor rev 2 (v8l)" },
  { brand: "HUAWEI", model: "EVE-LX9N", cpu: "-" },
  { brand: "HUAWEI", model: "BKK-AL10", cpu: "-" },
  { brand: "HUAWEI", model: "LLD-L21", cpu: "-" },
  { brand: "HUAWEI", model: "POT-LX3", cpu: "-" },
  { brand: "HUAWEI", model: "MAR-AL00", cpu: "-" },
  { brand: "HUAWEI", model: "BAC-L21", cpu: "-" },
  { brand: "HUAWEI", model: "PCT-AL10", cpu: "-" },
  { brand: "HUAWEI", model: "DLI-L42", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "HUAWEI", model: "MOA-LX9N", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "Che2-L11", cpu: "-" },
  { brand: "HUAWEI", model: "DUK-L09", cpu: "-" },
  { brand: "HUAWEI", model: "ELE-L04", cpu: "-" },
  { brand: "HUAWEI", model: "LLD-AL20", cpu: "-" },
  { brand: "HUAWEI", model: "MGA-LX9", cpu: "-" },
  { brand: "HUAWEI", model: "FRL-L22", cpu: "-" },
  { brand: "HUAWEI", model: "DIG-L21", cpu: "-" },
  { brand: "HUAWEI", model: "KOB-L09", cpu: "-" },
  { brand: "HUAWEI", model: "FIG-LA1", cpu: "-" },
  { brand: "HUAWEI", model: "ABR-LX9", cpu: "-" },
  { brand: "HUAWEI", model: "TAS-L29", cpu: "-" },
  { brand: "HUAWEI", model: "DUB-LX2", cpu: "-" },
  { brand: "HUAWEI", model: "CLT-TL01", cpu: "-" },
  { brand: "Huawei", model: "Huawei Enjoy 9s", cpu: "-" },
  { brand: "HUAWEI", model: "VRD-W09", cpu: "-" },
  { brand: "HUAWEI", model: "LON-L29", cpu: "-" },
  { brand: "HUAWEI", model: "DRA-LX5", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "HUAWEI", model: "AGS3-W09", cpu: "-" },
  { brand: "HUAWEI", model: "SLA-L22", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "HUAWEI", model: "LYA-L0C", cpu: "-" },
  { brand: "HUAWEI", model: "HUAWEI NMO-L31", cpu: "-" },
  { brand: "HUAWEI", model: "MAR-LX2J", cpu: "-" },
  { brand: "HUAWEI", model: "AGS3K-W09", cpu: "-" },
  { brand: "HUAWEI", model: "LND-L29", cpu: "-" },
  { brand: "HUAWEI", model: "YAL", cpu: "-" },
  { brand: "HUAWEI", model: "EDI-AL10", cpu: "-" },
  { brand: "HUAWEI", model: "VOG-L04", cpu: "-" },
  { brand: "HUAWEI", model: "HUAWEI CAN-L11", cpu: "-" },
  { brand: "HUAWEI", model: "MAR-LX3Bm", cpu: "-" },
  { brand: "HUAWEI", model: "AGS3-L09", cpu: "-" },
  { brand: "HUAWEI", model: "HUAWEI CAN-L01", cpu: "-" },
  { brand: "HUAWEI", model: "MED-LX9", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "HUAWEI", model: "BAH-W09", cpu: "-" },
  { brand: "HUAWEI", model: "AGS2-W09", cpu: "77%" },
  { brand: "HUAWEI", model: "DCO-LX9", cpu: "-" },
  { brand: "HUAWEI", model: "PRA-LA1", cpu: "-" },
  { brand: "HUAWEI", model: "BAH2-L09", cpu: "-" },
  { brand: "HUAWEI", model: "GRACE", cpu: "-" },
  { brand: "HUAWEI", model: "JAD-AL00", cpu: "-" },
  { brand: "HUAWEI", model: "MAR-LX3Am", cpu: "-" },
  { brand: "HUAWEI", model: "MAR-LX2B", cpu: "-" },
  { brand: "HXY", model: "A9 Pro", cpu: "-" },
  { brand: "HXY", model: "RP04", cpu: "-" },
  { brand: "HXY", model: "A7S", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "HXY", model: "MP18", cpu: "-" },
  { brand: "HXY", model: "MP07", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "HXY", model: "A11 Pro Max", cpu: "-" },
  { brand: "HXY", model: "M1", cpu: "-" },
  { brand: "HXY", model: "Note_1C", cpu: "-" },
  { brand: "HXY", model: "MP03", cpu: "-" },
  { brand: "HXY", model: "MP17", cpu: "-" },
  { brand: "HXY", model: "MP02", cpu: "-" },
  { brand: "HXY", model: "BISON Pro", cpu: "-" },
  { brand: "iBRIT", model: "MAX12", cpu: "-" },
  { brand: "iGET", model: "SMART_L20X", cpu: "-" },
  { brand: "iHunt", model: "S21 Ultra 4G 2021_EEA", cpu: "ARMv8 Processor" },
  {
    brand: "iHunt",
    model: "iHunt Like 12",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  {
    brand: "iHunt",
    model: "iHunt S22 PLUS",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  {
    brand: "iHunt",
    model: "Like_Hi10_2021",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  { brand: "iHunt", model: "S30 ULTRA APEX 2021", cpu: "-" },
  { brand: "incar", model: "P20S_EEA", cpu: "-" },
  { brand: "incar", model: "Q3-EEA", cpu: "-" },
  { brand: "incar", model: "J10-EEA", cpu: "-" },
  { brand: "incar", model: "S23_EEA", cpu: "-" },
  { brand: "incar", model: "M40Pro_EEA", cpu: "-" },
  { brand: "incar", model: "T104_64GB", cpu: "-" },
  { brand: "incar", model: "PMT4238_4G_EEA", cpu: "-" },
  {
    brand: "INFINIX",
    model: "Infinix X665C",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "INFINIX", model: "Infinix X6819", cpu: "-" },
  { brand: "INFINIX", model: "Infinix X6817", cpu: "-" },
  { brand: "INFINIX", model: "Infinix X669D", cpu: "-" },
  {
    brand: "INFINIX",
    model: "Infinix X6516",
    cpu: "ARMv8 Processor rev 0 (v8l)",
  },
  { brand: "Infinix", model: "Infinix PR652B", cpu: "ARMv8 Processor" },
  { brand: "INFINIX", model: "Infinix X670", cpu: "-" },
  { brand: "INFINIX", model: "Infinix X676B", cpu: "-" },
  { brand: "INFINIX", model: "Infinix X665E", cpu: "-" },
  {
    brand: "INFINIX",
    model: "Infinix X6823",
    cpu: "ARMv8 Processor rev 1 (v8l)",
  },
  { brand: "INFINIX", model: "Infinix X6515", cpu: "-" },
  { brand: "INFINIX", model: "Infinix X678B", cpu: "-" },
  {
    brand: "Infinix",
    model: "Infinix X559C",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  { brand: "INFINIX", model: "Infinix X6517", cpu: "-" },
  { brand: "INFINIX", model: "Infinix X6821", cpu: "-" },
  { brand: "INFINIX", model: "Infinix X669", cpu: "-" },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X697", cpu: "-" },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X657C",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X688B", cpu: "-" },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X689C", cpu: "-" },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X657",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X622", cpu: "-" },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X650",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X606D",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X655C", cpu: "-" },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X6816", cpu: "-" },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X655C",
    cpu: "ARMv8 Processor rev 4 (v8l)",
  },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X612",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X690B", cpu: "-" },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X650B",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X625B",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X682B", cpu: "-" },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X665B", cpu: "-" },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X6511E",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X6511B",
    cpu: "ARMv8 Processor",
  },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X657B",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X683", cpu: "-" },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X650D", cpu: "-" },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X6816C", cpu: "-" },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X652B", cpu: "-" },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X693", cpu: "-" },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X608", cpu: "-" },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X6512",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X660C", cpu: "-" },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X658E",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X6811B", cpu: "-" },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X650C", cpu: "-" },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X656", cpu: "-" },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X663",
    cpu: "ARMv8 Processor rev 1 (v8l)",
  },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X627",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X623", cpu: "-" },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X689F", cpu: "-" },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X652C", cpu: "-" },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X6511G",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X690", cpu: "-" },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X624B",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X655",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X606C",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X659B", cpu: "-" },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X680",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X688C",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  {
    brand: "INFINIX MOBILITY LIMITED",
    model: "Infinix X665",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X6810", cpu: "-" },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X663", cpu: "-" },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X660B", cpu: "-" },
  { brand: "INFINIX MOBILITY LIMITED", model: "Infinix X605", cpu: "-" },
  { brand: "InnJoo", model: "Halo X", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "iPhone 11", model: "iPhone", cpu: "Apple A13 Bionic" },
  { brand: "iPhone 11 Pro", model: "iPhone", cpu: "Apple A13 Bionic" },
  { brand: "iPhone 11 Pro Max", model: "iPhone", cpu: "Apple A13 Bionic" },
  { brand: "iPhone 12", model: "iPhone", cpu: "Apple A14 Bionic" },
  { brand: "iPhone 12 Mini", model: "iPhone", cpu: "Apple A14 Bionic" },
  { brand: "iPhone 12 Pro", model: "iPhone", cpu: "Apple A14 Bionic" },
  { brand: "iPhone 12 Pro Max", model: "iPhone", cpu: "Apple A14 Bionic" },
  { brand: "iPhone 13", model: "iPhone", cpu: "Apple A15 Bionic" },
  { brand: "iPhone 13 Mini", model: "iPhone", cpu: "Apple A15 Bionic" },
  { brand: "iPhone 13 Pro", model: "iPhone", cpu: "Apple A15 Bionic" },
  { brand: "iPhone 13 Pro Max", model: "iPhone", cpu: "Apple A15 Bionic" },
  {
    brand: "iPhone 5s",
    model: "iPhone",
    cpu: "Apple A7 1.3Ghz dual core; 64bit",
  },
  { brand: "iPhone 6", model: "iPhone", cpu: "Apple A8; 64bit" },
  { brand: "iPhone 6 Plus", model: "iPhone", cpu: "Apple A8; 64bit" },
  { brand: "iPhone 6s", model: "iPhone", cpu: "Apple A9; 64bit" },
  { brand: "iPhone 6s Plus", model: "iPhone", cpu: "Apple A10 Fusion (64bit)" },
  { brand: "iPhone 7", model: "iPhone", cpu: "Apple A10 Fusion (64bit)" },
  { brand: "iPhone 7 Plus", model: "iPhone", cpu: "Apple A10 Fusion (64bit)" },
  { brand: "iPhone 7 Plus", model: "iPhone; ", cpu: "Portrait" },
  { brand: "iPhone 8", model: "iPhone", cpu: "Apple A11 Bionic" },
  { brand: "iPhone 8 Plus", model: "iPhone", cpu: "Apple A11 Bionic" },
  { brand: "iPhone SE", model: "iPhone", cpu: "Apple A9; 64bit" },
  { brand: "iPhone SE 2nd Gen", model: "iPhone", cpu: "A13 Bionic" },
  { brand: "iPhone X", model: "iPhone", cpu: "Apple A12 Bionic" },
  { brand: "iPhone XR", model: "iPhone", cpu: "Apple A12 Bionic" },
  { brand: "iPhone XS", model: "iPhone", cpu: "Apple A12 Bionic" },
  { brand: "iPhone XS Max", model: "iPhone", cpu: "Apple A12 Bionic" },
  { brand: "IPRO", model: "S100E", cpu: "-" },
  { brand: "itel", model: "itel A661WP", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "itel", model: "itel A571W", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "itel", model: "itel P651W", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "ITEL", model: "itel A512W", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "itel", model: "itel A661L", cpu: "ARMv8 Processor" },
  { brand: "itel", model: "itel W6501", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "ITEL", model: "itel A632W", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "ITEL", model: "itel S663L", cpu: "ARMv8 Processor rev 0 (v8l)" },
  { brand: "itel", model: "itel L6002P", cpu: "-" },
  { brand: "itel", model: "itel W6002", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "itel", model: "itel S661W", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "ITEL", model: "itel A662L", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "itel", model: "itel W5001P", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "Jlinksz", model: "M3", cpu: "AArch64 Processor rev 4 (aarch64)" },
  { brand: "JUSYEA", model: "J5", cpu: "-" },
  { brand: "KLIPAD", model: "KL9878A_EEA", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "KONROW", model: "STAR5", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "KONROW", model: "SOFT5", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "KRIP", model: "K66", cpu: "-" },
  { brand: "Kruger_Matz", model: "Live 10S", cpu: "-" },
  { brand: "Kruger_Matz", model: "Flow 9", cpu: "-" },
  {
    brand: "LAIQ",
    model: "LAIQ Glam",
    cpu: "AArch64 Processor rev 4 (aarch64)",
  },
  { brand: "LandRover", model: "Land Rover Explore R", cpu: "-" },
  { brand: "LEAGOO", model: "XRover C", cpu: "ARMv7 Processor rev 4 (v7l)" },
  {
    brand: "LEAGOO",
    model: "KIICAA MIX",
    cpu: "AArch64 Processor rev 2 (aarch64)",
  },
  {
    brand: "LeMobile",
    model: "LEX626",
    cpu: "AArch64 Processor rev 1 (aarch64)",
  },
  {
    brand: "LeMobile",
    model: "LEX626",
    cpu: "AArch64 Processor rev 4 (aarch64)",
  },
  { brand: "LeMobile", model: "Le X526", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-X606F", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-X306F", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-X306X", cpu: "-" },
  { brand: "Lenovo", model: "TB328XU", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-X606X", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB125FU", cpu: "-" },
  { brand: "Lenovo", model: "Lenovo L78071", cpu: "-" },
  { brand: "Lenovo", model: "Lenovo L79031", cpu: "-" },
  { brand: "Lenovo", model: "Lenovo L38041", cpu: "-" },
  {
    brand: "LENOVO",
    model: "Lenovo TB-7305F",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  { brand: "LENOVO", model: "Lenovo TB-J706F", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-X705L", cpu: "-" },
  { brand: "LENOVO", model: "LIFETAB E1080X", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-X6C6X", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-J616F", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-X605L", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo P2a42", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-X606FA", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-J606F", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-8505XS", cpu: "-" },
  { brand: "Lenovo", model: "Lenovo L58041", cpu: "-" },
  { brand: "Lenovo", model: "Lenovo L78051", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-X704L", cpu: "-" },
  { brand: "Lenovo", model: "Lenovo L38111", cpu: "-" },
  {
    brand: "LENOVO",
    model: "Lenovo TB-7104I",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  { brand: "LENOVO", model: "Lenovo TB128FU", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-8505X", cpu: "-" },
  { brand: "Lenovo", model: "Lenovo L18021", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-J607Z", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-8505F", cpu: "-" },
  { brand: "Lenovo", model: "TB328FU", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-J706L", cpu: "-" },
  { brand: "LENOVO", model: "TB132FU", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-8504X", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB128XU", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo TB-X6C6F", cpu: "-" },
  { brand: "LENOVO", model: "Lenovo YT-X705X", cpu: "-" },
  { brand: "LGE", model: "LM-K520", cpu: "-" },
  { brand: "LGE", model: "LM-X540", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "LGE", model: "LM-G710", cpu: "-" },
  { brand: "LGE", model: "LM-X525", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "LGE", model: "LM-K410", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "LGE", model: "LM-K420", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "LGE", model: "LG-H635", cpu: "ARMv7 Processor rev 0 (v7l)" },
  { brand: "LGE", model: "LGV35", cpu: "-" },
  { brand: "LGE", model: "LM-X520", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "LGE", model: "LM-X430", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "LGE", model: "LM-Q630", cpu: "-" },
  { brand: "LGE", model: "LM-K510", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "LGE", model: "LM-G910", cpu: "-" },
  { brand: "LGE", model: "LG-H930", cpu: "-" },
  { brand: "LGE", model: "LM-G810", cpu: "-" },
  { brand: "LGE", model: "LM-X210", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "LGE", model: "LG-H870", cpu: "-" },
  { brand: "LGE", model: "LG-M200", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "LGE", model: "LM-X120", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "LGE", model: "LM-K200", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "LGE", model: "LM-F100", cpu: "-" },
  { brand: "LGE", model: "LM-X320", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "LGE", model: "LM-G900", cpu: "-" },
  { brand: "LGE", model: "LG-M250", cpu: "ARMv7 Processor rev 2 (v7l)" },
  { brand: "LGE", model: "LG-K350", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "LGE", model: "LM-X420", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "LGE", model: "Nexus 5", cpu: "-" },
  { brand: "LGE", model: "LG-K220", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "LGE", model: "LG-H650", cpu: "ARMv7 Processor rev 0 (v7l)" },
  { brand: "LGE", model: "LG-K420", cpu: "ARMv7 Processor rev 0 (v7l)" },
  { brand: "LGE", model: "LG-TP450", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "LGE", model: "LG-M700", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "LGE", model: "LM-G850", cpu: "-" },
  { brand: "LGE", model: "LG-M320", cpu: "ARMv7 Processor rev 2 (v7l)" },
  { brand: "LGE", model: "LM-Q850", cpu: "-" },
  { brand: "LGE", model: "LM-X410.FN", cpu: "ARMv7 Processor rev 2 (v7l)" },
  { brand: "LGE", model: "LG-H815", cpu: "-" },
  { brand: "LGE", model: "LM-Q730", cpu: "-" },
  { brand: "LGE", model: "LM-G710VM", cpu: "-" },
  { brand: "LGE", model: "LM-Q610.FGN", cpu: "ARMv7 Processor rev 2 (v7l)" },
  { brand: "LGE", model: "LM-V405", cpu: "-" },
  { brand: "LGE", model: "LG-LS998", cpu: "-" },
  { brand: "LGE", model: "Nexus 4", cpu: "-" },
  { brand: "LGE", model: "LG-H850", cpu: "-" },
  { brand: "LGE", model: "LM-K526", cpu: "-" },
  { brand: "LGE", model: "LML211BL", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "LGE", model: "LG-H970", cpu: "-" },
  { brand: "LGE", model: "LM-Q920N", cpu: "-" },
  { brand: "LGE", model: "Nexus 5X", cpu: "Android virtual processor" },
  { brand: "LGE", model: "LM-X410.F", cpu: "ARMv7 Processor rev 2 (v7l)" },
  { brand: "LGE", model: "LM-V500N", cpu: "-" },
  { brand: "LGE", model: "LG-K430", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Lihwak", model: "Lihwak 14 Pro Max", cpu: "-" },
  {
    brand: "LOGICOM",
    model: "LaTabStandRB",
    cpu: "ARMv8 Processor rev 4 (v8l)",
  },
  { brand: "Logicom", model: "Le Swipe", cpu: "ARMv8 Processor" },
  { brand: "LOGICOM", model: "FLOW", cpu: "ARMv7 Processor rev 4 (v7l)" },
  {
    brand: "M-HORSE",
    model: "Pure1",
    cpu: "AArch64 Processor rev 3 (aarch64)",
  },
  { brand: "Meizu", model: "meizu note8", cpu: "-" },
  { brand: "Meizu", model: "meizu X8", cpu: "-" },
  { brand: "Meizu", model: "16th", cpu: "-" },
  { brand: "Meizu", model: "meizu C9", cpu: "-" },
  {
    brand: "Meizu",
    model: "MEIZU_M5",
    cpu: "AArch64 Processor rev 2 (aarch64)",
  },
  { brand: "Meizu", model: "PRO 6", cpu: "AArch64 Processor rev 4 (aarch64)" },
  { brand: "meizu", model: "meizu note9", cpu: "-" },
  { brand: "Meizu", model: "PRO 6", cpu: "AArch64 Processor rev 1 (aarch64)" },
  { brand: "MobiWire", model: "S24", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "MobiWire", model: "S14", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "MobiWire", model: "S23", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "MobiWire", model: "S34", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Morep", model: "M850", cpu: "-" },
  { brand: "motorola", model: "moto e40", cpu: "-" },
  { brand: "motorola", model: "motorola edge 20", cpu: "-" },
  { brand: "motorola", model: "moto g(6) plus", cpu: "-" },
  {
    brand: "motorola",
    model: "moto e5 play",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "motorola", model: "XT1650", cpu: "-" },
  { brand: "motorola", model: "motorola edge 30 fusion", cpu: "-" },
  { brand: "motorola", model: "moto g(10)", cpu: "-" },
  { brand: "motorola", model: "moto g31(w)", cpu: "-" },
  { brand: "motorola", model: "moto g 5G", cpu: "-" },
  { brand: "motorola", model: "ThinkPhone by motorola", cpu: "-" },
  { brand: "motorola", model: "moto e(7) plus", cpu: "-" },
  { brand: "motorola", model: "moto g200 5G", cpu: "-" },
  { brand: "motorola", model: "moto g stylus 5G (2022)", cpu: "-" },
  { brand: "motorola", model: "motorola one", cpu: "-" },
  { brand: "motorola", model: "moto g71 5G", cpu: "-" },
  { brand: "motorola", model: "moto g82 5G", cpu: "-" },
  { brand: "motorola", model: "moto e13", cpu: "-" },
  { brand: "motorola", model: "moto g(60)", cpu: "-" },
  { brand: "motorola", model: "moto g62 5G", cpu: "-" },
  { brand: "motorola", model: "moto g(6)", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "motorola", model: "moto g22", cpu: "-" },
  { brand: "motorola", model: "moto e32(s)", cpu: "-" },
  { brand: "motorola", model: "motorola edge 20 lite", cpu: "-" },
  { brand: "motorola", model: "moto g32", cpu: "-" },
  { brand: "motorola", model: "moto e20", cpu: "ARMv8 Processor rev 1 (v8l)" },
  { brand: "motorola", model: "moto g(30)", cpu: "-" },
  { brand: "motorola", model: "moto e(7)", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "motorola", model: "moto g(100)", cpu: "-" },
  {
    brand: "motorola",
    model: "moto g(7) play",
    cpu: "ARMv7 Processor rev 2 (v7l)",
  },
  { brand: "motorola", model: "motorola one macro", cpu: "-" },
  { brand: "motorola", model: "moto g(7) power", cpu: "-" },
  { brand: "motorola", model: "motorola edge 20 pro", cpu: "-" },
  { brand: "motorola", model: "moto g(50)", cpu: "-" },
  { brand: "motorola", model: "motorola edge 40 pro", cpu: "-" },
  { brand: "motorola", model: "moto g(8) plus", cpu: "-" },
  { brand: "motorola", model: "moto g72", cpu: "-" },
  { brand: "motorola", model: "moto g23", cpu: "-" },
  { brand: "motorola", model: "moto e22i", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "motorola", model: "moto e32", cpu: "-" },
  { brand: "motorola", model: "moto g(9) plus", cpu: "-" },
  {
    brand: "motorola",
    model: "moto e(6) plus",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "motorola", model: "motorola one hyper", cpu: "-" },
  { brand: "motorola", model: "moto g(20)", cpu: "-" },
  { brand: "motorola", model: "motorola edge 30 neo", cpu: "-" },
  { brand: "motorola", model: "moto e(6) plus", cpu: "-" },
  { brand: "motorola", model: "moto g73 5G", cpu: "-" },
  {
    brand: "motorola",
    model: "Moto G Play",
    cpu: "ARMv7 Processor rev 0 (v7l)",
  },
  { brand: "motorola", model: "moto g(9) play", cpu: "-" },
  { brand: "motorola", model: "moto g42", cpu: "-" },
  {
    brand: "motorola",
    model: "moto e6 play",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "motorola", model: "motorola edge 30", cpu: "-" },
  { brand: "motorola", model: "moto e(7) power", cpu: "-" },
  {
    brand: "motorola",
    model: "Moto G (4)",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "motorola", model: "motorola one vision", cpu: "-" },
  { brand: "motorola", model: "motorola one zoom", cpu: "-" },
  { brand: "motorola", model: "moto g52", cpu: "-" },
  { brand: "motorola", model: "moto e22", cpu: "-" },
  { brand: "motorola", model: "motorola edge 30 ultra", cpu: "-" },
  { brand: "motorola", model: "moto g 5G plus", cpu: "-" },
  { brand: "motorola", model: "moto g(60)s", cpu: "-" },
  {
    brand: "motorola",
    model: "moto g pure",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "motorola", model: "moto e(6i)", cpu: "ARMv8 Processor" },
  {
    brand: "motorola",
    model: "moto g(6) play",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "motorola", model: "moto g(50) 5G", cpu: "-" },
  { brand: "motorola", model: "motorola one action", cpu: "-" },
  { brand: "motorola", model: "moto g play (2021)", cpu: "-" },
  {
    brand: "motorola",
    model: "moto e5 plus",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "motorola", model: "moto g(8) power lite", cpu: "-" },
  { brand: "motorola", model: "moto g13", cpu: "-" },
  {
    brand: "motorola",
    model: "Moto G (5S) Plus",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "motorola", model: "moto g(8) power", cpu: "-" },
  {
    brand: "motorola",
    model: "Moto G (5)",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "motorola", model: "moto e6s", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "motorola", model: "Moto C", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "motorola", model: "moto g51 5G", cpu: "-" },
  { brand: "motorola", model: "moto e(7i) power", cpu: "ARMv8 Processor" },
  {
    brand: "motorola",
    model: "Moto E (4)",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "motorola", model: "moto g(7) plus", cpu: "-" },
  {
    brand: "motorola",
    model: "moto g(8) play",
    cpu: "ARMv7 Processor rev 2 (v7l)",
  },
  {
    brand: "motorola",
    model: "Moto G (5S)",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "motorola", model: "moto g stylus (2021)", cpu: "-" },
  { brand: "motorola", model: "moto g41", cpu: "-" },
  { brand: "motorola", model: "motorola edge plus (2022)", cpu: "-" },
  { brand: "motorola", model: "moto x4", cpu: "-" },
  { brand: "motorola", model: "moto g(9) power", cpu: "-" },
  {
    brand: "motorola",
    model: "Moto G (5) Plus",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "motorola", model: "XT1635-01", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "motorola", model: "XT1562", cpu: "ARMv7 Processor rev 1 (v7l)" },
  { brand: "motorola", model: "motorola one fusion", cpu: "-" },
  { brand: "motorola", model: "moto g53 5G", cpu: "-" },
  { brand: "motorola", model: "moto g stylus (2022)", cpu: "-" },
  { brand: "motorola", model: "moto g(8)", cpu: "-" },
  { brand: "motorola", model: "motorola razr 40 ultra", cpu: "-" },
  { brand: "motorola", model: "motorola razr 2022", cpu: "-" },
  { brand: "motorola", model: "moto z4", cpu: "-" },
  { brand: "motorola", model: "motorola edge", cpu: "-" },
  { brand: "motorola", model: "moto g stylus 5G", cpu: "-" },
  { brand: "motorola", model: "moto e6s", cpu: "-" },
  {
    brand: "myPhone",
    model: "Hammer_Explorer",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  {
    brand: "NewBund",
    model: "Apollo Lite",
    cpu: "AArch64 Processor rev 4 (aarch64)",
  },
  {
    brand: "NewBund",
    model: "Apollo Lite",
    cpu: "AArch64 Processor rev 1 (aarch64)",
  },
  { brand: "Nothing", model: "A063", cpu: "-" },
  { brand: "Nous", model: "NS5004", cpu: "AArch64 Processor rev 4 (aarch64)" },
  { brand: "nubia", model: "NX659J", cpu: "-" },
  { brand: "nubia", model: "NX679J", cpu: "-" },
  { brand: "nubia", model: "NX651J", cpu: "-" },
  { brand: "nubia", model: "NX669J", cpu: "-" },
  { brand: "nubia", model: "NX591J", cpu: "-" },
  { brand: "nubia", model: "NX729J", cpu: "-" },
  { brand: "NUU", model: "NUU_A1", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "NUU", model: "S5704L", cpu: "-" },
  { brand: "OnePlus", model: "IN2023", cpu: "-" },
  { brand: "OnePlus", model: "GM1900", cpu: "-" },
  { brand: "OnePlus", model: "ONEPLUS A5000", cpu: "-" },
  { brand: "OnePlus", model: "CPH2399", cpu: "-" },
  { brand: "OnePlus", model: "GM1913", cpu: "-" },
  { brand: "OnePlus", model: "HD1900", cpu: "-" },
  {
    brand: "OnePlus",
    model: "ONEPLUS A3010",
    cpu: "11th Gen Intel(R) Core(TM) i7-1165G7 @ 2.80GHz",
  },
  { brand: "OnePlus", model: "EB2103", cpu: "-" },
  { brand: "OnePlus", model: "DN2103", cpu: "-" },
  { brand: "OnePlus", model: "IN2025", cpu: "-" },
  { brand: "OnePlus", model: "AC2003", cpu: "-" },
  { brand: "OnePlus", model: "KB2005", cpu: "-" },
  { brand: "OnePlus", model: "NE2213", cpu: "-" },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "AMD Ryzen 5 3600 6-Core Processor",
  },
  { brand: "OnePlus", model: "HD1903", cpu: "-" },
  { brand: "OnePlus", model: "CPH2413", cpu: "-" },
  { brand: "OnePlus", model: "CPH2447", cpu: "-" },
  { brand: "OnePlus", model: "LE2121", cpu: "-" },
  { brand: "OnePlus", model: "ONEPLUS A6003", cpu: "-" },
  { brand: "OnePlus", model: "LE2123", cpu: "-" },
  { brand: "OnePlus", model: "CPH2409", cpu: "-" },
  { brand: "OnePlus", model: "KB2003", cpu: "-" },
  { brand: "OnePlus", model: "IV2201", cpu: "-" },
  { brand: "OnePlus", model: "LE2125", cpu: "-" },
  { brand: "OnePlus", model: "DE2118", cpu: "-" },
  { brand: "OnePlus", model: "BE2029", cpu: "-" },
  { brand: "OnePlus", model: "LE2127", cpu: "-" },
  { brand: "OnePlus", model: "ONEPLUS A6013", cpu: "-" },
  { brand: "OnePlus", model: "IN2010", cpu: "-" },
  { brand: "OnePlus", model: "CPH2415", cpu: "-" },
  { brand: "OnePlus", model: "ONEPLUS A6000", cpu: "-" },
  { brand: "OnePlus", model: "GM1910", cpu: "-" },
  { brand: "OnePlus", model: "AC2001", cpu: "-" },
  { brand: "OnePlus", model: "IN2013", cpu: "-" },
  { brand: "OnePlus", model: "HD1911", cpu: "-" },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "Intel(R) Core(TM) i7-2670QM CPU @ 2.20GHz",
  },
  { brand: "OnePlus", model: "NE2215", cpu: "-" },
  { brand: "OnePlus", model: "CPH2449", cpu: "-" },
  { brand: "OnePlus", model: "HD1901", cpu: "-" },
  { brand: "OnePlus", model: "ONEPLUS A5010", cpu: "-" },
  { brand: "OnePlus", model: "IN2015", cpu: "-" },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "AMD Ryzen 7 3700U with Radeon Vega Mobile Gfx",
  },
  {
    brand: "OnePlus",
    model: "HD1907",
    cpu: "Intel(R) Core(TM) i5-6200U CPU @ 2.30GHz",
  },
  { brand: "OnePlus", model: "ONEPLUS A6010", cpu: "-" },
  { brand: "OnePlus", model: "LE2113", cpu: "-" },
  {
    brand: "OnePlus",
    model: "NE2211",
    cpu: "Intel(R) Core(TM) i5-9300H CPU @ 2.40GHz",
  },
  { brand: "OnePlus", model: "BE2013", cpu: "-" },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "Intel(R) Core(TM) i5-9400F CPU @ 2.90GHz",
  },
  { brand: "OnePlus", model: "GM1917", cpu: "-" },
  { brand: "OnePlus", model: "LE2111", cpu: "-" },
  { brand: "OnePlus", model: "KB2001", cpu: "-" },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "AMD Ryzen 5 4500 6-Core Processor",
  },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz",
  },
  {
    brand: "OnePlus",
    model: "ONEPLUS A3010",
    cpu: "Intel(R) Core(TM) i5-2467M CPU @ 1.60GHz",
  },
  { brand: "OnePlus", model: "OnePlus8Pro", cpu: "06/3f" },
  { brand: "OnePlus", model: "GM1911", cpu: "-" },
  { brand: "OnePlus", model: "HD1913", cpu: "-" },
  { brand: "OnePlus", model: "ONEPLUS A3003", cpu: "-" },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "AMD Ryzen 9 5900X 12-Core Processor",
  },
  { brand: "OnePlus", model: "LE2110", cpu: "-" },
  { brand: "OnePlus", model: "BE2026", cpu: "-" },
  { brand: "OnePlus", model: "ONE E1001", cpu: "-" },
  { brand: "OnePlus", model: "GM1903", cpu: "-" },
  { brand: "OnePlus", model: "DE2117", cpu: "-" },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "Intel(R) Core(TM) i5-2467M CPU @ 1.60GHz",
  },
  { brand: "OnePlus", model: "LE2115", cpu: "-" },
  {
    brand: "OnePlus",
    model: "NE2211",
    cpu: "Intel(R) Core(TM) i7-4770 CPU @ 3.40GHz",
  },
  { brand: "OnePlus", model: "IN2017", cpu: "-" },
  { brand: "OnePlus", model: "DN2101", cpu: "-" },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "Intel(R) Core(TM) i5-8365U CPU @ 1.60GHz",
  },
  { brand: "OnePlus", model: "IN2019", cpu: "-" },
  { brand: "OnePlus", model: "CPH2465", cpu: "-" },
  {
    brand: "OnePlus",
    model: "NE2211",
    cpu: "Intel(R) Core(TM) i5-10300H CPU @ 2.50GHz",
  },
  {
    brand: "OnePlus",
    model: "ONEPLUS A3010",
    cpu: "Intel(R) Core(TM) i7-2600 CPU @ 3.40GHz",
  },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "Intel(R) Core(TM) i7-4770 CPU @ 3.40GHz",
  },
  { brand: "OnePlus", model: "MT2111", cpu: "-" },
  { brand: "OnePlus", model: "PHB110", cpu: "-" },
  {
    brand: "OnePlus",
    model: "ONEPLUS A3010",
    cpu: "12th Gen Intel(R) Core(TM) i5-12400F",
  },
  { brand: "OnePlus", model: "LE2120", cpu: "-" },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "Intel(R) Core(TM) i5-10400F CPU @ 2.90GHz",
  },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "Intel(R) Core(TM) i5-8250U CPU @ 1.60GHz",
  },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "Intel(R) Core(TM) i7-3720QM CPU @ 2.60GHz",
  },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "AMD Ryzen 7 3750H with Radeon Vega Mobile Gfx",
  },
  { brand: "OnePlus", model: "ONE E1005", cpu: "-" },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "AMD E1-1200 APU with Radeon(tm) HD Graphics",
  },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "Intel(R) Xeon(R) CPU E5-2678 v3 @ 2.50GHz",
  },
  { brand: "OnePlus", model: "HD1910", cpu: "-" },
  {
    brand: "OnePlus",
    model: "ONEPLUS A3010",
    cpu: "AMD Ryzen 3 3250U with Radeon Graphics",
  },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "AMD Ryzen 5 2600 Six-Core Processor",
  },
  {
    brand: "OnePlus",
    model: "NE2211",
    cpu: "Intel(R) Core(TM) i7-9700 CPU @ 3.00GHz",
  },
  {
    brand: "OnePlus",
    model: "NE2211",
    cpu: "AMD Ryzen 5 3600X 6-Core Processor",
  },
  { brand: "OnePlus", model: "LE2101", cpu: "-" },
  {
    brand: "OnePlus",
    model: "ONEPLUS A5000",
    cpu: "Intel(R) Core(TM) i3-1005G1 CPU @ 1.20GHz",
  },
  {
    brand: "OnePlus",
    model: "ONEPLUS A3010",
    cpu: "AMD Athlon Silver 3050U with Radeon Graphics",
  },
  {
    brand: "OnePlus",
    model: "NE2211",
    cpu: "Intel(R) Core(TM) i9-10850K CPU @ 3.60GHz",
  },
  { brand: "OPPO", model: "CPH2197", cpu: "-" },
  { brand: "OPPO", model: "CPH2343", cpu: "-" },
  { brand: "OPPO", model: "CPH2219", cpu: "-" },
  { brand: "OPPO", model: "CPH2339", cpu: "-" },
  { brand: "OPPO", model: "CPH2273", cpu: "-" },
  { brand: "OPPO", model: "CPH2135", cpu: "-" },
  { brand: "OPPO", model: "CPH2333", cpu: "-" },
  { brand: "OPPO", model: "CPH2271", cpu: "-" },
  { brand: "OPPO", model: "CPH2375", cpu: "-" },
  { brand: "OPPO", model: "CPH2211", cpu: "-" },
  { brand: "OPPO", model: "CPH2371", cpu: "-" },
  { brand: "OPPO", model: "CPH2089", cpu: "-" },
  { brand: "OPPO", model: "CPH1951", cpu: "-" },
  { brand: "OPPO", model: "CPH2207", cpu: "-" },
  { brand: "OPPO", model: "CPH2247", cpu: "-" },
  { brand: "OPPO", model: "CPH2251", cpu: "-" },
  { brand: "OPPO", model: "CPH1931", cpu: "-" },
  { brand: "OPPO", model: "CPH2385", cpu: "-" },
  { brand: "OPPO", model: "CPH2269", cpu: "-" },
  { brand: "OPPO", model: "CPH2185", cpu: "-" },
  { brand: "OPPO", model: "CPH2065", cpu: "-" },
  { brand: "OPPO", model: "CPH1941", cpu: "-" },
  { brand: "OPPO", model: "CPH2145", cpu: "-" },
  { brand: "OPPO", model: "CPH2305", cpu: "-" },
  { brand: "OPPO", model: "CPH2195", cpu: "-" },
  { brand: "OPPO", model: "CPH1907", cpu: "-" },
  { brand: "OPPO", model: "CPH2005", cpu: "-" },
  { brand: "OPPO", model: "CPH2069", cpu: "-" },
  { brand: "OPPO", model: "CPH2127", cpu: "-" },
  { brand: "OPPO", model: "CPH2477", cpu: "-" },
  { brand: "OPPO", model: "CPH2067", cpu: "-" },
  { brand: "OPPO", model: "CPH2021", cpu: "-" },
  { brand: "OPPO", model: "CPH2357", cpu: "-" },
  { brand: "OPPO", model: "CPH2091", cpu: "-" },
  { brand: "OPPO", model: "CPH1893", cpu: "-" },
  { brand: "OPPO", model: "CPH2161", cpu: "-" },
  { brand: "OPPO", model: "CPH2159", cpu: "-" },
  { brand: "OPPO", model: "CPH2173", cpu: "-" },
  { brand: "OPPO", model: "CPH2043", cpu: "-" },
  { brand: "OPPO", model: "CPH2483", cpu: "-" },
  { brand: "OPPO", model: "CPH1835", cpu: "-" },
  { brand: "OPPO", model: "CPH2461", cpu: "-" },
  { brand: "OPPO", model: "CPH2359", cpu: "-" },
  { brand: "OPPO", model: "CPH2307", cpu: "-" },
  { brand: "OPPO", model: "CPH2437", cpu: "-" },
  { brand: "OPPO", model: "CPH2179", cpu: "-" },
  { brand: "OPPO", model: "CPH2481", cpu: "-" },
  { brand: "OPPO", model: "CPH2205", cpu: "-" },
  { brand: "OPPO", model: "CPH1917", cpu: "-" },
  { brand: "OPPO", model: "CPH2387", cpu: "-" },
  { brand: "OPPO", model: "CPH2119", cpu: "-" },
  { brand: "OPPO", model: "CPH2009", cpu: "-" },
  { brand: "OPPO", model: "CPH2025", cpu: "-" },
  { brand: "OPPO", model: "CPH1909", cpu: "-" },
  { brand: "OPPO", model: "CPH2239", cpu: "-" },
  { brand: "OPPO", model: "CPH2121", cpu: "-" },
  { brand: "OPPO", model: "CPH2083", cpu: "-" },
  { brand: "OPPO", model: "CPH2023", cpu: "-" },
  { brand: "OPPO", model: "CPH1877", cpu: "-" },
  { brand: "OPPO", model: "CPH2363", cpu: "-" },
  { brand: "OPPO", model: "CPH1903", cpu: "-" },
  { brand: "OPPO", model: "CPH2529", cpu: "-" },
  { brand: "OPPO", model: "CPH1921", cpu: "-" },
  { brand: "OPPO", model: "CPH1979", cpu: "-" },
  { brand: "OPPO", model: "CPH2365", cpu: "-" },
  { brand: "OPPO", model: "CPH2471", cpu: "-" },
  { brand: "OPPO", model: "CPH2349", cpu: "-" },
  { brand: "OPPO", model: "CPH2473", cpu: "-" },
  { brand: "OPPO", model: "CPH2015", cpu: "-" },
  { brand: "OPPO", model: "CPH1919", cpu: "-" },
  { brand: "OPPO", model: "CPH2125", cpu: "-" },
  { brand: "OPPO", model: "CPH2095", cpu: "-" },
  { brand: "OPPO", model: "CPH2333", cpu: "ARMv8 Processor rev 0 (v8l)" },
  { brand: "OPPO", model: "CPH2505", cpu: "-" },
  { brand: "OPPO", model: "CPH1923", cpu: "-" },
  { brand: "OPPO", model: "CPH1933", cpu: "-" },
  { brand: "OPPO", model: "OPD2102A", cpu: "-" },
  { brand: "OPPO", model: "CPH1837", cpu: "-" },
  { brand: "OPPO", model: "CPH2217", cpu: "-" },
  { brand: "OPPO", model: "CPH2213", cpu: "-" },
  { brand: "OPPO", model: "CPH1803", cpu: "-" },
  { brand: "OPPO", model: "CPH1831", cpu: "-" },
  { brand: "OPPO", model: "PFDM00", cpu: "-" },
  { brand: "OPPO", model: "CPH2203", cpu: "-" },
  { brand: "OSCAL", model: "C80", cpu: "-" },
  { brand: "OSCAL", model: "C60", cpu: "-" },
  { brand: "OUKITEL", model: "C32", cpu: "-" },
  { brand: "OUKITEL", model: "C5 PRO", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "OUKITEL", model: "WP5", cpu: "-" },
  { brand: "OUKITEL", model: "C19", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "OUKITEL", model: "K9 Pro", cpu: "-" },
  { brand: "OUKITEL", model: "WP22", cpu: "-" },
  { brand: "OUKITEL", model: "C18_Pro", cpu: "-" },
  { brand: "OUKITEL", model: "WP23", cpu: "-" },
  { brand: "OUKITEL", model: "WP16", cpu: "-" },
  { brand: "OUKITEL", model: "WP18 Pro", cpu: "-" },
  { brand: "OUKITEL", model: "WP6", cpu: "-" },
  { brand: "OUKITEL", model: "WP21", cpu: "-" },
  { brand: "OUKITEL", model: "C25", cpu: "-" },
  { brand: "OUKITEL", model: "WP2", cpu: "AArch64 Processor rev 2 (aarch64)" },
  { brand: "OUKITEL", model: "C21", cpu: "-" },
  { brand: "OUKITEL", model: "C21 Pro", cpu: "-" },
  { brand: "PHONEMAX", model: "X1", cpu: "ARMv7 Processor rev 4 (v7l)" },
  {
    brand: "Prestigio",
    model: "PSP7501DUO",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  { brand: "Qubo", model: "X626", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "Qubo", model: "X668_32GB", cpu: "-" },
  { brand: "Qubo", model: "SP510", cpu: "ARMv7 Processor rev 3 (v7l)" },
  {
    brand: "R13pro",
    model: "R13pro",
    cpu: "AArch64 Processor rev 4 (aarch64)",
  },
  { brand: "realme", model: "RMX3370", cpu: "-" },
  { brand: "realme", model: "RMX3363", cpu: "-" },
  { brand: "realme", model: "RMX1993", cpu: "-" },
  { brand: "realme", model: "RMX3371", cpu: "-" },
  { brand: "realme", model: "RMX3563", cpu: "-" },
  { brand: "realme", model: "RMX3085", cpu: "-" },
  { brand: "realme", model: "RMX2202", cpu: "-" },
  { brand: "realme", model: "RMX3201", cpu: "-" },
  { brand: "realme", model: "RMX3286", cpu: "-" },
  { brand: "realme", model: "RMX3624", cpu: "-" },
  { brand: "realme", model: "RMX3081", cpu: "-" },
  { brand: "realme", model: "RMX3491", cpu: "-" },
  { brand: "realme", model: "RMX3151", cpu: "-" },
  { brand: "realme", model: "RMX3511", cpu: "-" },
  { brand: "realme", model: "RMX3301", cpu: "-" },
  { brand: "realme", model: "RMX2155", cpu: "-" },
  { brand: "realme", model: "RMX3516", cpu: "-" },
  { brand: "realme", model: "RMX3231", cpu: "ARMv8 Processor rev 0 (v8l)" },
  { brand: "realme", model: "RMX1931", cpu: "-" },
  { brand: "realme", model: "RMX3561", cpu: "-" },
  { brand: "realme", model: "RMX2170", cpu: "-" },
  { brand: "realme", model: "RMX3623", cpu: "-" },
  { brand: "realme", model: "RMX3521", cpu: "-" },
  { brand: "realme", model: "RMX2040", cpu: "-" },
  { brand: "realme", model: "RMX3710", cpu: "-" },
  { brand: "realme", model: "RMX2075", cpu: "-" },
  { brand: "realme", model: "RMX3269", cpu: "-" },
  { brand: "realme", model: "RMX2063", cpu: "-" },
  { brand: "realme", model: "RMX2001", cpu: "-" },
  { brand: "realme", model: "RMX3630", cpu: "-" },
  { brand: "realme", model: "RMX3393", cpu: "-" },
  { brand: "realme", model: "RMX1971", cpu: "-" },
  { brand: "realme", model: "RMX2144", cpu: "-" },
  { brand: "realme", model: "RMX2111", cpu: "-" },
  { brand: "realme", model: "RMP2103", cpu: "-" },
  { brand: "realme", model: "RMX3263", cpu: "-" },
  { brand: "realme", model: "RMX3581", cpu: "ARMv8 Processor rev 1 (v8l)" },
  { brand: "realme", model: "RMX2151", cpu: "-" },
  { brand: "realme", model: "RMX1911", cpu: "-" },
  { brand: "realme", model: "RMX2030", cpu: "-" },
  { brand: "realme", model: "RMX3572", cpu: "-" },
  { brand: "realme", model: "RMX3503", cpu: "-" },
  { brand: "realme", model: "RMX2086", cpu: "-" },
  { brand: "realme", model: "RMX2185", cpu: "-" },
  { brand: "realme", model: "RMX3472", cpu: "-" },
  { brand: "realme", model: "RMX3311", cpu: "-" },
  { brand: "realme", model: "RMX3241", cpu: "-" },
  { brand: "realme", model: "RMX2193", cpu: "-" },
  { brand: "realme", model: "RMX3474", cpu: "-" },
  { brand: "realme", model: "RMX3242", cpu: "-" },
  { brand: "realme", model: "RMX3513", cpu: "-" },
  { brand: "realme", model: "RMX3195", cpu: "-" },
  { brand: "realme", model: "RMX2020", cpu: "-" },
  { brand: "realme", model: "RMX3506", cpu: "-" },
  { brand: "realme", model: "RMX3501", cpu: "-" },
  { brand: "realme", model: "RMX3474", cpu: "ARMv8 Processor rev 1 (v8l)" },
  { brand: "realme", model: "RMX3430", cpu: "-" },
  { brand: "realme", model: "RMX3171", cpu: "-" },
  { brand: "realme", model: "RMX3661", cpu: "-" },
  { brand: "realme", model: "RMX3612", cpu: "-" },
  { brand: "Realme", model: "RMX1851", cpu: "-" },
  { brand: "realme", model: "RMX3627", cpu: "-" },
  { brand: "Realme", model: "Realme 5 Pro", cpu: "-" },
  { brand: "realme", model: "RMX2195", cpu: "-" },
  { brand: "realme", model: "RMX2103", cpu: "-" },
  { brand: "realme", model: "RMX3771", cpu: "-" },
  { brand: "realme", model: "RMX3360", cpu: "-" },
  { brand: "realme", model: "RMX3741", cpu: "-" },
  { brand: "realme", model: "RMX2051", cpu: "-" },
  { brand: "realme", model: "RMX3392", cpu: "-" },
  { brand: "Rockchip", model: "H96_Max_V11", cpu: "-" },
  { brand: "samsung", model: "SM-S908B", cpu: "-" },
  { brand: "samsung", model: "SM-A336B", cpu: "-" },
  { brand: "samsung", model: "SM-A515F", cpu: "-" },
  { brand: "samsung", model: "SM-A725F", cpu: "-" },
  { brand: "samsung", model: "SM-G973F", cpu: "-" },
  { brand: "samsung", model: "SM-A705FN", cpu: "-" },
  { brand: "samsung", model: "SM-A035G", cpu: "-" },
  { brand: "samsung", model: "SM-A136B", cpu: "ARMv8 Processor rev 0 (v8l)" },
  { brand: "samsung", model: "SM-G991B", cpu: "-" },
  { brand: "samsung", model: "SM-A505G", cpu: "-" },
  { brand: "samsung", model: "SM-G770F", cpu: "-" },
  { brand: "samsung", model: "SM-A320FL", cpu: "-" },
  { brand: "samsung", model: "SM-A750FN", cpu: "-" },
  { brand: "samsung", model: "SM-A307FN", cpu: "-" },
  { brand: "samsung", model: "SM-A236B", cpu: "-" },
  { brand: "samsung", model: "SM-A536B", cpu: "-" },
  { brand: "samsung", model: "SM-G950F", cpu: "-" },
  { brand: "samsung", model: "SM-A525F", cpu: "-" },
  { brand: "samsung", model: "SM-A528B", cpu: "-" },
  { brand: "samsung", model: "SM-A325F", cpu: "-" },
  { brand: "samsung", model: "SM-N980F", cpu: "-" },
  { brand: "samsung", model: "SM-A530F", cpu: "-" },
  { brand: "samsung", model: "SM-G986B", cpu: "-" },
  { brand: "samsung", model: "SM-S918B", cpu: "-" },
  { brand: "samsung", model: "SM-G970F", cpu: "-" },
  { brand: "samsung", model: "SM-A137F", cpu: "ARMv8 Processor rev 1 (v8l)" },
  { brand: "samsung", model: "SM-T515", cpu: "ARMv8 Processor rev 2 (v8l)" },
  { brand: "samsung", model: "SM-J610FN", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "samsung", model: "SM-G928F", cpu: "-" },
  { brand: "samsung", model: "SM-G975F", cpu: "-" },
  { brand: "samsung", model: "SM-A600FN", cpu: "-" },
  { brand: "samsung", model: "SM-A405FN", cpu: "-" },
  { brand: "samsung", model: "SM-G998B", cpu: "-" },
  { brand: "samsung", model: "SM-G960F", cpu: "-" },
  { brand: "samsung", model: "SM-F936B", cpu: "-" },
  { brand: "samsung", model: "SM-F926B", cpu: "-" },
  { brand: "samsung", model: "SM-A426B", cpu: "-" },
  { brand: "samsung", model: "SM-A750G", cpu: "-" },
  { brand: "samsung", model: "SM-G965F", cpu: "-" },
  { brand: "samsung", model: "SM-N981B", cpu: "-" },
  { brand: "samsung", model: "SM-A035F", cpu: "-" },
  { brand: "samsung", model: "SM-A202F", cpu: "-" },
  { brand: "samsung", model: "SM-A715F", cpu: "-" },
  { brand: "samsung", model: "SM-A127F", cpu: "-" },
  { brand: "samsung", model: "SM-A546B", cpu: "-" },
  { brand: "samsung", model: "SM-G996B", cpu: "-" },
  { brand: "samsung", model: "SM-A505F", cpu: "-" },
  { brand: "samsung", model: "SM-G998U1", cpu: "-" },
  { brand: "samsung", model: "SM-A135F", cpu: "ARMv8 Processor rev 1 (v8l)" },
  { brand: "samsung", model: "SM-A516B", cpu: "-" },
  { brand: "samsung", model: "SM-A326B", cpu: "-" },
  { brand: "samsung", model: "SM-M315F", cpu: "-" },
  { brand: "samsung", model: "SM-G780G", cpu: "-" },
  { brand: "samsung", model: "SM-A315G", cpu: "-" },
  { brand: "samsung", model: "SM-G781B", cpu: "-" },
  { brand: "samsung", model: "SM-A217F", cpu: "-" },
  { brand: "samsung", model: "SM-J415FN", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "samsung", model: "SM-G965U1", cpu: "-" },
  { brand: "samsung", model: "SM-A415F", cpu: "-" },
  { brand: "samsung", model: "SM-A520F", cpu: "-" },
  { brand: "samsung", model: "SM-G973U1", cpu: "-" },
  { brand: "samsung", model: "SM-A505FN", cpu: "-" },
  { brand: "samsung", model: "SM-N960F", cpu: "-" },
  { brand: "samsung", model: "SM-A336E", cpu: "-" },
  { brand: "samsung", model: "SM-G985F", cpu: "-" },
  { brand: "samsung", model: "SM-A125F", cpu: "-" },
  { brand: "samsung", model: "SM-N950F", cpu: "-" },
  { brand: "samsung", model: "SM-A225F", cpu: "-" },
  { brand: "samsung", model: "SM-J730F", cpu: "-" },
  { brand: "samsung", model: "SM-G980F", cpu: "-" },
  { brand: "samsung", model: "SM-G780F", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "Intel(R) Core(TM) i3-1005G1 CPU @ 1.20GHz",
  },
  { brand: "samsung", model: "SM-A510F", cpu: "-" },
  { brand: "samsung", model: "SM-A226B", cpu: "-" },
  { brand: "samsung", model: "SM-G955F", cpu: "-" },
  { brand: "samsung", model: "SM-G988B", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "Intel(R) Core(TM) i5-7200U CPU @ 2.50GHz",
  },
  { brand: "samsung", model: "SM-M515F", cpu: "-" },
  { brand: "samsung", model: "SM-J510FN", cpu: "ARMv7 Processor rev 0 (v7l)" },
  { brand: "samsung", model: "SM-N950U", cpu: "-" },
  { brand: "samsung", model: "SM-G996N", cpu: "-" },
  { brand: "samsung", model: "SM-A145R", cpu: "-" },
  { brand: "samsung", model: "SM-N960U1", cpu: "-" },
  { brand: "samsung", model: "SM-F721B", cpu: "-" },
  { brand: "samsung", model: "SM-A207F", cpu: "-" },
  { brand: "samsung", model: "SM-G990B", cpu: "-" },
  { brand: "samsung", model: "SM-G988U1", cpu: "-" },
  { brand: "samsung", model: "SM-G981B", cpu: "-" },
  { brand: "samsung", model: "SM-A025G", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "samsung", model: "SM-N970F", cpu: "-" },
  { brand: "samsung", model: "SM-A047F", cpu: "-" },
  { brand: "samsung", model: "SM-G950U1", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G973F",
    cpu: "Intel(R) Core(TM) i5-2467M CPU @ 1.60GHz",
  },
  { brand: "samsung", model: "SM-G950U", cpu: "-" },
  { brand: "samsung", model: "SM-A526B", cpu: "-" },
  { brand: "samsung", model: "SM-G935F", cpu: "-" },
  { brand: "samsung", model: "SM-N985F", cpu: "-" },
  { brand: "samsung", model: "SM-G981U", cpu: "-" },
  { brand: "samsung", model: "SM-S901B", cpu: "-" },
  { brand: "samsung", model: "SM-A217M", cpu: "-" },
  { brand: "samsung", model: "SM-G990B2", cpu: "-" },
  { brand: "samsung", model: "SM-J701F", cpu: "-" },
  { brand: "samsung", model: "SM-A037G", cpu: "-" },
  { brand: "samsung", model: "SM-M135F", cpu: "ARMv8 Processor rev 1 (v8l)" },
  { brand: "samsung", model: "SM-J600FN", cpu: "-" },
  { brand: "samsung", model: "SM-A920F", cpu: "-" },
  { brand: "samsung", model: "SM-G960U1", cpu: "-" },
  { brand: "samsung", model: "SM-M526BR", cpu: "-" },
  { brand: "samsung", model: "SM-A115F", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "samsung", model: "SM-S911B", cpu: "-" },
  { brand: "samsung", model: "SM-M215F", cpu: "-" },
  { brand: "samsung", model: "SM-A032F", cpu: "ARMv8 Processor rev 0 (v8l)" },
  { brand: "samsung", model: "SM-N770F", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-N976N",
    cpu: "Intel(R) Core(TM) i7-10700KF CPU @ 3.80GHz",
  },
  { brand: "samsung", model: "SM-J530F", cpu: "-" },
  { brand: "samsung", model: "SM-M127F", cpu: "-" },
  { brand: "samsung", model: "SM-A105FN", cpu: "ARMv8 Processor rev 2 (v8l)" },
  { brand: "samsung", model: "SM-N975F", cpu: "-" },
  { brand: "samsung", model: "SM-G960U", cpu: "-" },
  { brand: "samsung", model: "SM-T290", cpu: "-" },
  { brand: "samsung", model: "SM-J330FN", cpu: "-" },
  { brand: "samsung", model: "SM-G398FN", cpu: "-" },
  { brand: "samsung", model: "SM-G532F", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "samsung", model: "SM-A045F", cpu: "-" },
  { brand: "samsung", model: "SM-N986B", cpu: "-" },
  { brand: "samsung", model: "SM-J600G", cpu: "-" },
  { brand: "samsung", model: "SM-G975U", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-S901E",
    cpu: "Intel(R) Xeon(R) CPU E5-2699 v4 @ 2.20GHz",
  },
  { brand: "samsung", model: "SM-T580", cpu: "-" },
  { brand: "samsung", model: "SM-S906B", cpu: "-" },
  { brand: "samsung", model: "SM-A315F", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "12th Gen Intel(R) Core(TM) i5-12600KF",
  },
  { brand: "samsung", model: "SM-A736B", cpu: "-" },
  { brand: "samsung", model: "SM-G977B", cpu: "-" },
  { brand: "samsung", model: "SM-S906U1", cpu: "-" },
  { brand: "samsung", model: "SM-S916B", cpu: "-" },
  { brand: "samsung", model: "SM-X700", cpu: "-" },
  { brand: "samsung", model: "SM-A146P", cpu: "-" },
  { brand: "samsung", model: "SM-A346B", cpu: "-" },
  { brand: "samsung", model: "SM-J415F", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "samsung", model: "SM-A600G", cpu: "-" },
  { brand: "samsung", model: "SM-A125M", cpu: "-" },
  { brand: "samsung", model: "SM-T220", cpu: "-" },
  { brand: "samsung", model: "SM-A107F", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "samsung", model: "SM-N960U", cpu: "-" },
  { brand: "samsung", model: "SM-G990E", cpu: "-" },
  { brand: "samsung", model: "SM-A037F", cpu: "-" },
  { brand: "samsung", model: "SM-A536E", cpu: "-" },
  { brand: "samsung", model: "SM-J710F", cpu: "-" },
  { brand: "samsung", model: "SM-T500", cpu: "-" },
  { brand: "samsung", model: "SM-T225N", cpu: "-" },
  { brand: "samsung", model: "SM-G998N", cpu: "-" },
  { brand: "samsung", model: "SM-M236B", cpu: "-" },
  { brand: "samsung", model: "SM-F916B", cpu: "-" },
  { brand: "samsung", model: "SM-T875", cpu: "-" },
  { brand: "samsung", model: "SM-G930F", cpu: "-" },
  { brand: "samsung", model: "SM-A225M", cpu: "-" },
  { brand: "samsung", model: "SM-G610M", cpu: "-" },
  { brand: "samsung", model: "SM-T585", cpu: "-" },
  { brand: "samsung", model: "SM-F711B", cpu: "-" },
  { brand: "samsung", model: "SM-J330F", cpu: "-" },
  { brand: "samsung", model: "SM-A035M", cpu: "-" },
  { brand: "samsung", model: "SM-A516U", cpu: "-" },
  { brand: "samsung", model: "SM-G9600", cpu: "-" },
  { brand: "samsung", model: "SM-A605FN", cpu: "-" },
  { brand: "samsung", model: "SM-G920F", cpu: "-" },
  { brand: "samsung", model: "SM-G955U", cpu: "-" },
  { brand: "samsung", model: "SM-A127M", cpu: "-" },
  { brand: "samsung", model: "SM-G965U", cpu: "-" },
  { brand: "samsung", model: "SM-M115F", cpu: "ARMv8 Processor rev 4 (v8l)" },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "11th Gen Intel(R) Core(TM) i5-11400F @ 2.60GHz",
  },
  { brand: "samsung", model: "SM-G390F", cpu: "-" },
  { brand: "samsung", model: "SM-X205", cpu: "-" },
  { brand: "samsung", model: "SM-A305F", cpu: "-" },
  { brand: "samsung", model: "SM-A105F", cpu: "ARMv8 Processor rev 2 (v8l)" },
  { brand: "samsung", model: "SM-G991N", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G998B",
    cpu: "12th Gen Intel(R) Core(TM) i7-12700K",
  },
  { brand: "samsung", model: "SM-T510", cpu: "ARMv8 Processor rev 2 (v8l)" },
  { brand: "samsung", model: "SM-A022F", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "samsung", model: "SM-A500FU", cpu: "ARMv7 Processor rev 0 (v7l)" },
  { brand: "samsung", model: "SM-T860", cpu: "-" },
  { brand: "samsung", model: "SM-G610F", cpu: "-" },
  { brand: "samsung", model: "SM-G925F", cpu: "-" },
  { brand: "samsung", model: "SM-G973U", cpu: "-" },
  { brand: "samsung", model: "SM-M325FV", cpu: "-" },
  { brand: "samsung", model: "SM-J260F", cpu: "-" },
  { brand: "samsung", model: "SM-M336B", cpu: "-" },
  { brand: "samsung", model: "SM-G991U1", cpu: "-" },
  { brand: "samsung", model: "SM-P615", cpu: "-" },
  { brand: "samsung", model: "SM-A042F", cpu: "-" },
  { brand: "samsung", model: "SM-S908E", cpu: "-" },
  { brand: "samsung", model: "SM-A705MN", cpu: "-" },
  { brand: "samsung", model: "SM-T595", cpu: "-" },
  { brand: "samsung", model: "SM-A145P", cpu: "-" },
  { brand: "samsung", model: "SM-X200", cpu: "-" },
  { brand: "samsung", model: "SM-G986N", cpu: "-" },
  { brand: "samsung", model: "SM-M325F", cpu: "-" },
  { brand: "samsung", model: "SM-S134DL", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "AMD Ryzen 5 5600G with Radeon Graphics",
  },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "Intel(R) Core(TM) i7-2600 CPU @ 3.40GHz",
  },
  { brand: "samsung", model: "SM-G903F", cpu: "-" },
  { brand: "samsung", model: "SM-G955U1", cpu: "-" },
  { brand: "samsung", model: "SM-X900", cpu: "-" },
  { brand: "samsung", model: "SM-P613", cpu: "-" },
  { brand: "samsung", model: "SM-A750F", cpu: "-" },
  { brand: "samsung", model: "SM-A515U1", cpu: "-" },
  { brand: "samsung", model: "SM-G570F", cpu: "-" },
  { brand: "samsung", model: "SM-G981V", cpu: "-" },
  { brand: "samsung", model: "SM-N976B", cpu: "-" },
  { brand: "samsung", model: "SM-T813", cpu: "-" },
  { brand: "samsung", model: "SM-G9650", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G965N",
    cpu: "Intel(R) Core(TM) i7-2670QM CPU @ 2.20GHz",
  },
  { brand: "samsung", model: "SM-A707F", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-S908N",
    cpu: "11th Gen Intel(R) Core(TM) i9-11900KF @ 3.50GHz",
  },
  { brand: "samsung", model: "SM-G977N", cpu: "-" },
  { brand: "samsung", model: "SM-N981N", cpu: "-" },
  { brand: "samsung", model: "SM-G930U", cpu: "-" },
  { brand: "samsung", model: "SM-M225FV", cpu: "-" },
  { brand: "samsung", model: "SM-A032M", cpu: "ARMv8 Processor rev 0 (v8l)" },
  { brand: "samsung", model: "SM-A022G", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "samsung", model: "SM-M013F", cpu: "ARMv7 Processor rev 4 (v7l)" },
  {
    brand: "samsung",
    model: "SM-G998B",
    cpu: "Intel(R) Core(TM) i5-8265U CPU @ 1.60GHz",
  },
  { brand: "samsung", model: "SM-A305G", cpu: "-" },
  { brand: "samsung", model: "SM-G715FN", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "11th Gen Intel(R) Core(TM) i3-1115G4 @ 3.00GHz",
  },
  { brand: "samsung", model: "SM-M307FN", cpu: "-" },
  { brand: "samsung", model: "SM-A013F", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "samsung", model: "SM-A307GN", cpu: "-" },
  { brand: "samsung", model: "SM-G981N", cpu: "-" },
  { brand: "samsung", model: "SM-A115M", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "samsung", model: "SM-A805F", cpu: "-" },
  { brand: "samsung", model: "SM-A102U", cpu: "ARMv8 Processor rev 2 (v8l)" },
  { brand: "samsung", model: "SM-A908B", cpu: "-" },
  { brand: "samsung", model: "SM-A325M", cpu: "-" },
  { brand: "samsung", model: "SM-A720F", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "Intel(R) Core(TM) i7-10750H CPU @ 2.60GHz",
  },
  { brand: "samsung", model: "SM-A215U", cpu: "-" },
  { brand: "samsung", model: "SM-G9500", cpu: "-" },
  { brand: "samsung", model: "SM-G965N", cpu: "-" },
  { brand: "samsung", model: "SM-N986U1", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G970N",
    cpu: "11th Gen Intel(R) Core(TM) i5-11400H @ 2.70GHz",
  },
  { brand: "samsung", model: "SM-G525F", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-S901E",
    cpu: "Intel(R) Core(TM) i7-4770 CPU @ 3.40GHz",
  },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "11th Gen Intel(R) Core(TM) i5-11400H @ 2.70GHz",
  },
  {
    brand: "samsung",
    model: "SM-G955N",
    cpu: "11th Gen Intel(R) Core(TM) i5-11400H @ 2.70GHz",
  },
  { brand: "samsung", model: "SM-G991U", cpu: "-" },
  { brand: "samsung", model: "SM-G973N", cpu: "-" },
  { brand: "samsung", model: "SM-A145F", cpu: "-" },
  { brand: "samsung", model: "SM-A235F", cpu: "-" },
  { brand: "samsung", model: "SM-A207M", cpu: "-" },
  { brand: "samsung", model: "SM-G975U1", cpu: "-" },
  { brand: "samsung", model: "SM-A013G", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "samsung", model: "SM-M127G", cpu: "-" },
  { brand: "samsung", model: "SM-J415G", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "samsung", model: "SM-A205F", cpu: "-" },
  { brand: "samsung", model: "SM-S901N", cpu: "-" },
  { brand: "samsung", model: "SM-A507FN", cpu: "-" },
  { brand: "samsung", model: "SM-A025F", cpu: "ARMv8 Processor rev 4 (v8l)" },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "Intel(R) Core(TM) i5-2467M CPU @ 1.60GHz",
  },
  {
    brand: "samsung",
    model: "SM-G973F",
    cpu: "Intel(R) Core(TM) i5-9300H CPU @ 2.40GHz",
  },
  { brand: "samsung", model: "SM-A307G", cpu: "-" },
  { brand: "samsung", model: "SM-G9880", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G955F",
    cpu: "Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz",
  },
  { brand: "samsung", model: "SM-T725", cpu: "-" },
  { brand: "samsung", model: "SM-A310F", cpu: "-" },
  { brand: "samsung", model: "SM-A226L", cpu: "-" },
  { brand: "samsung", model: "SM-S908B", cpu: "ARMv8 Processor rev 0 (v8l)" },
  { brand: "samsung", model: "SM-T865", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-N975F",
    cpu: "11th Gen Intel(R) Core(TM) i7-1165G7 @ 2.80GHz",
  },
  {
    brand: "samsung",
    model: "SM-N975F",
    cpu: "Intel(R) Core(TM) i5-8250U CPU @ 1.60GHz",
  },
  {
    brand: "samsung",
    model: "SM-G970N",
    cpu: "Intel(R) Core(TM) i5-8250U CPU @ 1.60GHz",
  },
  { brand: "samsung", model: "SM-A525F", cpu: "ARMv8 Processor rev 15 (v8l)" },
  { brand: "samsung", model: "SM-T815", cpu: "ARMv7 Processor rev 0 (v7l)" },
  { brand: "samsung", model: "SM-A205G", cpu: "-" },
  { brand: "samsung", model: "SM-N910F", cpu: "ARMv7 Processor rev 1 (v7l)" },
  { brand: "samsung", model: "SM-P610", cpu: "-" },
  { brand: "samsung", model: "SM-A716S", cpu: "-" },
  { brand: "samsung", model: "SM-S906N", cpu: "-" },
  { brand: "samsung", model: "SM-S908N", cpu: "-" },
  { brand: "samsung", model: "SM-A105G", cpu: "ARMv8 Processor rev 2 (v8l)" },
  { brand: "samsung", model: "SM-G950F", cpu: "ARMv8 Processor rev 0 (v8l)" },
  {
    brand: "samsung",
    model: "SM-G965N",
    cpu: "AMD Ryzen 5 3500U with Radeon Vega Mobile Gfx",
  },
  { brand: "samsung", model: "SM-A305GT", cpu: "-" },
  { brand: "samsung", model: "SM-S918U", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G977N",
    cpu: "AMD Ryzen 7 3800X 8-Core Processor",
  },
  { brand: "samsung", model: "SM-A136U", cpu: "ARMv8 Processor rev 0 (v8l)" },
  { brand: "samsung", model: "SM-G988N", cpu: "-" },
  { brand: "samsung", model: "SM-M536B", cpu: "-" },
  { brand: "samsung", model: "SM-S9110", cpu: "-" },
  { brand: "samsung", model: "SM-F711U1", cpu: "-" },
  { brand: "samsung", model: "SM-A750GN", cpu: "-" },
  { brand: "samsung", model: "SM-J710MN", cpu: "-" },
  { brand: "samsung", model: "SM-F415F", cpu: "-" },
  { brand: "samsung", model: "SM-A105M", cpu: "ARMv8 Processor rev 2 (v8l)" },
  { brand: "samsung", model: "SM-A805N", cpu: "-" },
  { brand: "samsung", model: "SM-S9060", cpu: "-" },
  { brand: "samsung", model: "SM-S908U1", cpu: "-" },
  { brand: "samsung", model: "SM-A546B", cpu: "ARMv8 Processor rev 1 (v8l)" },
  { brand: "samsung", model: "SM-G981U1", cpu: "-" },
  { brand: "samsung", model: "SM-S908U", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G973F",
    cpu: "AMD Ryzen 9 5900X 12-Core Processor",
  },
  { brand: "samsung", model: "SM-T736B", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G998B",
    cpu: "Intel(R) Core(TM) i5-8250U CPU @ 1.60GHz",
  },
  { brand: "samsung", model: "SM-J701M", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "Intel(R) Core(TM) i3-9100F CPU @ 3.60GHz",
  },
  { brand: "samsung", model: "SM-T555", cpu: "ARMv7 Processor rev 0 (v7l)" },
  { brand: "samsung", model: "SM-A235M", cpu: "-" },
  { brand: "samsung", model: "SM-T505", cpu: "-" },
  { brand: "samsung", model: "SM-G970U", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-N976N",
    cpu: "AMD Ryzen 9 5900X 12-Core Processor",
  },
  {
    brand: "samsung",
    model: "SM-G973N",
    cpu: "Intel(R) Core(TM) i7-10750H CPU @ 2.60GHz",
  },
  { brand: "samsung", model: "SM-G532M", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "samsung", model: "SM-G901F", cpu: "ARMv7 Processor rev 1 (v7l)" },
  { brand: "samsung", model: "SM-M205FN", cpu: "-" },
  { brand: "samsung", model: "SM-G9350", cpu: "-" },
  { brand: "samsung", model: "SM-T720", cpu: "-" },
  { brand: "samsung", model: "SM-T550", cpu: "ARMv7 Processor rev 0 (v7l)" },
  { brand: "samsung", model: "SM-A226BR", cpu: "-" },
  { brand: "samsung", model: "SCV42", cpu: "-" },
  { brand: "samsung", model: "SM-G970U1", cpu: "-" },
  { brand: "samsung", model: "SM-G920F", cpu: "ARMv7 Processor rev 1 (v7l)" },
  { brand: "samsung", model: "SM-G960N", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-N976N",
    cpu: "12th Gen Intel(R) Core(TM) i7-12700H",
  },
  { brand: "samsung", model: "SM-N976V", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G998B",
    cpu: "Intel(R) Core(TM) i7-4770 CPU @ 3.40GHz",
  },
  { brand: "samsung", model: "SM-P619", cpu: "-" },
  { brand: "samsung", model: "SM-G920W8", cpu: "-" },
  { brand: "samsung", model: "SM-A315N", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G973N",
    cpu: "Intel(R) Core(TM) i3-6006U CPU @ 2.00GHz",
  },
  {
    brand: "samsung",
    model: "SM-G977N",
    cpu: "Intel(R) Core(TM) i7-8700K CPU @ 3.70GHz",
  },
  { brand: "samsung", model: "SM-T870", cpu: "-" },
  { brand: "samsung", model: "SM-J500FN", cpu: "ARMv7 Processor rev 0 (v7l)" },
  {
    brand: "samsung",
    model: "SM-G965N",
    cpu: "Intel(R) Core(TM) i7-8650U CPU @ 1.90GHz",
  },
  { brand: "samsung", model: "SM-M205G", cpu: "-" },
  { brand: "samsung", model: "SM-A300FU", cpu: "ARMv7 Processor rev 0 (v7l)" },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "Intel(R) Core(TM) i9-10980HK CPU @ 2.40GHz",
  },
  {
    brand: "samsung",
    model: "SM-G973N",
    cpu: "12th Gen Intel(R) Core(TM) i7-12700H",
  },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "Intel(R) Core(TM) i9-10980HK CPU @ 2.40GHz",
  },
  {
    brand: "samsung",
    model: "SM-G9880",
    cpu: "12th Gen Intel(R) Core(TM) i7-12700H",
  },
  {
    brand: "samsung",
    model: "SM-N975F",
    cpu: "Intel(R) Core(TM) i9-10980HK CPU @ 2.40GHz",
  },
  { brand: "samsung", model: "SM-J737T", cpu: "-" },
  { brand: "samsung", model: "SM-J400G", cpu: "-" },
  { brand: "samsung", model: "SM-G998U", cpu: "-" },
  { brand: "samsung", model: "SM-M022F", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "samsung", model: "SM-A525M", cpu: "-" },
  { brand: "samsung", model: "SM-T705", cpu: "-" },
  { brand: "samsung", model: "SM-J500M", cpu: "ARMv7 Processor rev 0 (v7l)" },
  { brand: "samsung", model: "SM-A107M", cpu: "ARMv8 Processor rev 4 (v8l)" },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "Intel(R) Core(TM) i7-2670QM CPU @ 2.20GHz",
  },
  { brand: "samsung", model: "SM-N981U", cpu: "-" },
  { brand: "samsung", model: "SM-A700F", cpu: "ARMv7 Processor rev 3 (v7l)" },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "AMD Ryzen 5 1600 Six-Core Processor",
  },
  { brand: "samsung", model: "SM-A102N", cpu: "ARMv8 Processor rev 2 (v8l)" },
  { brand: "samsung", model: "SM-T225", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "AMD Ryzen 5 3600 6-Core Processor",
  },
  { brand: "samsung", model: "SM-N9500", cpu: "-" },
  {
    brand: "samsung",
    model: "SCG02",
    cpu: "Intel(R) Celeron(R) N4120 CPU @ 1.10GHz",
  },
  { brand: "samsung", model: "SM-J337P", cpu: "-" },
  { brand: "samsung", model: "SM-G900F", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "Intel(R) Core(TM) i5-6300HQ CPU @ 2.30GHz",
  },
  { brand: "samsung", model: "SM-A145M", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G9810",
    cpu: "Intel(R) Core(TM) i7-2670QM CPU @ 2.20GHz",
  },
  { brand: "samsung", model: "SM-J810M", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G930K",
    cpu: "Intel(R) Core(TM) i5-8257U CPU @ 1.40GHz",
  },
  { brand: "samsung", model: "SM-G986U", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "Intel(R) Core(TM) i3-4330 CPU @ 3.50GHz",
  },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "Intel(R) Core(TM) i5-6300U CPU @ 2.40GHz",
  },
  { brand: "samsung", model: "SM-S260DL", cpu: "-" },
  { brand: "samsung", model: "SM-A146U", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-A908N",
    cpu: "Intel(R) Core(TM) i7-4770 CPU @ 3.40GHz",
  },
  {
    brand: "samsung",
    model: "SM-G973F",
    cpu: "Intel(R) Core(TM) i7-4770 CPU @ 3.40GHz",
  },
  { brand: "samsung", model: "SM-J400M", cpu: "-" },
  { brand: "samsung", model: "SM-G935W8", cpu: "-" },
  { brand: "samsung", model: "SM-G986U1", cpu: "-" },
  { brand: "samsung", model: "SM-A135M", cpu: "ARMv8 Processor rev 1 (v8l)" },
  { brand: "samsung", model: "SM-G965W", cpu: "-" },
  { brand: "samsung", model: "SM-A125U1", cpu: "-" },
  { brand: "samsung", model: "SM-A015M", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "samsung", model: "SM-S901U1", cpu: "-" },
  { brand: "samsung", model: "SM-G9860", cpu: "-" },
  { brand: "samsung", model: "SM-G930V", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G965N",
    cpu: "AMD Ryzen 5 5600X 6-Core Processor",
  },
  { brand: "samsung", model: "SM-S906U", cpu: "-" },
  { brand: "samsung", model: "SM-A205W", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G977N",
    cpu: "Intel(R) Celeron(R) N4120 CPU @ 1.10GHz",
  },
  { brand: "samsung", model: "SM-A346B", cpu: "ARMv8 Processor rev 0 (v8l)" },
  { brand: "samsung", model: "SM-N975U1", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G955N",
    cpu: "AMD Ryzen 5 3500U with Radeon Vega Mobile Gfx",
  },
  {
    brand: "samsung",
    model: "SM-G955N",
    cpu: "AMD Ryzen 5 5500U with Radeon Graphics",
  },
  { brand: "samsung", model: "G950F", cpu: "-" },
  { brand: "samsung", model: "SM-A526U", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "Intel(R) Core(TM) i7-4770 CPU @ 3.40GHz",
  },
  { brand: "samsung", model: "SM-A205U", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-N975F",
    cpu: "Intel(R) Core(TM) i7-10750H CPU @ 2.60GHz",
  },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "12th Gen Intel(R) Core(TM) i7-12700H",
  },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "AMD Ryzen 5 2600 Six-Core Processor",
  },
  { brand: "samsung", model: "SM-J810Y", cpu: "-" },
  { brand: "samsung", model: "SM-A505U1", cpu: "-" },
  { brand: "samsung", model: "SM-M105F", cpu: "-" },
  { brand: "samsung", model: "SM-N9750", cpu: "-" },
  { brand: "samsung", model: "SM-J500F", cpu: "ARMv7 Processor rev 0 (v7l)" },
  { brand: "samsung", model: "SM-M025F", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "samsung", model: "SM-A536B", cpu: "ARMv8 Processor rev 1 (v8l)" },
  { brand: "samsung", model: "SM-F900F", cpu: "-" },
  { brand: "samsung", model: "SM-A320F", cpu: "-" },
  { brand: "samsung", model: "SM-N920C", cpu: "-" },
  { brand: "samsung", model: "SM-F936U", cpu: "-" },
  { brand: "samsung", model: "SM-G988U", cpu: "-" },
  { brand: "samsung", model: "SM-G960W", cpu: "-" },
  { brand: "samsung", model: "SM-S918B", cpu: "ARMv8 Processor rev 0 (v8l)" },
  { brand: "samsung", model: "SM-G9730", cpu: "-" },
  { brand: "samsung", model: "SM-M317F", cpu: "-" },
  { brand: "samsung", model: "SM-A127F", cpu: "ARMv8 Processor rev 1 (v8l)" },
  { brand: "samsung", model: "SM-N986U", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "Intel(R) Core(TM) i7-4770 CPU @ 3.40GHz",
  },
  {
    brand: "samsung",
    model: "SM-S901N",
    cpu: "11th Gen Intel(R) Core(TM) i7-11370H @ 3.30GHz",
  },
  { brand: "samsung", model: "SM-N986N", cpu: "-" },
  { brand: "samsung", model: "SM-G998W", cpu: "-" },
  { brand: "samsung", model: "SM-J610F", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "samsung", model: "SM-G781U", cpu: "-" },
  { brand: "samsung", model: "SM-A047M", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G930T",
    cpu: "AArch64 Processor rev 2 (aarch64)",
  },
  {
    brand: "samsung",
    model: "SM-N975F",
    cpu: "Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz",
  },
  { brand: "samsung", model: "SM-N9600", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G935F",
    cpu: "Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz",
  },
  {
    brand: "samsung",
    model: "SM-T825",
    cpu: "AArch64 Processor rev 2 (aarch64)",
  },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "AMD Ryzen 5 1600 Six-Core Processor",
  },
  { brand: "samsung", model: "SM-G9910", cpu: "-" },
  { brand: "samsung", model: "SM-A015F", cpu: "ARMv8 Processor rev 4 (v8l)" },
  {
    brand: "samsung",
    model: "SM-A908N",
    cpu: "AMD Ryzen 3 5300U with Radeon Graphics",
  },
  { brand: "samsung", model: "SM-A037M", cpu: "-" },
  { brand: "samsung", model: "SM-G9900", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "AMD Ryzen 5 4500U with Radeon Graphics",
  },
  { brand: "samsung", model: "SM-J600F", cpu: "-" },
  { brand: "samsung", model: "SM-J260FU", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "samsung", model: "SM-N975U", cpu: "-" },
  { brand: "samsung", model: "SM-G975W", cpu: "-" },
  { brand: "samsung", model: "SM-A013M", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "samsung", model: "SM-A730F", cpu: "-" },
  { brand: "samsung", model: "SM-S515DL", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-S901N",
    cpu: "Intel(R) Xeon(R) CPU E3-1505M v5 @ 2.80GHz",
  },
  { brand: "samsung", model: "SM-E625F", cpu: "-" },
  { brand: "samsung", model: "SM-T295", cpu: "-" },
  { brand: "samsung", model: "SM-A546E", cpu: "-" },
  { brand: "samsung", model: "SM-T976B", cpu: "-" },
  { brand: "samsung", model: "SM-A025M", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "samsung", model: "SM-X706B", cpu: "-" },
  { brand: "samsung", model: "SM-T733", cpu: "-" },
  { brand: "samsung", model: "SM-G781W", cpu: "-" },
  { brand: "samsung", model: "SM-G996U1", cpu: "-" },
  { brand: "samsung", model: "SM-E236B", cpu: "-" },
  { brand: "samsung", model: "SM-A326U1", cpu: "-" },
  { brand: "samsung", model: "SM-J106M", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "samsung", model: "SM-F127G", cpu: "-" },
  { brand: "samsung", model: "SM-G935S", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G973N",
    cpu: "Intel(R) Core(TM) i5-10210U CPU @ 1.60GHz",
  },
  { brand: "samsung", model: "SM-A405FM", cpu: "-" },
  { brand: "samsung", model: "SM-A315G", cpu: "ARMv8 Processor rev 1 (v8l)" },
  { brand: "samsung", model: "SM-N950U1", cpu: "-" },
  { brand: "samsung", model: "SM-A505GT", cpu: "-" },
  { brand: "samsung", model: "SM-A145R", cpu: "ARMv8 Processor rev 1 (v8l)" },
  { brand: "samsung", model: "SM-G7810", cpu: "-" },
  { brand: "samsung", model: "SM-M526B", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G977N",
    cpu: "AMD Ryzen 7 4800H with Radeon Graphics",
  },
  {
    brand: "samsung",
    model: "SM-G998B",
    cpu: "Intel(R) Core(TM) i5-9400F CPU @ 2.90GHz",
  },
  {
    brand: "samsung",
    model: "SM-G998B",
    cpu: "Intel(R) Core(TM) i3-7130U CPU @ 2.70GHz",
  },
  { brand: "samsung", model: "SM-F700F", cpu: "-" },
  { brand: "samsung", model: "SM-A260F", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G975N",
    cpu: "Intel(R) Core(TM) i7-10750H CPU @ 2.60GHz",
  },
  { brand: "samsung", model: "SM-S918U1", cpu: "-" },
  { brand: "samsung", model: "SM-T970", cpu: "-" },
  { brand: "samsung", model: "SM-A260G", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "11th Gen Intel(R) Core(TM) i5-11400H @ 2.70GHz",
  },
  { brand: "samsung", model: "SAMSUNG-SM-G930A", cpu: "-" },
  { brand: "samsung", model: "SM-S9010", cpu: "-" },
  { brand: "samsung", model: "SM-A908N", cpu: "-" },
  { brand: "samsung", model: "SM-G781U1", cpu: "-" },
  { brand: "samsung", model: "SM-G9750", cpu: "-" },
  { brand: "samsung", model: "SM-A605GN", cpu: "-" },
  { brand: "samsung", model: "SM-N910C", cpu: "ARMv7 Processor rev 0 (v7l)" },
  { brand: "samsung", model: "SM-A725M", cpu: "-" },
  { brand: "samsung", model: "SM-G935U", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G977N",
    cpu: "13th Gen Intel(R) Core(TM) i9-13900F",
  },
  { brand: "samsung", model: "SM-G736B", cpu: "-" },
  { brand: "samsung", model: "SM-F707B", cpu: "-" },
  { brand: "samsung", model: "SM-G991Q", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "Intel(R) Celeron(R) CPU N3350 @ 1.10GHz",
  },
  { brand: "samsung", model: "SM-G9550", cpu: "-" },
  { brand: "samsung", model: "SM-A8050", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G970N",
    cpu: "AMD Ryzen 7 4800H with Radeon Graphics",
  },
  {
    brand: "samsung",
    model: "SM-G955N",
    cpu: "AMD Ryzen 7 4800H with Radeon Graphics",
  },
  { brand: "samsung", model: "SM-J510H", cpu: "ARMv7 Processor rev 0 (v7l)" },
  { brand: "samsung", model: "SAMSUNG-SM-N920A", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G965N",
    cpu: "Intel(R) Xeon(R) CPU E5-2630 v3 @ 2.40GHz",
  },
  { brand: "samsung", model: "SM-G611M", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "AMD Ryzen 7 4800H with Radeon Graphics",
  },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "AMD Ryzen 7 4800H with Radeon Graphics",
  },
  {
    brand: "samsung",
    model: "SM-G9810",
    cpu: "11th Gen Intel(R) Core(TM) i7-1165G7 @ 2.80GHz",
  },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "AMD Ryzen 5 3600X 6-Core Processor",
  },
  { brand: "samsung", model: "SM-S901E", cpu: "AMD Ryzen 5 5500" },
  { brand: "samsung", model: "SM-A910F", cpu: "-" },
  { brand: "samsung", model: "SM-G990U1", cpu: "-" },
  { brand: "samsung", model: "SM-T820", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-N976N",
    cpu: "12th Gen Intel(R) Core(TM) i7-12700",
  },
  {
    brand: "samsung",
    model: "SM-N975F",
    cpu: "Intel(R) Core(TM) i7-1065G7 CPU @ 1.30GHz",
  },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "11th Gen Intel(R) Core(TM) i7-11390H @ 3.40GHz",
  },
  { brand: "samsung", model: "SM-J250M", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "samsung", model: "SM-G9287", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-N976N",
    cpu: "Intel(R) Core(TM) i7-8565U CPU @ 1.80GHz",
  },
  { brand: "samsung", model: "SM-N970U1", cpu: "-" },
  { brand: "samsung", model: "SM-T805", cpu: "-" },
  { brand: "samsung", model: "SM-S901B", cpu: "ARMv8 Processor rev 0 (v8l)" },
  { brand: "samsung", model: "SM-G800F", cpu: "-" },
  { brand: "samsung", model: "SM-A326U", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "Intel(R) Core(TM) i5-7300U CPU @ 2.60GHz",
  },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "Intel(R) Core(TM) i5-3210M CPU @ 2.50GHz",
  },
  { brand: "samsung", model: "SM-A7050", cpu: "-" },
  { brand: "samsung", model: "SM-A245F", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-N976N",
    cpu: "13th Gen Intel(R) Core(TM) i7-13700KF",
  },
  { brand: "samsung", model: "SM-F926B", cpu: "ARMv8 Processor rev 0 (v8l)" },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "AMD Ryzen Threadripper 1900X 8-Core Processor",
  },
  {
    brand: "samsung",
    model: "SM-G973N",
    cpu: "Intel(R) Core(TM) i7-7700 CPU @ 3.60GHz",
  },
  { brand: "samsung", model: "SM-G781V", cpu: "-" },
  { brand: "samsung", model: "SCV41", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz",
  },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "Intel(R) Core(TM) i3-7020U CPU @ 2.30GHz",
  },
  {
    brand: "samsung",
    model: "SM-S901E",
    cpu: "AMD Ryzen 7 3700U with Radeon Vega Mobile Gfx",
  },
  { brand: "samsung", model: "SM-S9080", cpu: "-" },
  { brand: "samsung", model: "SM-A205FN", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "Intel(R) Core(TM) i5-9300HF CPU @ 2.40GHz",
  },
  { brand: "samsung", model: "SM-G600FY", cpu: "ARMv7 Processor rev 0 (v7l)" },
  {
    brand: "samsung",
    model: "SM-N960N",
    cpu: "Intel(R) Core(TM) i3-7020U CPU @ 2.30GHz",
  },
  {
    brand: "samsung",
    model: "SM-G998B",
    cpu: "Intel(R) Pentium(R) CPU G3260 @ 3.30GHz",
  },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "AMD Ryzen 7 3700X 8-Core Processor",
  },
  {
    brand: "samsung",
    model: "SM-G998B",
    cpu: "AMD A10-9620P RADEON R5; 10 COMPUTE CORES 4C+6G",
  },
  { brand: "samsung", model: "SM-A5360", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-N976N",
    cpu: "Intel(R) Xeon(R) CPU E5-2630 v3 @ 2.40GHz",
  },
  { brand: "samsung", model: "SM-N935F", cpu: "-" },
  { brand: "samsung", model: "SM-A022M", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "samsung", model: "SM-G975N", cpu: "-" },
  { brand: "samsung", model: "SM-G950U", cpu: "ARMv8 Processor rev 1 (v8l)" },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "Intel(R) Core(TM) i5-9300H CPU @ 2.40GHz",
  },
  {
    brand: "samsung",
    model: "SM-G970N",
    cpu: "Intel(R) Core(TM) i7-10750H CPU @ 2.60GHz",
  },
  { brand: "samsung", model: "SM-M022G", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "samsung", model: "SM-S901W", cpu: "-" },
  { brand: "samsung", model: "SM-G9810", cpu: "-" },
  { brand: "samsung", model: "SM-A715W", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-S908E",
    cpu: "Intel(R) Core(TM) i7-8700K CPU @ 3.70GHz",
  },
  { brand: "samsung", model: "SM-C9000", cpu: "-" },
  { brand: "samsung", model: "SM-T575", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G973N",
    cpu: "AMD Ryzen 5 3500U with Radeon Vega Mobile Gfx",
  },
  { brand: "samsung", model: "SM-F9260", cpu: "-" },
  { brand: "samsung", model: "SM-A125F", cpu: "4900" },
  {
    brand: "samsung",
    model: "SM-G955N",
    cpu: "AMD Ryzen 5 4600H with Radeon Graphics",
  },
  { brand: "samsung", model: "SM-X906B", cpu: "-" },
  { brand: "samsung", model: "SM-G8858", cpu: "-" },
  { brand: "samsung", model: "SM-A236U", cpu: "-" },
  { brand: "samsung", model: "SM-A305FN", cpu: "-" },
  { brand: "samsung", model: "SM-F926U", cpu: "-" },
  { brand: "samsung", model: "SM-N950N", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G988N",
    cpu: "Intel(R) Core(TM) i5-4200M CPU @ 2.50GHz",
  },
  {
    brand: "samsung",
    model: "SM-G965N",
    cpu: "11th Gen Intel(R) Core(TM) i5-11300H @ 3.10GHz",
  },
  {
    brand: "samsung",
    model: "SM-S908N",
    cpu: "11th Gen Intel(R) Core(TM) i5-11300H @ 3.10GHz",
  },
  { brand: "samsung", model: "SM-A536V", cpu: "-" },
  { brand: "samsung", model: "SM-G996U", cpu: "-" },
  {
    brand: "samsung",
    model: "SM-G965N",
    cpu: "AMD Ryzen 9 5900X 12-Core Processor",
  },
  { brand: "samsung", model: "14 Pro Max", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "samsung", model: "SM-A125U", cpu: "-" },
  { brand: "SHARP", model: "FS8032", cpu: "-" },
  { brand: "SM-G9880", model: "SM-G9880", cpu: "AMD Ryzen 5 5500" },
  {
    brand: "SMART PRODUCTS CONNECTION SA",
    model: "GRAVITY-2-MAX",
    cpu: "ARMv8 Processor",
  },
  {
    brand: "Smart Products Connection SA",
    model: "SMART_ULTIMATE_64",
    cpu: "-",
  },
  { brand: "Sony", model: "J8110", cpu: "-" },
  { brand: "Sony", model: "G8141", cpu: "-" },
  { brand: "Sony", model: "G3121", cpu: "AArch64 Processor rev 4 (aarch64)" },
  { brand: "Sony", model: "XQ-CT54", cpu: "-" },
  { brand: "Sony", model: "F8331", cpu: "-" },
  { brand: "Sony", model: "H4113", cpu: "-" },
  { brand: "Sony", model: "J9110", cpu: "-" },
  { brand: "Sony", model: "XQ-AD51", cpu: "-" },
  { brand: "Sony", model: "F3211", cpu: "AArch64 Processor rev 2 (aarch64)" },
  { brand: "Sony", model: "XQ-BE52", cpu: "-" },
  { brand: "Sony", model: "G3221", cpu: "AArch64 Processor rev 4 (aarch64)" },
  { brand: "Sony", model: "XQ-BT52", cpu: "-" },
  { brand: "Sony", model: "J9210", cpu: "-" },
  { brand: "Sony", model: "XQ-CC54", cpu: "-" },
  { brand: "Sony", model: "H4311", cpu: "AArch64 Processor rev 3 (aarch64)" },
  { brand: "Sony", model: "E6683", cpu: "-" },
  { brand: "Sony", model: "H8116", cpu: "-" },
  { brand: "Sony", model: "F3111", cpu: "AArch64 Processor rev 2 (aarch64)" },
  { brand: "Sony", model: "H3311", cpu: "AArch64 Processor rev 3 (aarch64)" },
  { brand: "Sony", model: "E2303", cpu: "-" },
  { brand: "Sony", model: "XQ-AU52", cpu: "-" },
  { brand: "Sony", model: "I3312", cpu: "-" },
  { brand: "Sony", model: "G8341", cpu: "-" },
  { brand: "Sony", model: "H3113", cpu: "-" },
  { brand: "Sony", model: "G3311", cpu: "AArch64 Processor rev 3 (aarch64)" },
  { brand: "Sony", model: "XQ-BQ52", cpu: "-" },
  { brand: "Sony", model: "G8342", cpu: "-" },
  { brand: "Sony", model: "XQ-AS52", cpu: "-" },
  { brand: "Sony", model: "XQ-AT51", cpu: "-" },
  { brand: "Sony", model: "G3421", cpu: "AArch64 Processor rev 4 (aarch64)" },
  { brand: "Sony", model: "XQ-AD52", cpu: "-" },
  { brand: "Sony", model: "G8441", cpu: "-" },
  { brand: "Sony", model: "H8416", cpu: "-" },
  { brand: "Sony", model: "I4113", cpu: "-" },
  { brand: "Sony", model: "XQ-BC52", cpu: "-" },
  { brand: "Sony", model: "H8216", cpu: "-" },
  { brand: "Sony", model: "H8266", cpu: "-" },
  { brand: "Sony", model: "I3113", cpu: "-" },
  { brand: "Sony", model: "G3312", cpu: "AArch64 Processor rev 3 (aarch64)" },
  { brand: "Sony", model: "XQ-BE72", cpu: "-" },
  { brand: "Sony", model: "D6603", cpu: "-" },
  { brand: "Sony", model: "E5823", cpu: "-" },
  { brand: "Sony", model: "F3311", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "Sony", model: "D6503", cpu: "-" },
  { brand: "Sony", model: "F5321", cpu: "-" },
  { brand: "Sony", model: "XQ-CQ54", cpu: "-" },
  { brand: "Sony", model: "E5603", cpu: "AArch64 Processor rev 2 (aarch64)" },
  { brand: "Sony", model: "XQ-DQ54", cpu: "-" },
  { brand: "Sony", model: "G3112", cpu: "AArch64 Processor rev 4 (aarch64)" },
  { brand: "SPA Condor Electronics", model: "Plume L4", cpu: "-" },
  { brand: "SPC", model: "SMART_MAX_2", cpu: "ARMv8 Processor" },
  { brand: "sprd", model: "Superb_PLus", cpu: "-" },
  { brand: "SUNMI", model: "V2sNC_EEA", cpu: "-" },
  { brand: "TCL", model: "6156D", cpu: "-" },
  { brand: "TCL", model: "T766H_EEA", cpu: "-" },
  { brand: "TCL", model: "4187D", cpu: "ARMv8 Processor" },
  { brand: "TCL", model: "T671H", cpu: "-" },
  { brand: "TCL", model: "T782H", cpu: "-" },
  { brand: "TCL", model: "T774H", cpu: "-" },
  { brand: "TCL", model: "6102H", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "5030D_EEA", cpu: "-" },
  { brand: "TCL", model: "5028D_EEA", cpu: "-" },
  { brand: "TCL", model: "5164D", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "T767H", cpu: "-" },
  { brand: "TCL", model: "6025H_EEA", cpu: "-" },
  { brand: "TCL", model: "6165H", cpu: "-" },
  { brand: "TCL", model: "T506K", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "6025D_EEA", cpu: "-" },
  { brand: "TCL", model: "9009G_EEA", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "TCL", model: "T771K", cpu: "-" },
  { brand: "TCL", model: "6102D", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "T770H", cpu: "-" },
  { brand: "TCL", model: "T610K", cpu: "-" },
  { brand: "TCL", model: "Alpha 20", cpu: "-" },
  { brand: "TCL", model: "5061K_EEA", cpu: "-" },
  { brand: "TCL", model: "5008D_EEA", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "5033F", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "T431D", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "5048Y_EEA", cpu: "-" },
  { brand: "TCL", model: "5024D_EEA", cpu: "-" },
  { brand: "TCL", model: "5002H_EEA", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "5033D_EEA", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "5031G", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "T781", cpu: "-" },
  { brand: "TCL", model: "8491X_EEA", cpu: "ARMv7 Processor rev 1 (v7l)" },
  { brand: "TCL", model: "5030F_EEA", cpu: "-" },
  { brand: "TCL", model: "T790Y", cpu: "-" },
  { brand: "TCL", model: "6127I", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "T676K", cpu: "-" },
  { brand: "TCL", model: "T506D", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "5029D_EEA", cpu: "-" },
  { brand: "TCL", model: "5028Y_EEA", cpu: "-" },
  { brand: "TCL", model: "HEYOU70", cpu: "-" },
  { brand: "TCL", model: "T775H", cpu: "-" },
  { brand: "TCL", model: "T671H", cpu: "ARMv8 Processor rev 2 (v8l)" },
  { brand: "TCL", model: "8088X_EEA", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "T780H", cpu: "-" },
  { brand: "TCL", model: "5056X", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "TCL", model: "5024F_EEA", cpu: "-" },
  { brand: "TCL", model: "5024D", cpu: "-" },
  { brand: "TCL", model: "5033D", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "T507D", cpu: "-" },
  { brand: "TCL", model: "5003D_EEA", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "TCL", model: "9080G", cpu: "-" },
  { brand: "TCL", model: "5026D", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "TCL", model: "T676H", cpu: "-" },
  { brand: "TCL", model: "T767W", cpu: "-" },
  { brand: "TCL", model: "T799H", cpu: "-" },
  { brand: "TCL", model: "5044P", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "5060D_EEA", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "6025A", cpu: "-" },
  { brand: "TCL", model: "T766H", cpu: "-" },
  { brand: "TCL", model: "6027A", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "5033F_EEA", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "5053Y_EEA", cpu: "-" },
  { brand: "TCL", model: "5059D", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "5007U_EEA", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "4087U_EEA", cpu: "-" },
  { brand: "TCL", model: "T810H", cpu: "-" },
  { brand: "TCL", model: "5002D_EEA", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "6056D", cpu: "-" },
  { brand: "TCL", model: "5052D", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "HEYOU10", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "5033E", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "5010D", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "TCL", model: "5001D_EEA", cpu: "ARMv8 Processor" },
  { brand: "TCL", model: "T779W", cpu: "-" },
  { brand: "TCL", model: "5044D", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "5059X", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "5085Y", cpu: "AArch64 Processor rev 4 (aarch64)" },
  { brand: "TCL", model: "Wind Tab 10", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TCL", model: "9160G", cpu: "-" },
  { brand: "TCL", model: "6165A", cpu: "-" },
  { brand: "TCL", model: "8082_EEA", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "Teclast", model: "P20HD_EEA", cpu: "-" },
  { brand: "TECLAST", model: "A10S", cpu: "AArch64 Processor rev 3 (aarch64)" },
  { brand: "Teclast", model: "P30Air_EEA", cpu: "-" },
  { brand: "TECNO", model: "TECNO CI6", cpu: "ARMv8 Processor rev 1 (v8l)" },
  { brand: "TECNO", model: "TECNO CI6", cpu: "-" },
  { brand: "TECNO", model: "TECNO PR651E", cpu: "ARMv8 Processor" },
  { brand: "TECNO", model: "TECNO KG5p", cpu: "-" },
  { brand: "TECNO", model: "TECNO KH6", cpu: "-" },
  { brand: "TECNO", model: "TECNO BF7", cpu: "-" },
  { brand: "TECNO", model: "TECNO KI7", cpu: "-" },
  { brand: "TECNO", model: "TECNO KH7", cpu: "-" },
  { brand: "TECNO", model: "TECNO BF6", cpu: "ARMv8 Processor rev 0 (v8l)" },
  { brand: "TECNO", model: "TECNO LG7n", cpu: "-" },
  { brand: "TECNO", model: "TECNO KI5k", cpu: "-" },
  { brand: "TECNO", model: "TECNO CH6i", cpu: "-" },
  { brand: "TECNO", model: "TECNO PR651H", cpu: "ARMv8 Processor" },
  { brand: "TECNO", model: "TECNO CK6", cpu: "-" },
  { brand: "TECNO", model: "TECNO BE8", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "TECNO", model: "TECNO K7", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "TECNO", model: "TECNO CK7n", cpu: "-" },
  { brand: "TECNO", model: "TECNO CI8", cpu: "-" },
  {
    brand: "TECNO",
    model: "Phantom6-Plus",
    cpu: "AArch64 Processor rev 1 (aarch64)",
  },
  { brand: "TECNO", model: "TECNO KI5q", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO KE6j", cpu: "-" },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO KG5j",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO KG5k", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CF7k", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO KF7j", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO KG7h", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CD7", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CG6", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CH6", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO LE6", cpu: "-" },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO KE5",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CD8j", cpu: "-" },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO KC8",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CE9", cpu: "-" },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO KG6",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO KD7h",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO LE6h", cpu: "-" },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO KG5h",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO KD7",
    cpu: "ARMv8 Processor rev 4 (v8l)",
  },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO KE7", cpu: "-" },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO BE6",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO BC2c",
    cpu: "ARMv7 Processor rev 5 (v7l)",
  },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CG7", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CH7", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CG6j", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CC6", cpu: "-" },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO BD4h",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO CF7",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO KD7", cpu: "-" },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO BD4j",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO LE7", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CE7j", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CE8", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CF8", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO LB8a", cpu: "-" },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO KA7O",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO KG6p", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CH7n", cpu: "-" },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO KD6",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CD6j", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO AB7", cpu: "-" },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO LA7",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO KF6i", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CG8", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO LD7", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CD6", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO KE5j", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CG8h", cpu: "-" },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO RA8",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO KA7",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO Mobile KG6k", cpu: "-" },
  { brand: "TECNO MOBILE LIMITED", model: "TECNO CC9", cpu: "-" },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO BD2p",
    cpu: "ARMv7 Processor rev 5 (v7l)",
  },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO BA2",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO KC6",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO KB7",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  {
    brand: "TECNO MOBILE LIMITED",
    model: "TECNO LA7 Pro",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "TENNO", model: "i13 Max", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "TIOK", model: "TIOK S60", cpu: "-" },
  {
    brand: "TP-LINK",
    model: "Neffos C5 Plus",
    cpu: "ARMv7 Processor rev 3 (v7l)",
  },
  {
    brand: "TP-LINK",
    model: "Neffos_C7A",
    cpu: "AArch64 Processor rev 4 (aarch64)",
  },
  { brand: "Ulefone", model: "Armor X7 Pro", cpu: "-" },
  { brand: "Ulefone", model: "Armor_3W", cpu: "-" },
  { brand: "Ulefone", model: "Armor X8", cpu: "-" },
  { brand: "Ulefone", model: "Armor X10 Pro", cpu: "-" },
  { brand: "Ulefone", model: "Note 16 Pro", cpu: "-" },
  { brand: "Ulefone", model: "Note 10", cpu: "ARMv8 Processor" },
  { brand: "Ulefone", model: "Armor X9 Pro", cpu: "-" },
  { brand: "Ulefone", model: "Power Armor14 Pro", cpu: "-" },
  { brand: "Ulefone", model: "Armor 9", cpu: "-" },
  { brand: "Ulefone", model: "Power Armor 14", cpu: "-" },
  { brand: "Ulefone", model: "Note 13P", cpu: "-" },
  { brand: "Ulefone", model: "Power Armor 13", cpu: "-" },
  { brand: "Ulefone", model: "Armor X5", cpu: "-" },
  { brand: "Ulefone", model: "Armor 7", cpu: "-" },
  { brand: "Ulefone", model: "Armor 8 Pro", cpu: "-" },
  { brand: "Ulefone", model: "Note 9P", cpu: "-" },
  { brand: "Ulefone", model: "Armor 7E", cpu: "-" },
  { brand: "Ulefone", model: "Note 14", cpu: "-" },
  { brand: "Ulefone", model: "Power Armor 19T", cpu: "-" },
  { brand: "Ulefone", model: "Armor 11T 5G", cpu: "-" },
  { brand: "Ulefone", model: "Note 12", cpu: "-" },
  { brand: "Ulefone", model: "Note 11P", cpu: "-" },
  { brand: "Ulefone", model: "Power Armor 16 Pro", cpu: "-" },
  { brand: "Ulefone", model: "Armor_6E", cpu: "-" },
  { brand: "Ulefone", model: "Armor 10 5G", cpu: "-" },
  {
    brand: "ulefone",
    model: "Power_3",
    cpu: "AArch64 Processor rev 4 (aarch64)",
  },
  { brand: "Ulefone", model: "Note 12P", cpu: "-" },
  { brand: "UMAX", model: "10L-PLUS", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "UMIDIGI", model: "RP02", cpu: "-" },
  { brand: "UMIDIGI", model: "BISON GT", cpu: "-" },
  { brand: "UMIDIGI", model: "S5 Pro", cpu: "-" },
  { brand: "UMIDIGI", model: "A7 Pro", cpu: "-" },
  { brand: "UMIDIGI", model: "MP04", cpu: "-" },
  { brand: "UMIDIGI", model: "A5_Pro", cpu: "-" },
  { brand: "UMIDIGI", model: "Power 3", cpu: "-" },
  { brand: "Unihertz", model: "Jelly2", cpu: "-" },
  { brand: "UNISOC", model: "LEM16", cpu: "-" },
  {
    brand: "unknown",
    model: "Custom Phone",
    cpu: "11th Gen Intel(R) Core(TM) i5-1135G7 @ 2.40GHz",
  },
  {
    brand: "unknown",
    model: "GCE x86 phone",
    cpu: "Intel(R) Xeon(R) CPU @ 2.00GHz",
  },
  {
    brand: "unknown",
    model: "GCE x86 phone",
    cpu: "Intel(R) Xeon(R) CPU @ 2.30GHz",
  },
  { brand: "unknown", model: "TrebleDroid vanilla", cpu: "-" },
  {
    brand: "unknown",
    model: "unknown",
    cpu: "Intel(R) Xeon(R) CPU E3-1245 v5 @ 3.50GHz",
  },
  {
    brand: "unknown",
    model: "GCE x86 phone",
    cpu: "Intel(R) Xeon(R) CPU @ 2.20GHz",
  },
  { brand: "Vanzo", model: "CUBOT R11", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "vivo", model: "V2110", cpu: "-" },
  { brand: "vivo", model: "V2023", cpu: "-" },
  { brand: "vivo", model: "V2109", cpu: "-" },
  { brand: "vivo", model: "V2053", cpu: "-" },
  { brand: "vivo", model: "V2050", cpu: "-" },
  { brand: "vivo", model: "V2041", cpu: "-" },
  { brand: "vivo", model: "V2130", cpu: "-" },
  { brand: "vivo", model: "V2046", cpu: "-" },
  { brand: "vivo", model: "V2205", cpu: "-" },
  { brand: "vivo", model: "V2027", cpu: "-" },
  { brand: "vivo", model: "V2050", cpu: "4000" },
  { brand: "vivo", model: "V2124", cpu: "-" },
  { brand: "vivo", model: "V2028", cpu: "-" },
  { brand: "vivo", model: "V2029", cpu: "-" },
  { brand: "vivo", model: "V2111", cpu: "-" },
  { brand: "vivo", model: "V2208", cpu: "-" },
  { brand: "vivo", model: "V2118", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "vivo", model: "vivo 1938", cpu: "-" },
  { brand: "vivo", model: "V2227A", cpu: "-" },
  { brand: "vivo", model: "V2204", cpu: "-" },
  { brand: "vivo", model: "V2154", cpu: "-" },
  { brand: "vivo", model: "V2206", cpu: "-" },
  { brand: "vivo", model: "V2035", cpu: "-" },
  { brand: "vivo", model: "V2058", cpu: "-" },
  { brand: "vivo", model: "vivo 2018", cpu: "-" },
  { brand: "vivo", model: "V2006", cpu: "-" },
  { brand: "vivo", model: "V2218A", cpu: "-" },
  { brand: "vivo", model: "I2223", cpu: "-" },
  { brand: "vivo", model: "V2145", cpu: "-" },
  { brand: "vivo", model: "V2207", cpu: "-" },
  { brand: "vivo", model: "vivo 1902_19", cpu: "-" },
  { brand: "vivo", model: "V2244", cpu: "-" },
  { brand: "vivo", model: "V2046A", cpu: "-" },
  { brand: "vivo", model: "vivo Y71A", cpu: "-" },
  { brand: "vivo", model: "V2139", cpu: "-" },
  { brand: "vivo", model: "V2059-EG", cpu: "-" },
  { brand: "vivo", model: "vivo 1920", cpu: "-" },
  { brand: "vivo", model: "V2183A", cpu: "-" },
  { brand: "vivo", model: "V1921A", cpu: "-" },
  { brand: "vivo", model: "V2170A", cpu: "-" },
  { brand: "vivo", model: "vivo 1915", cpu: "-" },
  { brand: "Vodafone", model: "VFD 630", cpu: "-" },
  { brand: "Vodafone", model: "VFD 710", cpu: "-" },
  {
    brand: "Vodafone",
    model: "Vodafone Smart ultra 6",
    cpu: "ARMv7 Processor rev 1 (v7l)",
  },
  { brand: "Vodafone", model: "VFD 500", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Vodafone", model: "VFD 730", cpu: "-" },
  { brand: "Vodafone", model: "VFD 620", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Vodafone", model: "VFD 600", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "Vodafone", model: "VFD 528", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "vsmart", model: "Active 1+", cpu: "-" },
  {
    brand: "WELCOME",
    model: "Phone12 Pro Max",
    cpu: "ARMv7 Processor rev 5 (v7l)",
  },
  { brand: "WELCOME", model: "S22 Ultra", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "Welcome", model: "S23 Ultra", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "wheatek", model: "A80S", cpu: "-" },
  { brand: "wheatek", model: "Cyber 9 Pro", cpu: "-" },
  { brand: "wheatek", model: "W10 Pro", cpu: "-" },
  { brand: "wheatek", model: "A95", cpu: "-" },
  { brand: "wheatek", model: "WP12 Pro", cpu: "-" },
  { brand: "wheatek", model: "BV5500Plus", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "wheatek", model: "WP19", cpu: "-" },
  { brand: "wheatek", model: "BV6300Pro", cpu: "-" },
  { brand: "wheatek", model: "W10", cpu: "-" },
  { brand: "wheatek", model: "WP20 Pro", cpu: "-" },
  { brand: "wheatek", model: "WP20", cpu: "-" },
  { brand: "wheatek", model: "C31 Pro", cpu: "-" },
  { brand: "wheatek", model: "BV8800", cpu: "-" },
  { brand: "wheatek", model: "Digi R2", cpu: "-" },
  { brand: "wheatek", model: "A90", cpu: "-" },
  { brand: "wheatek", model: "A80", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "wheatek", model: "WP15 S", cpu: "-" },
  { brand: "WIKO", model: "W-V720-EEA", cpu: "-" },
  { brand: "WIKO", model: "W-V745-EEA", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "WIKO", model: "W-V730-EEA", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "WIKO", model: "W-V850-EEA", cpu: "-" },
  { brand: "WIKO", model: "W-K610-FRA", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "WIKO", model: "W-V680-EEA", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "WIKO", model: "W-K130-EEA", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "WIKO", model: "LENNY3", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "WIKO", model: "W_K400", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "WIKO", model: "W-V830-EEA", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "WIKO", model: "W-K521-EEA", cpu: "ARMv8 Processor" },
  { brand: "WIKO", model: "W-K610-EEA", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "WIKO", model: "W_P200", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "WIKO", model: "View2 Go", cpu: "-" },
  { brand: "WIKO", model: "W-V750BN-EEA", cpu: "-" },
  { brand: "WIKO", model: "W-V755-EEA", cpu: "-" },
  { brand: "WIKO", model: "W-K630-EEA", cpu: "-" },
  { brand: "WIKO", model: "W_C800S", cpu: "-" },
  { brand: "WIKO", model: "W-K560-EEA", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "WIKO", model: "W_C800", cpu: "-" },
  { brand: "WIKO", model: "U PULSE", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "WIKO", model: "HARRY", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "WIKO", model: "W-P311-EEA", cpu: "-" },
  { brand: "WIKO", model: "W-K211-EEA", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "WIKO", model: "Tommy3", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "WIKO", model: "W-K610-OPE", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "WIKO", model: "JERRY2", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "Wings Mobile", model: "W4", cpu: "-" },
  { brand: "Wingtech", model: "T Phone", cpu: "-" },
  { brand: "Wingtech", model: "T Phone Pro", cpu: "-" },
  { brand: "Wingtech", model: "REVVL V+ 5G", cpu: "-" },
  { brand: "WINNOVO", model: "T10", cpu: "-" },
  { brand: "worldchip", model: "T8116", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "X109", model: "X109", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "XGODY", model: "Mate40", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "XGODY", model: "K30S", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "Xiaomi", model: "Redmi 7A", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Xiaomi", model: "M2006C3LG", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "Xiaomi", model: "M2101K9G", cpu: "-" },
  { brand: "Xiaomi", model: "M2006C3MG", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "Xiaomi", model: "Redmi Note 9 Pro", cpu: "-" },
  { brand: "Xiaomi", model: "M2101K6G", cpu: "-" },
  { brand: "Xiaomi", model: "Mi 9T Pro", cpu: "-" },
  { brand: "Xiaomi", model: "POCOPHONE F1", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi Note 8 Pro", cpu: "-" },
  { brand: "Xiaomi", model: "21091116UG", cpu: "-" },
  { brand: "Xiaomi", model: "2201116PG", cpu: "-" },
  { brand: "Xiaomi", model: "22111317G", cpu: "-" },
  { brand: "Xiaomi", model: "22101316UG", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi Note 8T", cpu: "-" },
  { brand: "Xiaomi", model: "M2004J19C", cpu: "-" },
  { brand: "Xiaomi", model: "M2012K11AG", cpu: "-" },
  { brand: "Xiaomi", model: "M2006C3MNG", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "Xiaomi", model: "M2002J9G", cpu: "-" },
  { brand: "Xiaomi", model: "2201116SG", cpu: "-" },
  { brand: "Xiaomi", model: "MI 9", cpu: "-" },
  { brand: "Xiaomi", model: "220733SG", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Xiaomi", model: "Redmi Note 7", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi Note 9S", cpu: "-" },
  { brand: "Xiaomi", model: "2109119DG", cpu: "-" },
  { brand: "Xiaomi", model: "M2007J3SY", cpu: "-" },
  { brand: "Xiaomi", model: "Mi 9T", cpu: "-" },
  { brand: "Xiaomi", model: "2201117TY", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi 7", cpu: "-" },
  { brand: "Xiaomi", model: "23028RN4DG", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "Xiaomi", model: "220333QNY", cpu: "-" },
  { brand: "Xiaomi", model: "2209116AG", cpu: "-" },
  { brand: "Xiaomi", model: "M2101K7BNY", cpu: "-" },
  { brand: "Xiaomi", model: "2201123G", cpu: "-" },
  { brand: "Xiaomi", model: "M2101K7AG", cpu: "-" },
  { brand: "Xiaomi", model: "M2102J20SG", cpu: "-" },
  { brand: "Xiaomi", model: "Mi Note 10 Lite", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi Note 8", cpu: "-" },
  { brand: "Xiaomi", model: "2107113SG", cpu: "-" },
  { brand: "Xiaomi", model: "22081212UG", cpu: "-" },
  { brand: "Xiaomi", model: "M2007J3SG", cpu: "-" },
  { brand: "Xiaomi", model: "21061119DG", cpu: "-" },
  { brand: "Xiaomi", model: "M2003J15SC", cpu: "-" },
  { brand: "Xiaomi", model: "22031116BG", cpu: "-" },
  { brand: "Xiaomi", model: "2201117SY", cpu: "-" },
  { brand: "Xiaomi", model: "M2103K19G", cpu: "-" },
  { brand: "Xiaomi", model: "Mi A2 Lite", cpu: "-" },
  { brand: "Xiaomi", model: "Mi A2", cpu: "-" },
  { brand: "Xiaomi", model: "M2012K11G", cpu: "-" },
  { brand: "Xiaomi", model: "Mi Note 10", cpu: "-" },
  { brand: "Xiaomi", model: "Mi MIX 3", cpu: "-" },
  { brand: "Xiaomi", model: "M2010J19SY", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi 6A", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Xiaomi", model: "2211133G", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi 8", cpu: "-" },
  { brand: "Xiaomi", model: "2201117SG", cpu: "-" },
  { brand: "Xiaomi", model: "22126RN91Y", cpu: "-" },
  { brand: "Xiaomi", model: "Mi Note 10 Pro", cpu: "-" },
  { brand: "Xiaomi", model: "22101316G", cpu: "-" },
  { brand: "Xiaomi", model: "M2007J22G", cpu: "-" },
  { brand: "Xiaomi", model: "M2006C3LVG", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "Xiaomi", model: "M2007J17G", cpu: "-" },
  { brand: "Xiaomi", model: "M2101K9AG", cpu: "-" },
  { brand: "Xiaomi", model: "22041219NY", cpu: "-" },
  { brand: "Xiaomi", model: "23021RAA2Y", cpu: "-" },
  { brand: "Xiaomi", model: "2201117TG", cpu: "-" },
  { brand: "Xiaomi", model: "MI MAX 3", cpu: "-" },
  { brand: "Xiaomi", model: "M2007J20CG", cpu: "-" },
  { brand: "Xiaomi", model: "21081111RG", cpu: "-" },
  { brand: "Xiaomi", model: "22041219PG", cpu: "-" },
  { brand: "Xiaomi", model: "22011119UY", cpu: "-" },
  { brand: "Xiaomi", model: "2201117PG", cpu: "-" },
  { brand: "Xiaomi", model: "MI 8 SE", cpu: "-" },
  { brand: "Xiaomi", model: "220233L2G", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "Xiaomi", model: "MI 6", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi 6", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Xiaomi", model: "2210129SG", cpu: "-" },
  { brand: "Xiaomi", model: "M2010J19CG", cpu: "-" },
  { brand: "Xiaomi", model: "MI 8", cpu: "-" },
  { brand: "Xiaomi", model: "22081283G", cpu: "-" },
  { brand: "Xiaomi", model: "MI 8 Lite", cpu: "-" },
  { brand: "Xiaomi", model: "21091116AG", cpu: "-" },
  { brand: "Xiaomi", model: "2203129G", cpu: "-" },
  { brand: "Xiaomi", model: "POCO X2", cpu: "-" },
  { brand: "Xiaomi", model: "M2101K7BG", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi Note 5", cpu: "-" },
  { brand: "Xiaomi", model: "POCO F2 Pro", cpu: "-" },
  { brand: "Xiaomi", model: "220733SFG", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Xiaomi", model: "Mi 9 Lite", cpu: "-" },
  { brand: "Xiaomi", model: "Mi A3", cpu: "-" },
  { brand: "Xiaomi", model: "22120RN86G", cpu: "-" },
  { brand: "Xiaomi", model: "2201116TG", cpu: "-" },
  { brand: "Xiaomi", model: "22021211RG", cpu: "-" },
  { brand: "Xiaomi", model: "22041216G", cpu: "-" },
  { brand: "Xiaomi", model: "22101320G", cpu: "-" },
  { brand: "Xiaomi", model: "22071212AG", cpu: "-" },
  { brand: "Xiaomi", model: "M2010J19SG", cpu: "-" },
  { brand: "Xiaomi", model: "M2011K2G", cpu: "-" },
  { brand: "Xiaomi", model: "Mi 10", cpu: "-" },
  { brand: "Xiaomi", model: "22071219CG", cpu: "-" },
  { brand: "Xiaomi", model: "2201122G", cpu: "-" },
  { brand: "Xiaomi", model: "2210132G", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi Note 4", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi Note 5A Prime", cpu: "-" },
  { brand: "Xiaomi", model: "M1908C3JGG", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi Note 6 Pro", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi 6 Pro", cpu: "-" },
  { brand: "Xiaomi", model: "220333QAG", cpu: "-" },
  { brand: "Xiaomi", model: "Mi MIX 2S", cpu: "-" },
  { brand: "Xiaomi", model: "Mi 9 SE", cpu: "-" },
  { brand: "Xiaomi", model: "Mi Note 3", cpu: "-" },
  { brand: "Xiaomi", model: "M2102K1G", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi 5 Plus", cpu: "-" },
  { brand: "Xiaomi", model: "21051182G", cpu: "-" },
  { brand: "Xiaomi", model: "23030RAC7Y", cpu: "-" },
  { brand: "Xiaomi", model: "Mi A1", cpu: "-" },
  { brand: "Xiaomi", model: "M2103K19PG", cpu: "-" },
  { brand: "Xiaomi", model: "2201117TL", cpu: "-" },
  { brand: "Xiaomi", model: "220333QPG", cpu: "-" },
  { brand: "Xiaomi", model: "2207122MC", cpu: "-" },
  { brand: "Xiaomi", model: "M2007J1SC", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi 5", cpu: "-" },
  { brand: "Xiaomi", model: "2207117BPG", cpu: "-" },
  { brand: "Xiaomi", model: "Mi MIX 3 5G", cpu: "-" },
  { brand: "Xiaomi", model: "23021RAAEG", cpu: "-" },
  { brand: "Xiaomi", model: "21061119AG", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi Go", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Xiaomi", model: "Redmi S2", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Xiaomi", model: "2112123AG", cpu: "-" },
  { brand: "Xiaomi", model: "Mi-4c", cpu: "-" },
  { brand: "Xiaomi", model: "21121119SG", cpu: "-" },
  { brand: "Xiaomi", model: "GM1917", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi K20", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi 8A", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Xiaomi", model: "23049PCD8G", cpu: "-" },
  {
    brand: "Xiaomi",
    model: "2107113SI",
    cpu: "Intel(R) Core(TM) i7-4770 CPU @ 3.40GHz",
  },
  { brand: "Xiaomi", model: "23013PC75G", cpu: "-" },
  { brand: "Xiaomi", model: "21121210G", cpu: "-" },
  { brand: "Xiaomi", model: "MIX 3", cpu: "-" },
  { brand: "Xiaomi", model: "M2006C3LI", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "Xiaomi", model: "Mi A2", cpu: "ARMv8 Processor rev 2 (v8l)" },
  { brand: "Xiaomi", model: "2201116TI", cpu: "-" },
  { brand: "Xiaomi", model: "22081212C", cpu: "-" },
  { brand: "Xiaomi", model: "M2101K7BL", cpu: "-" },
  { brand: "Xiaomi", model: "21091116C", cpu: "-" },
  { brand: "Xiaomi", model: "Mi 9X", cpu: "-" },
  { brand: "Xiaomi", model: "M2101K7AI", cpu: "-" },
  { brand: "Xiaomi", model: "21091116AI", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi Note 9", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi 5A", cpu: "-" },
  { brand: "Xiaomi", model: "M2101K6I", cpu: "-" },
  { brand: "Xiaomi", model: "21061110AG", cpu: "-" },
  { brand: "Xiaomi", model: "2201116SG", cpu: "5000" },
  { brand: "Xiaomi", model: "M2010J19SI", cpu: "-" },
  { brand: "Xiaomi", model: "POCO F1", cpu: "-" },
  { brand: "Xiaomi", model: "M2007J20CI", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi K20 Pro", cpu: "-" },
  { brand: "Xiaomi", model: "M2104K10AC", cpu: "-" },
  { brand: "Xiaomi", model: "M2102J20SI", cpu: "-" },
  { brand: "Xiaomi", model: "23028RA60L", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi K30 Pro", cpu: "-" },
  { brand: "Xiaomi", model: "M2102J20SG", cpu: "5160" },
  { brand: "Xiaomi", model: "Mi MIX 2", cpu: "-" },
  { brand: "Xiaomi", model: "M2012K11C", cpu: "-" },
  { brand: "Xiaomi", model: "MI MAX 2", cpu: "-" },
  { brand: "Xiaomi", model: "M2101K6P", cpu: "-" },
  { brand: "Xiaomi", model: "220733SL", cpu: "ARMv7 Processor rev 4 (v7l)" },
  { brand: "Xiaomi", model: "M2012K10C", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi 4A", cpu: "-" },
  { brand: "Xiaomi", model: "21061119AL", cpu: "-" },
  { brand: "Xiaomi", model: "Mi 10 Pro", cpu: "-" },
  { brand: "Xiaomi", model: "Mi9 Pro 5G", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi 3S", cpu: "-" },
  { brand: "Xiaomi", model: "MI 5", cpu: "-" },
  { brand: "Xiaomi", model: "M2010J19SL", cpu: "-" },
  { brand: "Xiaomi", model: "2304FPN6DG", cpu: "-" },
  { brand: "Xiaomi", model: "22111317PG", cpu: "-" },
  { brand: "Xiaomi", model: "M2012K11AC", cpu: "-" },
  { brand: "Xiaomi", model: "M2103K19C", cpu: "-" },
  { brand: "Xiaomi", model: "220333QL", cpu: "-" },
  { brand: "Xiaomi", model: "2201116SC", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi 4", cpu: "-" },
  {
    brand: "Xiaomi",
    model: "Redmi Pro",
    cpu: "AArch64 Processor rev 1 (aarch64)",
  },
  {
    brand: "Xiaomi",
    model: "Redmi Pro",
    cpu: "AArch64 Processor rev 4 (aarch64)",
  },
  { brand: "Xiaomi", model: "22101316UP", cpu: "-" },
  { brand: "Xiaomi", model: "MI 6X", cpu: "-" },
  { brand: "Xiaomi", model: "2201117SL", cpu: "-" },
  { brand: "Xiaomi", model: "21121119SC", cpu: "-" },
  {
    brand: "Xiaomi",
    model: "2107113SI",
    cpu: "Intel(R) Celeron(R) CPU N3350 @ 1.10GHz",
  },
  { brand: "Xiaomi", model: "M2101K6R", cpu: "-" },
  { brand: "Xiaomi", model: "M2003J15SC", cpu: "ARMv8 Processor rev 1 (v8l)" },
  { brand: "Xiaomi", model: "2303ERA42L", cpu: "-" },
  { brand: "Xiaomi", model: "MI PAD 4", cpu: "-" },
  { brand: "Xiaomi", model: "Mi Note 2", cpu: "-" },
  { brand: "Xiaomi", model: "M2102K1AC", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi Note 5A", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi 4X", cpu: "-" },
  { brand: "Xiaomi", model: "23013RK75C", cpu: "-" },
  { brand: "Xiaomi", model: "23053RN02Y", cpu: "-" },
  { brand: "Xiaomi", model: "2106118C", cpu: "-" },
  { brand: "Xiaomi", model: "2201122C", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi Note 2", cpu: "-" },
  { brand: "Xiaomi", model: "Redmi Note 7 Pro", cpu: "-" },
  { brand: "Xiaomi", model: "2206123SC", cpu: "-" },
  { brand: "Xiaomi", model: "2201117TI", cpu: "-" },
  { brand: "Xiaomi", model: "MI 9 Transparent Edition", cpu: "-" },
  { brand: "xiaomi", model: "POCO M3 Pro 5G", cpu: "-" },
  { brand: "Xiaomi", model: "2304FPN6DC", cpu: "-" },
  { brand: "YESTEL", model: "G2", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "Yestel", model: "X7", cpu: "ARMv7 Processor rev 3 (v7l)" },
  { brand: "YESTEL", model: "X2", cpu: "ARMv8 Processor rev 4 (v8l)" },
  { brand: "YOTOPT", model: "Y8-EEA", cpu: "ARMv8 Processor" },
  { brand: "YUMEFone", model: "S21+Ultra", cpu: "MTK6889 rev 5 (v7l)" },
  { brand: "ZTE", model: "ZTE Blade A51", cpu: "ARMv8 Processor" },
  { brand: "ZTE", model: "ZTE 8045", cpu: "-" },
  { brand: "ZTE", model: "ZTE Blade A5 2020", cpu: "-" },
  { brand: "ZTE", model: "ZTE Blade A52", cpu: "ARMv8 Processor" },
  { brand: "ZTE", model: "ZTE A7040", cpu: "-" },
  { brand: "ZTE", model: "ZTE BLADE A330", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "ZTE", model: "ZTE 8046", cpu: "-" },
  { brand: "ZTE", model: "ZTE 7540N", cpu: "-" },
  { brand: "ZTE", model: "ZTE 2050", cpu: "-" },
  { brand: "ZTE", model: "ZTE Blade V10 Vita", cpu: "-" },
  { brand: "ZTE", model: "ZTE Blade A31 Plus", cpu: "ARMv8 Processor" },
  { brand: "ZTE", model: "ZTE 8030", cpu: "-" },
  { brand: "ZTE", model: "ZTE A7030", cpu: "-" },
  { brand: "ZTE", model: "ZTE Blade A31", cpu: "ARMv8 Processor" },
  {
    brand: "ZTE",
    model: "ZTE Blade A52 Lite",
    cpu: "ARMv8 Processor rev 0 (v8l)",
  },
  {
    brand: "ZTE",
    model: "ZTE Blade A7 2020",
    cpu: "ARMv7 Processor rev 4 (v7l)",
  },
  { brand: "ZTE", model: "ZTE Blade A51 Lite RU", cpu: "ARMv8 Processor" },
  { brand: "ZTE", model: "ZTE 9030", cpu: "-" },
  { brand: "ZTE", model: "ZTE Blade V1000", cpu: "-" },
  {
    brand: "ZTE",
    model: "ZTE BLADE A610",
    cpu: "AArch64 Processor rev 3 (aarch64)",
  },
  { brand: "ZTE", model: "ZTE Blade L210", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "ZTE", model: "ZTE Blade A3 2020", cpu: "ARMv8 Processor" },
  { brand: "ZTE", model: "ZTE Blade A31 Lite", cpu: "ARMv8 Processor" },
  { brand: "ZTE", model: "ZTE Blade A32", cpu: "ARMv8 Processor" },
  { brand: "ZTE", model: "ZTE A2023P", cpu: "-" },
  { brand: "ZTE", model: "ZTE A2322G", cpu: "-" },
  { brand: "ZTE", model: "ZTE A2023PG", cpu: "-" },
  {
    brand: "ZTE",
    model: "ZTE BLADE A910",
    cpu: "AArch64 Processor rev 3 (aarch64)",
  },
  { brand: "ZTE", model: "ZTE BLADE L7", cpu: "ARMv7 Processor rev 5 (v7l)" },
  { brand: "ZTE", model: "ZTE Blade 10 Vita", cpu: "-" },
  { brand: "ZTE", model: "ZTE BLADE V0850", cpu: "-" },
  { brand: "ZTE", model: "ZTE Blade A5 2019", cpu: "-" },
  { brand: "ZTE", model: "ZTE Blade A7 2019", cpu: "-" },
  { brand: "ZTE", model: "ZTE 8010", cpu: "-" },
  { brand: "ZTE", model: "ZTE Blade A5 2019RU", cpu: "-" },
  {
    brand: "ZTE",
    model: "ZTE BLADE V0820",
    cpu: "ARMv7 Processor rev 2 (v7l)",
  },
  { brand: "ZTE", model: "ZTE BLADE A910", cpu: "Portrait" },
  { brand: "ZTE", model: "ZTE Blade A52", cpu: "-" },
  {
    brand: "ZTE",
    model: "ZTE BLADE A0622",
    cpu: "ARMv7 Processor rev 5 (v7l)",
  },
  { brand: "ZTE", model: "ZTE BLADE V9", cpu: "-" },
];

exports.Array = MODEL;
