import { createRouter, createWebHistory } from "vue-router";
import RawDataCard from "../views/RawDataCard.vue";
import OSMetricsCard from "../views/OSMetricsCard.vue";
import InsitesCard from "../views/InsitesCard.vue";
import FraudReasonsCard from "../views/FraudReasonsCard.vue";
import BaseMetricsCard from "../views/BaseMetricsCard.vue";
import DeviceConnectionTypeCard from "../views/DeviceConnectionTypeCard.vue";
import CountryMetricsCard from "../views/CountryMetricsCard.vue";
import BrowserMetricsCard from "../views/BrowserMetricsCard.vue";
import ConnectionTypeMetricsCard from "../views/ConnectionTypeMetricsCard.vue";
import HomeViewCard from "../views/HomeViewCard.vue";

const routes = [
  {
    path: "/",
    name: "HomeViewCard",
    component: HomeViewCard,
  },
  {
    path: "/RawDataCard",
    name: "RawDataCard",
    component: RawDataCard,
  },
  {
    path: "/OSMetricsCard",
    name: "OSMetricsCard",
    component: OSMetricsCard,
  },
  {
    path: "/InsitesCard",
    name: "InsitesCard",
    component: InsitesCard,
  },
  {
    path: "/FraudReasonsCard",
    name: "FraudReasonsCard",
    component: FraudReasonsCard,
  },
  {
    path: "/BaseMetricsCard",
    name: "BaseMetricsCard",
    component: BaseMetricsCard,
  },
  {
    path: "/DeviceConnectionTypeCard",
    name: "DeviceConnectionTypeCard",
    component: DeviceConnectionTypeCard,
  },
  {
    path: "/CountryMetricsCard",
    name: "CountryMetricsCard",
    component: CountryMetricsCard,
  },
  {
    path: "/BrowserMetricsCard",
    name: "BrowserMetricsCard",
    component: BrowserMetricsCard,
  },
  {
    path: "/ConnectionTypeMetricsCard",
    name: "ConnectionTypeMetricsCard",
    component: ConnectionTypeMetricsCard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
