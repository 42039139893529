const FRAUD_REASONS = [
  "",
  "-",
  "Abnormal browser settings detected",
  "Bot Detected - High confidence of being an automated request by a bot or program",
  "Emulator detected; user is spoofing their device settings",
  "High confidence of a residential proxy in use",
  "Human-like bot detected with risky behavior",
  "Irregular Configuration Settings",
  "Irregular connection detected",
  "Malicious browser settings detected",
  "Mobile emulator detected; user is spoofing their device settings",
  "Possible Browser Spoofing - Low Risk",
  "Proxy connection or anonymizer detected",
  "Request generated via iframe; possible clickjacking or misleading placement",
  "Software enabled to facilitate device spoofing and fraudulent behavior",
  "Suspicious browser configuration",
  "Suspicious device configuration; device recently reset",
  "Suspicious device settings",
  "Suspicious device settings indicating possible device spoofing",
  "Suspicious device settings; possible browser spoofing",
  "Suspicious device settings; user is likely using a fake device",
  "This device has engaged in recent abuse across the IPQS network",
  "This device is exhibiting higher risk behavior patterns",
  "User active in a known fraud ring",
  "User frequently changes IP addresses and devices",
  "User has AdBlock enabled",
  "User has an abnormal connection",
  "User has do not track enabled",
  "User has software enabled to randomize their digital footprint",
  "User has suspicious device settings",
  "User is exhibiting behavior of a bot",
  "User is frequently changing devices",
  "User is intentionally spoofing their device and digital signature",
  "User is likely spoofing their location",
  "User is likely to be located in another country",
  "User is not unique",
  "User is on a TOR connection",
  "User is spoofing their true operating system",
  "User made an abnormal request",
  "Virtual machine detected; likely a fraudulent user",
  "VPN connection or anonymizer detected",
  "Malicious browser settings detected",
  "Abnormal browser settings detected",
  "Fraud Reason",
  "Human-like bot detected with risky behavior",
  "Irregular Configuration Settings",
  "Irregular connection detected",
  "org",
  "Proxy connection or anonymizer detected",
  "Request generated via iframe; possible clickjacking or misleading placement",
  "Software enabled to facilitate device spoofing and fraudulent behavior",
  "Suspicious browser configuration",
  "Suspicious device configuration; device recently reset",
  "Suspicious device settings indicating possible device spoofing",
  "User frequently changes IP addresses and devices",
  "User has AdBlock enabled",
  "User has an abnormal connection",
  "User has do not track enabled",
  "User has software enabled to randomize their digital footprint",
  "User has suspicious device settings",
  "User is exhibiting behavior of a bot",
  "User is frequently changing devices",
  "User is intentionally spoofing their device and digital signature",
  "User is likely spoofing their location",
  "User is not unique",
  "User is spoofing their true operating system",
  "User made an abnormal request",
  "VPN connection or anonymizer detected",
  "Suspicious device configuration",
  "Mobile emulator detected",
  "Emulator detected",
  "Suspicious device settings; likely spoofing location",
  "Bot Detected - High confidence of being an automated request by a bot or program",
  "*",
  " Abnormal browser settings detected",
  " Bot Detected - High confidence of being an automated request by a bot or program",
  " Emulator detected; user is spoofing their device settings",
  " High confidence of a residential proxy in use",
  " Human-like bot detected with risky behavior",
  " Irregular Configuration Settings",
  " Irregular connection detected",
  " Malicious browser settings detected",
  " Mobile emulator detected; user is spoofing their device settings",
  " Possible Browser Spoofing - Low Risk",
  " Proxy connection or anonymizer detected",
  " Request generated via iframe; possible clickjacking or misleading placement",
  " Software enabled to facilitate device spoofing and fraudulent behavior",
  " Suspicious browser configuration",
  " Suspicious device configuration; device recently reset",
  " Suspicious device settings",
  " Suspicious device settings indicating possible device spoofing",
  " Suspicious device settings; possible browser spoofing",
  " Suspicious device settings; user is likely using a fake device",
  " This device has engaged in recent abuse across the IPQS network",
  " This device is exhibiting higher risk behavior patterns",
  " User active in a known fraud ring",
  " User frequently changes IP addresses and devices",
  " User has AdBlock enabled",
  " User has an abnormal connection",
  " User has do not track enabled",
  " User has software enabled to randomize their digital footprint",
  " User has suspicious device settings",
  " User is exhibiting behavior of a bot",
  " User is intentionally spoofing their device and digital signature",
  " User is likely spoofing their location",
  " User is likely to be located in another country",
  " User is not unique",
  " User is on a TOR connection",
  " User is spoofing their true operating system",
  " User made an abnormal request",
  " Virtual machine detected; likely a fraudulent user",
  " VPN connection or anonymizer detected",
  " User is frequently changing devices",
  " Suspicious device settings; likely spoofing location",
];

exports.Array = FRAUD_REASONS;
