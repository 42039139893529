import assemble_report from "./reports_functions/get_ip_reports.js";
import assemble_ip_reports_array from "./../data/report_labels.js";

/* eslint-disable */
export function run_ip_reports(data) {
  return new Promise(async (resolve) => {
    if (data.length > 0) {
      // const REPORT_OBJECT = await report_labels.assemble_ip_reports_array();
      const RESPONSE = await assemble_report(data);
      resolve(RESPONSE);
    } else {
      const RESPONSE = await await assemble_ip_reports_array();
      resolve(RESPONSE);
    }
  });
}
