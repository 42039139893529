// const SERVICE = require("./../services/ip_service");
/* eslint-disable */

const MOBILE_BROWSERS_IMPORT = require("../data/mobile_browsers.js");
const DESKTOP_BROWSERS_IMPORT = require("../data/desktop_browsers.js");
const OTHER_BROWSERS_IMPORT = require("../data/other_browsers.js");
const DESKTOP_OS_IMPORT = require("../data/desktop_os.js");
const MOBILE_OS_IMPORT = require("../data/mobile_os.js");

// #######################################
// Configs
// #######################################
const TOTAL_METRICS = 10;

// #######################################
// Filter readme
// #######################################
// filter = {
//   enabled: true,
//   filters: [
//     {
//       key: "fraud_score", // primary key to search, must match advanced reports header
//       bool_key: "bot" // key to search for bool values
//       // --------- Numeric filters
//       is_numeric: true, // Search for a numeric value such as fraud score
//       numeric_threshold: 90, // numeric threshold, will be greater AND equal, or less AND equal
//       numeric_range: "greater" || "less", // search for greater then or less then of numeric value
//       //  -------  Bool Filters
//       is_bool: true, // search for boolean value
//       bool: true,
//       //  -------  Search for other values filter
//       does_contain: true, // Search for other things that are not a numeric or bool value
//       other_keys: ["connection_type"], // header keys to search for
//       searchable_item: ["residential"], // values of header keys to search for
//       is_desktop_os: true, // if you are looking for a desktop os
//       is_mobile_os: true, // if you are looking for a mobile os
//       is_mobile_browser: true, // if you are looking for a mobile browser
//       is_desktop_browser: true, // if you are looking for a desktop browser
//       is_unknown_browser: true, // if you are looking for a other browser
//     },
//   ],
// };

async function get_insights(data) {
  return new Promise(async (resolve) => {
    const MOST_USED_IPS = await get_most_used(data, "ip_address", {
      enabled: false,
    });
    const MOST_USED_IPS_With_Fraud_80 = await get_most_used(
      data,
      "ip_address",
      {
        enabled: true,
        filters: [
          {
            key: "fraud_score",
            is_numeric: true,
            numeric_threshold: 80,
            numeric_range: "greater",
          },
        ],
      }
    );
    const MOST_USED_ISPS = await get_most_used(data, "isp", { enabled: false });
    const MOST_USED_OS = await get_most_used(data, "operating_system", {
      enabled: false,
    });
    const MOST_USED_COUNTRY = await get_most_used(data, "country", {
      enabled: false,
    });
    const MOST_USED_COUNTRY_USING_DESKTOP_BROWSER_ON_MOBILE_CONNECTION =
      await get_most_used(data, "country", {
        enabled: true,
        filters: [
          {
            key: "country",
            does_contain: true,
            is_desktop_browser: true,
            other_keys: ["connection_type"],
            searchable_item: ["Mobile"],
            is_edge_case: true,
          },
        ],
      });

    const MOST_USED_COUNTRY_USING_DESKTOP_BROWSER_ON_MOBILE_CONNECTION_WITH_FRAUD80 =
      await get_most_used(data, "country", {
        enabled: true,
        filters: [
          {
            key: "fraud_score",
            does_contain: true,
            is_desktop_browser: true,
            is_numeric: true,
            numeric_threshold: 90,
            numeric_range: "greater",
            other_keys: ["connection_type"],
            searchable_item: ["Mobile"],
            is_edge_case: true,
          },
        ],
      });

    const MOST_USED_DEVICE_WITH_FRAUD80 = await get_most_used(data, "model", {
      enabled: true,
      filters: [
        {
          key: "fraud_score",
          //   does_contain: true,
          //   is_desktop_browser: true,
          is_numeric: true,
          numeric_threshold: 80,
          numeric_range: "greater",
          //   other_keys: ["model"],
          // searchable_item: ["Mobile"],
          // is_edge_case: true,
        },
      ],
    });

    const MOST_USED_BRAND_WITH_FRAUD80 = await get_most_used(data, "brand", {
      enabled: true,
      filters: [
        {
          key: "fraud_score",
          //   does_contain: true,
          //   is_desktop_browser: true,
          is_numeric: true,
          numeric_threshold: 80,
          numeric_range: "greater",
          //   other_keys: ["model"],
          // searchable_item: ["Mobile"],
          // is_edge_case: true,
        },
      ],
    });

    const MOST_USED_OS_WITH_FRAUD80 = await get_most_used(
      data,
      "operating_system",
      {
        enabled: true,
        filters: [
          {
            key: "fraud_score",
            //   does_contain: true,
            //   is_desktop_browser: true,
            is_numeric: true,
            numeric_threshold: 80,
            numeric_range: "greater",
            // other_keys: ["model"],
            // searchable_item: ["Mobile"],
            // is_edge_case: true,
          },
        ],
      }
    );

    const MOST_USED_OS_WITH_FRAUD80_AND_BOT = await get_most_used(
      data,
      "operating_system",
      {
        enabled: true,
        filters: [
          {
            key: "fraud_score",
            bool_key: "bot_status",
            //   does_contain: true,
            //   is_desktop_browser: true,
            is_numeric: true,
            numeric_threshold: 80,
            numeric_range: "greater",
            is_bool: true, // search for boolean value
            bool: true,
            // other_keys: ["model"],
            // searchable_item: ["Mobile"],
            // is_edge_case: true,
          },
        ],
      }
    );

    const MOST_USED_BROWSER_WITH_FRAUD80_AND_BOT = await get_most_used(
      data,
      "browser",
      {
        enabled: true,
        filters: [
          {
            key: "fraud_score",
            bool_key: "bot_status",
            //   does_contain: true,
            //   is_desktop_browser: true,
            is_numeric: true,
            numeric_threshold: 80,
            numeric_range: "greater",
            is_bool: true, // search for boolean value
            bool: true,
            // other_keys: ["model"],
            // searchable_item: ["Mobile"],
            // is_edge_case: true,
          },
        ],
      }
    );

    const MOST_USED_ISP_WITH_FRAUD80_AND_BOT = await get_most_used(
      data,
      "isp",
      {
        enabled: true,
        filters: [
          {
            key: "fraud_score",
            bool_key: "bot_status",
            //   does_contain: true,
            //   is_desktop_browser: true,
            is_numeric: true,
            numeric_threshold: 80,
            numeric_range: "greater",
            is_bool: true, // search for boolean value
            bool: true,
            // other_keys: ["model"],
            // searchable_item: ["Mobile"],
            // is_edge_case: true,
          },
        ],
      }
    );

    const MOST_USED_ISP_WITH_FRAUD80 = await get_most_used(data, "isp", {
      enabled: true,
      filters: [
        {
          key: "fraud_score",
          //   bool_key: "bot_status",
          //   does_contain: true,
          //   is_desktop_browser: true,
          is_numeric: true,
          numeric_threshold: 80,
          numeric_range: "greater",
          //   is_bool: true, // search for boolean value
          //   bool: true,
          // other_keys: ["model"],
          // searchable_item: ["Mobile"],
          // is_edge_case: true,
        },
      ],
    });

    const MOST_USED_DATA_CENTER_ISP = await get_most_used(data, "isp", {
      enabled: true,
      filters: [
        {
          // key: "fraud_score",
          //   bool_key: "bot_status",
          does_contain: true,
          //   is_desktop_browser: true,
          // is_numeric: true,
          // numeric_threshold: 80,
          // numeric_range: "greater",
          //   is_bool: true, // search for boolean value
          //   bool: true,
          other_keys: ["connection_type"],
          searchable_item: ["Data Center"],
          // is_edge_case: true,
        },
      ],
    });

    const MOST_USED_MOBILE_ISP = await get_most_used(data, "isp", {
      enabled: true,
      filters: [
        {
          // key: "fraud_score",
          //   bool_key: "bot_status",
          does_contain: true,
          //   is_desktop_browser: true,
          // is_numeric: true,
          // numeric_threshold: 80,
          // numeric_range: "greater",
          //   is_bool: true, // search for boolean value
          //   bool: true,
          other_keys: ["connection_type"],
          searchable_item: ["Mobile"],
          // is_edge_case: true,
        },
      ],
    });

    const MOST_RESIDENTIAL_MOBILE_ISP = await get_most_used(data, "isp", {
      enabled: true,
      filters: [
        {
          // key: "fraud_score",
          //   bool_key: "bot_status",
          does_contain: true,
          //   is_desktop_browser: true,
          // is_numeric: true,
          // numeric_threshold: 80,
          // numeric_range: "greater",
          //   is_bool: true, // search for boolean value
          //   bool: true,
          other_keys: ["connection_type"],
          searchable_item: ["Residential"],
          // is_edge_case: true,
        },
      ],
    });

    // ############################
    // Assemble Response
    // ############################
    const RESPONSE = [
      { key: "Most Used IPs", insight: MOST_USED_IPS },
      {
        key: "Most Used IPs With Fraud Score 80 or Greater",
        insight: MOST_USED_IPS_With_Fraud_80,
      },
      { key: "Most Used ISP", insight: MOST_USED_ISPS },
      {
        key: "Most Used OS",
        insight: MOST_USED_OS,
      },
      {
        key: "Most Used Country",
        insight: MOST_USED_COUNTRY,
      },
      {
        key: "Most Countries Using Desktop Browser on Mobile Connection",
        insight: MOST_USED_COUNTRY_USING_DESKTOP_BROWSER_ON_MOBILE_CONNECTION,
      },
      {
        key: "Most Countries Using Desktop Browser on Mobile Connection W/ Fraud > 80",
        insight:
          MOST_USED_COUNTRY_USING_DESKTOP_BROWSER_ON_MOBILE_CONNECTION_WITH_FRAUD80,
      },
      {
        key: "Most Used Device W/ Fraud > 80",
        insight: MOST_USED_DEVICE_WITH_FRAUD80,
      },
      {
        key: "Most Used Brand W/ Fraud > 80",
        insight: MOST_USED_BRAND_WITH_FRAUD80,
      },
      {
        key: "Most Used OS W/ Fraud > 80",
        insight: MOST_USED_OS_WITH_FRAUD80,
      },
      {
        key: "Most Used OS W/ Fraud > 80 AND BOT",
        insight: MOST_USED_OS_WITH_FRAUD80_AND_BOT,
      },
      {
        key: "Most Used BROWSER W/ Fraud > 80 AND BOT",
        insight: MOST_USED_BROWSER_WITH_FRAUD80_AND_BOT,
      },
      {
        key: "Most Used ISP W/ Fraud > 80 AND BOT",
        insight: MOST_USED_ISP_WITH_FRAUD80_AND_BOT,
      },
      {
        key: "Most Used ISP W/ Fraud > 80",
        insight: MOST_USED_ISP_WITH_FRAUD80,
      },
      {
        key: "Most Used Data Center ISP",
        insight: MOST_USED_DATA_CENTER_ISP,
      },
      {
        key: "Most Used Mobile ISP",
        insight: MOST_USED_MOBILE_ISP,
      },
      {
        key: "Most Used Residential ISP",
        insight: MOST_RESIDENTIAL_MOBILE_ISP,
      },
    ];
    resolve(RESPONSE);
  });
}

function get_most_used(data, key, filter) {
  return new Promise(async (resolve) => {
    const RAW_DATA = data.raw_data;
    const TOTALS = await get_totals(RAW_DATA, key, filter);
    const SORTED_TOTAL = TOTALS.sort((a, b) => (a.total > b.total ? -1 : 1));
    const RESPONSE = await assemble_response(SORTED_TOTAL, key);
    resolve(RESPONSE);
  });
}

function get_totals(data, key, filter) {
  return new Promise(async (resolve) => {
    const temp_array = [{ [key]: "NA", total: 0 }];
    for (let line = 0; line < data.length; line++) {
      if (filter.enabled) {
        const DOES_PASS_FILTER = await does_pass_filter(
          data[line],
          filter.filters
        );
        if (DOES_PASS_FILTER) {
          if (temp_array.find((entry) => entry[key] === data[line][key])) {
            temp_array.find(
              (entry) => entry[key] === data[line][key]
            ).total += 1;
          } else {
            temp_array.push({ [key]: data[line][key], total: 1 });
          }
        }
      } else {
        if (temp_array.find((entry) => entry[key] === data[line][key])) {
          temp_array.find((entry) => entry[key] === data[line][key]).total += 1;
        } else {
          temp_array.push({ [key]: data[line][key], total: 1 });
        }
      }

      //complete loop
      if (line === data.length - 1) {
        resolve(temp_array);
      }
    }
  });
}

function does_pass_filter(line, filter) {
  return new Promise(async (resolve) => {
    let passed_filter = 0;

    for (let x = 0; x < filter.length; x++) {
      let pass_numeric = false;
      let pass_bool = false;
      let pass_desktop_browser = false;
      let pass_mobile_browser = false;
      let pass_other_browser = false;
      let pass_desktop_os = false;
      let pass_mobile_os = false;
      let pass_does_contain = false;

      if (!filter[x].is_numeric) pass_numeric = true;
      if (!filter[x].is_bool) pass_bool = true;
      if (!filter[x].is_desktop_os) pass_desktop_os = true;
      if (!filter[x].is_mobile_os) pass_mobile_os = true;
      if (!filter[x].is_mobile_browser) pass_mobile_browser = true;
      if (!filter[x].pass_desktop_browser) pass_desktop_browser = true;
      if (!filter[x].is_unknown_browser) pass_other_browser = true;
      if (!filter[x].does_contain) pass_does_contain = true;

      const FILTER_KEY = filter[x].key;
      const BOOL_FILTER_KEY = filter[x].bool_key;

      // numeric filters
      if (filter[x].is_numeric) {
        const NUMERIC_THRESHOLD = filter[x].numeric_threshold;
        const RANGE = filter[x].numeric_range;
        if (RANGE === "greater") {
          if (line[FILTER_KEY] >= NUMERIC_THRESHOLD) pass_numeric = true;
        }
        if (RANGE === "less") {
          if (line[FILTER_KEY] <= NUMERIC_THRESHOLD) pass_numeric = true;
        }
      }

      // bool filters
      if (filter[x].is_bool) {
        const BOOL = filter[x].bool;
        if (line[BOOL_FILTER_KEY] === BOOL) pass_bool = true;
      }

      // line contains

      if (filter[x].does_contain) {
        const SEARCHABLES = filter[x].searchable_item;
        const KEYS = filter[x].other_keys;

        for (let other_keys = 0; other_keys < KEYS.length; other_keys++) {
          for (
            let searchable_item = 0;
            searchable_item < SEARCHABLES.length;
            searchable_item++
          ) {
            if (line[KEYS[other_keys]] === SEARCHABLES[searchable_item]) {
              pass_does_contain = true;
              // Look for things like mobile OS or Desktop browsers

              if (filter[x].is_edge_case) {
                const MOBILE_BROWSERS = MOBILE_BROWSERS_IMPORT.Array;
                const DESKTOP_BROWSERS = DESKTOP_BROWSERS_IMPORT.Array;
                const OTHER_BROWSERS = OTHER_BROWSERS_IMPORT.Array;
                const DESKTOP_OS = DESKTOP_OS_IMPORT.Array;
                const MOBILE_OS = MOBILE_OS_IMPORT.Array;
                // is desktop os filter
                if (
                  filter[x].is_desktop_os &&
                  DESKTOP_OS.includes(line.operating_system)
                ) {
                  pass_desktop_os = true;
                }
                // is mobile os filter
                if (
                  filter[x].is_mobile_os &&
                  MOBILE_OS.includes(line.operating_system)
                ) {
                  pass_mobile_os = true;
                }
                // is mobile browser filter
                if (
                  filter[x].is_mobile_browser &&
                  MOBILE_BROWSERS.includes(line.browser)
                ) {
                  pass_mobile_browser = true;
                }
                // is desktop browser filter
                if (
                  filter[x].is_desktop_browser &&
                  DESKTOP_BROWSERS.includes(line.browser)
                ) {
                  pass_desktop_browser = true;
                }
                // is unknown browser filter
                if (
                  filter[x].is_unknown_browser &&
                  OTHER_BROWSERS.includes(line.browser)
                ) {
                  pass_other_browser = true;
                }
              }
            }
          }
        }
      }
      if (
        pass_numeric &&
        pass_bool &&
        pass_desktop_browser &&
        pass_mobile_browser &&
        pass_other_browser &&
        pass_desktop_os &&
        pass_mobile_os &&
        pass_does_contain
      ) {
        passed_filter++;
      }
      if (x === filter.length - 1) {
        if (passed_filter >= filter.length) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    }
  });
}

function assemble_response(SORTED_TOTAL, key) {
  return new Promise(async (resolve) => {
    const FILTERED = SORTED_TOTAL.slice(0, TOTAL_METRICS);
    const RESPONSE = [];
    for (let line = 0; line < FILTERED.length; line++) {
      RESPONSE.push(`${FILTERED[line][key]}: ${FILTERED[line].total}`);
      if (line === FILTERED.length - 1) {
        resolve(RESPONSE);
      }
    }
  });
}

module.exports = { get_insights };
