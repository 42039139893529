<template>
  <div class="card flex justify-content-center">
    <Dialog
      modal
      header="Select Reports"
      :style="{ width: '50vw' }"
      :visible="is_visible"
      closeIcon="false"
    >
      <div v-if="this.labels.length > 0">
        <div class="card" v-if="this.is_progress_bar_visible">
          <ProgressBar :value="progress"></ProgressBar>
        </div>
        <div class="mt-2">
          <h2>IP / Device Tracker Report</h2>
          <div
            v-for="label in this.labels"
            :key="label"
            class="flex align-items-center"
          >
            <RadioButton
              v-model="this.selected_reports_1"
              :inputId="label"
              name="category"
              :value="label"
              @change="this.create_keys_list"
            />
            <label :for="label" class="ml-2 mt-2">{{ label }}</label>
          </div>
        </div>
        <div class="mt-2">
          <h2>Email Report</h2>
          <div
            v-for="label in this.labels"
            :key="label"
            class="flex align-items-center"
          >
            <RadioButton
              v-model="this.selected_reports_2"
              :inputId="label"
              name="category"
              :value="label"
              @change="this.create_keys_list"
            />
            <label :for="label" class="ml-2 mt-2">{{ label }}</label>
          </div>
        </div>
        <div class="mt-2">
          <h2>Select Common Key</h2>
          <div
            v-for="label in this.common_keys"
            :key="label"
            class="flex align-items-center"
          >
            <RadioButton
              v-model="this.selected_key"
              :inputId="label"
              name="category"
              :value="label"
            />
            <label :for="label" class="ml-2 mt-2">{{ label }}</label>
          </div>
        </div>
      </div>
      <div v-else>
        <h2>No reports available.</h2>
      </div>

      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="this.cancel" text />
        <Button
          label="Get"
          icon="pi pi-check"
          @click="this.submit"
          autofocus
          v-if="this.labels.length > 0"
        />
        <Button
          label="Get"
          icon="pi pi-check"
          @click="this.submit"
          autofocus
          disabled
          v-else
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import RadioButton from "primevue/radiobutton";
import ProgressBar from "primevue/progressbar";
import { toRaw } from "vue";

import * as API from "./../../api";
const REPORTS = new API.reports();

export default {
  name: "CombineTablesCard",
  components: { Dialog, Button, RadioButton, ProgressBar },
  data() {
    return {
      visible: true,
      ip: "",
      debug: false,
      cache: false,
      labels: [],
      common_keys: [],
      selected_reports_1: [],
      selected_reports_2: [],
      selected_key: "",
      progress: 0,
      is_progress_bar_visible: false,
    };
  },
  mounted() {
    this.get_labels();
  },
  // created: {},
  computed: {
    is_visible() {
      return this.$store.getters.get_is_combine_reports_card_visible;
    },
  },
  watch: {
    is_visible: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.get_labels();
        }
      },
      deep: true,
    },
  },
  methods: {
    get_index(object_array, entry) {
      return new Promise((resolve) => {
        object_array.findIndex((element, key) => {
          if (element.ip_reports.label == entry) {
            resolve(key);
          }
        });
      });
    },
    async create_keys_list() {
      const ARRAYS = toRaw(this.$store.getters.get_reports);
      const INDEX_1 = await this.get_index(ARRAYS, this.selected_reports_1);
      const REPORT_1_RAW = toRaw(this.$store.getters.get_reports)[INDEX_1]
        .ip_reports.raw_data;
      const REPORT_1_KEYS = Object.keys(REPORT_1_RAW[0]);
      const INDEX_2 = await this.get_index(ARRAYS, this.selected_reports_2);
      const REPORT_2_RAW = toRaw(this.$store.getters.get_reports)[INDEX_2]
        .ip_reports.raw_data;
      const REPORT_2_KEYS = Object.keys(REPORT_2_RAW[0]);
      this.common_keys = await this.get_common_keys(
        REPORT_1_KEYS,
        REPORT_2_KEYS
      );
    },
    get_common_keys(report_1, report_2) {
      return new Promise((resolve) => {
        const TEMP_BUFFER = [];
        for (let x = 0; x < report_1.length; x++) {
          for (let y = 0; y < report_2.length; y++) {
            if (report_1[x] === report_2[y]) {
              TEMP_BUFFER.push(report_1[x]);
            }
          }
          if (x === report_1.length - 1) {
            resolve(TEMP_BUFFER);
          }
        }
      });
    },
    get_labels() {
      this.labels = [];
      const reports = toRaw(this.$store.getters.get_reports);
      for (let label = 0; label < reports.length; label++) {
        this.labels.push(reports[label].ip_reports.label);
      }
    },
    async submit() {
      const ARRAYS = toRaw(this.$store.getters.get_reports);
      const INDEX_1 = await this.get_index(ARRAYS, this.selected_reports_1);
      const REPORT_1_RAW = toRaw(this.$store.getters.get_reports)[INDEX_1]
        .ip_reports.raw_data;
      const INDEX_2 = await this.get_index(ARRAYS, this.selected_reports_2);
      const REPORT_2_RAW = toRaw(this.$store.getters.get_reports)[INDEX_2]
        .ip_reports.raw_data;
      const COMBINED_REPORT = await this.combine_raw_data(
        REPORT_1_RAW,
        REPORT_2_RAW
      );
      const REPORT = await REPORTS.create_reports(COMBINED_REPORT);
      console.log("COMBINED REPORT: ", REPORT);
      REPORT.ip_reports.raw_data = COMBINED_REPORT;
      REPORT.ip_reports.label = `Combined Reports_${this.selected_reports_1}_${this.selected_reports_2}`;
      this.$store.commit("set_reports", REPORT);
      this.$store.commit("set_is_combine_reports_card_visible");
    },
    /* eslint-disable */
    combine_raw_data(report_1, report_2) {
      return new Promise(async (resolve) => {
        this.is_progress_bar_visible = true;
        const NO_MATCH = [];
        const NEW_REPORT = [];
        for (let x = 0; x < report_1.length; x++) {
          this.progress = parseInt(x / report_1.length) * 100;
          let flag = false;
          if (!NO_MATCH.includes(report_1[x][this.selected_key])) {
            for (let y = 0; y < report_2.length; y++) {
              if (
                report_1[x][this.selected_key].trim() ==
                report_2[y][this.selected_key].trim()
              ) {
                flag = true;

                const REPORT_1_KEYS = Object.keys(report_1[x]);
                const REPORT_1_LINE = report_1[x];

                const REPORT_2_KEYS = Object.keys(report_2[y]);
                const REPORT_2_LINE = report_2[x];

                const LINE_1_ENTRY = await this.create_new_entry(
                  REPORT_1_LINE,
                  REPORT_1_KEYS,
                  {},
                  "ip"
                );
                const LINE_2_ENTRY = await this.create_new_entry(
                  REPORT_2_LINE,
                  REPORT_2_KEYS,
                  LINE_1_ENTRY,
                  "email"
                );

                NEW_REPORT.push(LINE_2_ENTRY);
              }
            }
            if (flag) {
              NO_MATCH.push(report_1[x][this.selected_key]);
            }
          }
          if (x === report_1.length - 2) {
            this.is_progress_bar_visible = false;
            resolve(NEW_REPORT);
          }
        }
      });
    },
    create_new_entry(report_line, report_keys, temp_entry, entry) {
      return new Promise((resolve) => {
        for (let x = 0; x < report_keys.length; x++) {
          let key = report_keys[x];
          if (
            (key === "fraud_score" || key === "fraud_chance") &&
            entry === "ip"
          ) {
            let temp_key = "ip_fraud_score";
            temp_entry[temp_key] = report_line[key];
          } else if (
            (key === "fraud_score" || key === "fraud_chance") &&
            entry === "email"
          ) {
            let temp_key = "email_fraud_score";
            temp_entry[temp_key] = report_line[key];
          } else {
            temp_entry[key] = report_line[key];
          }
          if (x === report_keys.length - 2) {
            resolve(temp_entry);
          }
        }
      });
    },
    cancel() {
      this.$store.commit("set_is_combine_reports_card_visible");
    },
  },
};
</script>

<style></style>
