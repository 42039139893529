const MOBILE_OS = [
  "Android ",
  "Android 1.0",
  "Android 10.0",
  "Android 10.1",
  "Android 10.2",
  "Android 10.3",
  "Android 10.4",
  "Android 10",
  "Android 11.0",
  "Android 11.1",
  "Android 11.2",
  "Android 11.21",
  "Android 11.3",
  "Android 11.4",
  "Android 11",
  "Android 12.0",
  "Android 12.1",
  "Android 12",
  "Android 13",
  "Android 14",
  "Android 2.0",
  "Android 2.2",
  "Android 2.3",
  "Android 2022134874",
  "Android 29",
  "Android 4.0",
  "Android 4.1",
  "Android 4.2",
  "Android 4.3",
  "Android 4.4",
  "Android 4.5",
  "Android 5.0",
  "Android 5.1",
  "Android 5.2",
  "Android 6.0",
  "Android 6.1",
  "Android 7.0",
  "Android 7.1",
  "Android 7.4",
  "Android 7",
  "Android 8.0",
  "Android 8.1",
  "Android 9.0",
  "Android 9.1",
  "Android 9.2",
  "Android 9.4",
  "Android 9",
  "Android",
  "Firefox OS ",
  "Firefox OS",
  "iOS ",
  "iOS 1.0",
  "iOS 10.0",
  "iOS 10.1",
  "iOS 10.15",
  "iOS 10.2",
  "iOS 10.3",
  "iOS 10.9",
  "iOS 11.0",
  "iOS 11.1",
  "iOS 11.2",
  "iOS 11.3",
  "iOS 11.4",
  "iOS 12.0",
  "iOS 12.1",
  "iOS 12.2",
  "iOS 12.3",
  "iOS 12.4",
  "iOS 12.5",
  "iOS 13.0",
  "iOS 13.1",
  "iOS 13.2",
  "iOS 13.3",
  "iOS 13.4",
  "iOS 13.5",
  "iOS 13.6",
  "iOS 13.7",
  "iOS 14.0",
  "iOS 14.1",
  "iOS 14.2",
  "iOS 14.3",
  "iOS 14.4",
  "iOS 14.5",
  "iOS 14.6",
  "iOS 14.7",
  "iOS 14.8",
  "iOS 1421",
  "iOS 147",
  "iOS 15.0",
  "iOS 15.1",
  "iOS 15.2",
  "iOS 15.3",
  "iOS 15.4",
  "iOS 15.5",
  "iOS 15.6",
  "iOS 15.7",
  "iOS 151",
  "iOS 1511",
  "iOS 152",
  "iOS 156",
  "iOS 16.0",
  "iOS 16.1",
  "iOS 16.2",
  "iOS 16.3",
  "iOS 16.4",
  "iOS 16.5",
  "iOS 16.6",
  "iOS 160",
  "iOS 17.0",
  "iOS 3.2",
  "iOS 4.0",
  "iOS 5.0",
  "iOS 5.1",
  "iOS 6.0",
  "iOS 6.1",
  "iOS 7.0",
  "iOS 8.0",
  "iOS 8.1",
  "iOS 8.2",
  "iOS 8.4",
  "iOS 9.0",
  "iOS 9.1",
  "iOS 9.2",
  "iOS 9.3",
  "iOS 9.4",
  "iOS",
  "MeeGo ",
  "palmOS ",
  "PlayStation Portable Vita",
  "Sailfish OS ",
  "Symbian ",
  "Symbian OS 9.2",
  "Symbian OS Series 60 ",
  "Tizen 2.0",
  "Tizen 2.4",
  "Tizen 3.0",
  "Tizen 4.0",
  "Tizen 5.0",
  "Tizen 5.5",
  "Tizen 6.0",
  "Tizen 6.5",
  "Tizen 7.0",
  "Windows CE ",
  "Windows Phone 10.0",
  "Windows Phone 8.0",
];

exports.Array = MOBILE_OS;
