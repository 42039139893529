const CRYPTO_JS = require("crypto-js");
/* eslint-disable */

function save_data(fingerprint, data, key) {
  if (fingerprint.device_id && data && key) {
    const CRYPTO_KEY = fingerprint.device_id;
    const ENCRYPTED_DATA = CRYPTO_JS.AES.encrypt(data, CRYPTO_KEY).toString();
    localStorage.setItem(key, ENCRYPTED_DATA);
  }
}

function get_data(fingerprint, key) {
  return new Promise((resolve) => {
    const DATA = localStorage.getItem(key);
    if (DATA) {
      const CRYPTO_KEY = fingerprint.device_id;
      const DECRYPTED_DATA = CRYPTO_JS.AES.decrypt(DATA, CRYPTO_KEY);
      const RESTORED_DATA = DECRYPTED_DATA.toString(CRYPTO_JS.enc.Utf8);
      resolve(RESTORED_DATA);
    } else {
      resolve(null);
    }
  });
}

module.exports = {
  save_data,
  get_data,
};
