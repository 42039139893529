<template>
  <div>
    <Card class="mt-2 shadow-5">
      <template #content>
        <div
          class="flex justify-content-center flex-wrap card-container mt-4 mb-3"
        >
          <div class="flex justify-content-left mr-4">
            <Tag
              severity="warning"
              class="pr-4 pl-4"
              :value="this.report_label"
            ></Tag>
          </div>
          <div
            v-for="(record, key) in this.totalRecords"
            :key="key"
            class="align-items-center justify-content-center ml-2 mr-2"
          >
            <Button
              label="Primary"
              text
              raised
              @click="update_page(key)"
              :class="this.is_selected(key)"
            >
              {{ record }}
            </Button>
          </div>
        </div>
        <Accordion :activeIndex="0">
          <AccordionTab
            v-for="(table, id) in this.table_data"
            :key="id"
            :ref="this.keys[id]"
            :header="this.keys[id]"
          >
            <div class="mt-4">
              <div class="card">
                <DataTable
                  :value="table"
                  stripedRows
                  paginator
                  :rows="10"
                  :rowsPerPageOptions="[10, 20, 50, 100]"
                  :class="`p-datatable-sm`"
                  resizableColumns
                  columnResizeMode="expand"
                  tableStyle="min-width: 50rem"
                  :exportFilename="this.keys[id]"
                  :ref="this.keys[id]"
                >
                  <div class="grid w-auto">
                    <div style="text-align: right">
                      <Button
                        icon="pi pi-external-link"
                        size="large"
                        link
                        @click="export_csv($event, this.keys[id])"
                      />
                    </div>
                    <h3 class="ml-3 mr-3">
                      Basic Reports : {{ this.keys[id] }}
                    </h3>
                  </div>
                  <Column field="key" header="Key" sortable></Column>
                  <Column field="total" header="Total" sortable></Column>
                  <Column
                    field="f90"
                    header="Total W/ Fraud 90 or Greater"
                    sortable
                  ></Column>
                  <Column
                    field="f80"
                    header="Total W/ Fraud 80-89"
                    sortable
                  ></Column>
                  <Column
                    field="diff_total"
                    header="Diff Totals"
                    sortable
                  ></Column>
                  <Column
                    field="diff_f90"
                    header="Diff Fraud 90 or Greater"
                    sortable
                  ></Column>
                  <Column
                    field="diff_f80"
                    header="Diff W/ Fraud 80-89"
                    sortable
                  ></Column>
                </DataTable>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </template>
    </Card>
  </div>
</template>

<script>
import { toRaw } from "vue";
// @ is an alias to /src
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Card from "primevue/card";
import Button from "primevue/button";
import Tag from "primevue/tag";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

export default {
  name: "DeviceConnectionTypeCard",
  components: { DataTable, Column, Card, Button, Tag, Accordion, AccordionTab },
  data() {
    return {
      entry: 0,
      report_label: "",
      table_data: [],
      diff_table_data: [],
      keys: [],
      csv_export_ref: "",
    };
  },
  mounted() {
    if (this.$route.query.entry) {
      this.entry = this.$route.query.entry;
    }
    this.set_data();
  },
  // created: {},
  computed: {
    selected_data() {
      return toRaw(this.$store.getters.get_reports[this.entry]);
    },
    totalRecords() {
      return toRaw(this.$store.getters.get_reports.length);
    },
  },
  watch: {
    selected_data: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.set_data();
        }
      },
      deep: true,
    },
  },
  methods: {
    export_csv(event, ref) {
      this.$refs[ref][0].exportCSV();
    },
    update_page(page) {
      this.update_page_count();
      this.set_data();
      this.entry = page;
    },
    update_page_count() {
      if (this.$store.getters.get_reports) {
        this.totalRecords = toRaw(this.$store.getters.get_reports).length;
      }
    },
    is_selected(key) {
      if (key === this.entry) {
        return " bg-primary";
      } else {
        return "";
      }
    },
    async set_data() {
      this.table_data = [];
      this.key = [];
      if (this.$store.getters.get_reports.length > 0) {
        this.update_page_count();
        this.report_label = this.selected_data.ip_reports.label;

        const REPORTS = this.selected_data.ip_reports.DEVICE_CONNECTION_TYPE;

        Object.keys(REPORTS).forEach((key) => {
          this.keys.push(key);
          let entry = this.create_table(REPORTS[key]);
          this.table_data.push(entry);
        });

        // Start diff calc
        this.diff_enabled = this.$store.getters.get_diff_table_info.enabled;
        if (this.diff_enabled) {
          const diff_table = toRaw(
            this.$store.getters.get_diff_table_info.tables
          );
          // Create diff table
          const ARRAYS = toRaw(this.$store.getters.get_reports);
          const INDEX = await this.get_index(ARRAYS, diff_table);
          this.diff_report_label = toRaw(this.$store.getters.get_reports)[
            INDEX
          ].ip_reports.label;

          const DIFF_REPORTS = toRaw(this.$store.getters.get_reports)[INDEX]
            .ip_reports.DEVICE_CONNECTION_TYPE;

          Object.keys(DIFF_REPORTS).forEach((key) => {
            let entry = this.create_table(DIFF_REPORTS[key]);
            this.diff_table_data.push(entry);
          });

          this.calculate_diffs();
        }
      }
    },
    get_index(object_array, entry) {
      return new Promise((resolve) => {
        object_array.findIndex((element, key) => {
          if (element.ip_reports.label == entry) {
            resolve(key);
          }
        });
      });
    },
    create_table(table) {
      const KEYS = Object.keys(table);
      const temp_array = [];
      KEYS.forEach((key) => {
        if (table[key] > 0) {
          temp_array.push(table[key]);
        }
      });
      return temp_array;
    },
    calculate_diffs() {
      const KEYS = Object.keys(this.table_data[0]);
      for (let table = 0; table < this.table_data.length; table++) {
        let grand_total = 0;
        let diff_grand_total = 0;
        KEYS.forEach((key) => {
          const ORIGINAL_TOTAL = this.table_data[table][key].total;
          const DIFF_TOTAL = this.diff_table_data[table][key].total;
          let DIFF_DIFFERENCE = ORIGINAL_TOTAL - DIFF_TOTAL;
          if (this.table_data[table][key].key === "Total") {
            grand_total = this.table_data[table][key].total;
            diff_grand_total = this.diff_table_data[table][key].total;
          } else {
            const ORIGINAL_PERCENT_TOTAL = parseInt(
              Math.abs(ORIGINAL_TOTAL / grand_total) * 100
            );
            const DIFF_PERCENT_TOTAL = parseInt(
              Math.abs(DIFF_TOTAL / diff_grand_total) * 100
            );

            DIFF_DIFFERENCE = `${ORIGINAL_PERCENT_TOTAL}% Vs. ${DIFF_PERCENT_TOTAL}%`;
          }

          const ORIGINAL_F90 = this.table_data[table][key].f90;
          const ORIGINAL_F90_PERCENT =
            parseInt(Math.abs(ORIGINAL_F90 / ORIGINAL_TOTAL) * 100) || 0;

          const DIFF_F90 = this.diff_table_data[table][key].f90;
          const DIFF_F90_PERCENT =
            parseInt(Math.abs((DIFF_F90 / DIFF_TOTAL) * 100)) || 0;

          const ORIGINAL_F80 = this.table_data[table][key].f80;
          const ORIGINAL_F80_PERCENT =
            parseInt(Math.abs(ORIGINAL_F80 / ORIGINAL_TOTAL) * 100) || 0;

          const DIFF_F80 = this.diff_table_data[table][key].f80;
          const DIFF_F80_PERCENT =
            parseInt(Math.abs((DIFF_F80 / DIFF_TOTAL) * 100)) || 0;

          this.table_data[table][key].diff_total = `${DIFF_DIFFERENCE}`;
          this.table_data[table][
            key
          ].diff_f90 = `${ORIGINAL_F90_PERCENT}% Vs. ${DIFF_F90_PERCENT}%`;
          this.table_data[table][
            key
          ].diff_f80 = `${ORIGINAL_F80_PERCENT}% Vs. ${DIFF_F80_PERCENT}%`;
        });
      }
    },
  },
};
</script>

<style>
.dataAlert {
  background-color: red;
}
.tableHover:hover {
  background-color: #e6e6e6;
}
.popOutMenu {
  position: absolute;
  /* left: v-bind(mouseX);
  top: v-bind(mouseY); */
  left: v-bind(mouseX);
  top: v-bind(mouseY);
  z-index: 1500;
}
</style>
