import { createStore } from "vuex";

// import { toRaw } from "vue";
// import calculateMetrics from "../functions/calculateMetrics";

export default createStore({
  state: {
    diff_table_info: { enabled: false, tables: "" },
    is_csv_chooser_card_visible: false,
    is_csv_parse_card_visible: false,
    is_combine_reports_card_visible: false,
    is_api_key_card_visible: false,
    is_list_modal_visible: false,
    is_email_validation_card_visible: false,
    is_ipqs_validation_card_visible: false,
    is_validate_csv_modal_visible: false,
    api_key: "",
    endpoint: process.env.VUE_APP_API_ENDPOINT,
    progress_bar: 0,
    is_progress_bar_visible: false,
    is_diff_table_visible: false,
    raw_data: [],
    reports: [],
    fingerprint: {},
  },
  getters: {
    get_endpoint(state) {
      return state.endpoint;
    },
    // get_ip_validation_data(state) {
    //   return state.ip_validation_data;
    // },
    get_is_csv_chooser_card_visible(state) {
      return state.is_csv_chooser_card_visible;
    },
    get_raw_data(state) {
      return state.raw_data;
    },
    get_reports(state) {
      return state.reports;
    },
    get_progress_bar(state) {
      return state.progress_bar;
    },
    get_is_progress_bar_visible(state) {
      return state.is_progress_bar_visible;
    },
    get_fingerprint(state) {
      return state.fingerprint;
    },
    get_is_diff_table_visible(state) {
      return state.is_diff_table_visible;
    },
    get_diff_table_info(state) {
      return state.diff_table_info;
    },
    get_is_csv_parse_card_visible(state) {
      return state.is_csv_parse_card_visible;
    },
    get_is_combine_reports_card_visible(state) {
      return state.is_combine_reports_card_visible;
    },
    get_api_key(state) {
      return state.api_key;
    },
    get_is_api_key_card_visible(state) {
      return state.is_api_key_card_visible;
    },
    get_is_list_modal_visible(state) {
      return state.is_list_modal_visible;
    },
    get_is_email_validation_card_visible(state) {
      return state.is_email_validation_card_visible;
    },
    get_is_ipqs_validation_card_visible(state) {
      return state.is_ipqs_validation_card_visible;
    },
    get_is_validate_csv_modal_visible(state) {
      return state.is_validate_csv_modal_visible;
    },
  },
  mutations: {
    set_is_validate_csv_modal_visible(state) {
      state.is_validate_csv_modal_visible =
        !state.is_validate_csv_modal_visible;
    },
    set_is_ipqs_validation_card_visible(state) {
      state.is_ipqs_validation_card_visible =
        !state.is_ipqs_validation_card_visible;
    },
    set_is_email_validation_card_visible(state) {
      state.is_email_validation_card_visible =
        !state.is_email_validation_card_visible;
    },
    set_is_list_modal_visible(state) {
      state.is_list_modal_visible = !state.is_list_modal_visible;
    },
    set_is_api_key_card_visible(state) {
      state.is_api_key_card_visible = !state.is_api_key_card_visible;
    },
    set_api_key(state, data) {
      state.api_key = data;
    },
    set_is_combine_reports_card_visible(state) {
      state.is_combine_reports_card_visible =
        !state.is_combine_reports_card_visible;
    },
    set_is_csv_parse_card_visible(state) {
      state.is_csv_parse_card_visible = !state.is_csv_parse_card_visible;
    },
    set_diff_table_info(state, data) {
      state.diff_table_info = data;
    },
    set_is_diff_table_visible(state) {
      state.is_diff_table_visible = !state.is_diff_table_visible;
    },
    // set_ip_validation_data(state, ip_data) {
    //   state.ip_validation_data.push(ip_data);
    // },
    set_is_csv_chooser_card_visible(state) {
      state.is_csv_chooser_card_visible = !state.is_csv_chooser_card_visible;
    },
    set_raw_data(state, data) {
      state.raw_data.push(data);
    },
    set_reports(state, data) {
      state.reports.push(data);
    },
    set_progress_bar(state, progress) {
      state.progress_bar = progress;
    },
    reset_progress_bar(state) {
      state.progress_bar = 0;
    },
    enable_is_progress_bar_visible(state) {
      state.is_progress_bar_visible = true;
    },
    disable_is_progress_bar_visible(state) {
      state.is_progress_bar_visible = false;
    },
    set_fingerprint(state, fingerprint) {
      state.fingerprint = fingerprint;
    },
  },
  actions: {},
  modules: {},
});
