<template>
  <div class="m-2 p-2 shadow-5">
    <div class="flex justify-content-center flex-wrap card-container mt-4 mb-3">
      <div class="flex justify-content-left mr-4 mt-2">
        <Tag
          severity="warning"
          class="pr-4 pl-4"
          :value="this.report_label"
        ></Tag>
      </div>
      <div
        v-for="(record, key) in this.totalRecords"
        :key="key"
        class="align-items-center justify-content-center ml-2 mr-2 mt-2"
      >
        <Button
          label="Primary"
          text
          raised
          @click="update_page(key)"
          :class="this.is_selected(key)"
        >
          {{ record }}
        </Button>
      </div>
    </div>
    <Accordion :activeIndex="0" :multiple="true" class="m-2">
      <AccordionTab header="Report Insights">
        <Card class="mt-2 shadow-5">
          <!-- ################################
         Insights
          ################################ -->
          <template #header>
            <div class="grid w-auto">
              <div style="text-align: right">
                <Button
                  size="small"
                  icon="pi pi-refresh"
                  label="Calculate"
                  link
                  @click="this.get_insights()"
                />
              </div>
            </div>
          </template>
          <template #content>
            <div class="card">
              <DataTable
                :value="this.insight_table"
                stripedRows
                tableStyle="min-width: 50rem"
                ref="insight_table"
                exportFilename="Report Insights"
                paginator
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50, 100]"
                :class="`p-datatable-sm`"
                resizableColumns
                columnResizeMode="expand"
              >
                <div class="grid w-auto">
                  <h3 class="ml-3 mr-3">Report Insights</h3>
                  <div style="text-align: right">
                    <Button
                      icon="pi pi-external-link"
                      size="large"
                      link
                      @click="export_insight_CSV($event)"
                    />
                  </div>
                </div>
                <Column field="key" header="Key" sortable></Column>
                <Column field="insight" header="Insight">
                  <template #body="slotProps">
                    <ul>
                      <li v-for="entry in slotProps.data.insight" :key="entry">
                        {{ entry }}<br />
                      </li>
                    </ul>
                  </template>
                </Column>
              </DataTable>
            </div>
          </template>
        </Card>
      </AccordionTab>

      <!-- ################################
          Users With Unknown Models
          ################################ -->

      <AccordionTab header="Users With Unknown Devices">
        <Card class="mt-2 shadow-5">
          <template #content>
            <div class="card">
              <DataTable
                :value="this.users_with_unknown_devices"
                stripedRows
                tableStyle="min-width: 50rem"
                paginator
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50, 100]"
                :class="`p-datatable-sm`"
                resizableColumns
                columnResizeMode="expand"
                ref="users_with_unknown_model_table"
                exportFilename="users_with_unknown_model_table"
              >
                <div class="grid w-auto">
                  <h3 class="ml-3 mr-3">Users With Unknown Devices</h3>
                  <div style="text-align: right">
                    <Button
                      icon="pi pi-external-link"
                      size="large"
                      link
                      @click="export_users_with_unknown_model_table($event)"
                    />
                  </div>
                </div>
                <Column
                  v-for="(key, index) of selected_columns"
                  :key="key + '_' + index"
                  :field="key"
                  :header="key"
                  sortable
                >
                  <template
                    #body="slotProps"
                    v-if="this.is_alertable_field(key)"
                  >
                    <div :class="is_alertable(slotProps.data, key)">
                      {{ slotProps.data[key] }}
                    </div>
                  </template></Column
                >
              </DataTable>
            </div>
          </template>
        </Card>
      </AccordionTab>

      <AccordionTab header="Unknown Browsers">
        <Card class="mt-2 shadow-5">
          <!-- ################################
          Unknown Browsers
          ################################ -->
          <template #content>
            <div class="card">
              <DataTable
                :value="this.unknown_browsers"
                stripedRows
                tableStyle="min-width: 50rem"
                ref="unknown_browsers_table"
                exportFilename="Uknown Browsers"
                paginator
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50, 100]"
                :class="`p-datatable-sm`"
                resizableColumns
                columnResizeMode="expand"
              >
                <div class="grid w-auto">
                  <h3 class="ml-3 mr-3">Unknown Browsers</h3>
                  <div style="text-align: right">
                    <Button
                      icon="pi pi-external-link"
                      size="large"
                      link
                      @click="export_unknown_browser_CSV($event)"
                    />
                  </div>
                </div>
                <Column field="browser" header="Browser" sortable></Column>
              </DataTable>
            </div>
          </template>
        </Card>
      </AccordionTab>
      <!-- ################################
          Unknown OSes
          ################################ -->
      <AccordionTab header="Unknown OSes">
        <Card class="mt-2 shadow-5">
          <template #content>
            <div class="card">
              <DataTable
                :value="this.unknown_os"
                stripedRows
                tableStyle="min-width: 50rem"
                ref="unknown_os_table"
                exportFilename="Uknown OSes"
                paginator
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50, 100]"
                :class="`p-datatable-sm`"
                resizableColumns
                columnResizeMode="expand"
              >
                <div class="grid w-auto">
                  <h3 class="ml-3 mr-3">Unknown OSes</h3>
                  <div style="text-align: right">
                    <Button
                      icon="pi pi-external-link"
                      size="large"
                      link
                      @click="export_unknown_os_CSV($event)"
                    />
                  </div>
                </div>
                <Column field="os" header="OS" sortable></Column>
              </DataTable>
            </div>
          </template>
        </Card>
      </AccordionTab>
      <!-- ################################
          Unknown Countries
          ################################ -->
      <AccordionTab header="Unknown Countries">
        <Card class="mt-2 shadow-5">
          <template #content>
            <div class="card">
              <DataTable
                :value="this.unknown_countries"
                stripedRows
                tableStyle="min-width: 50rem"
                ref="unknown_countries_table"
                exportFilename="Uknown Countries"
                paginator
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50, 100]"
                :class="`p-datatable-sm`"
                resizableColumns
                columnResizeMode="expand"
              >
                <div class="grid w-auto">
                  <h3 class="ml-3 mr-3">Unknown Countries</h3>
                  <div style="text-align: right">
                    <Button
                      icon="pi pi-external-link"
                      size="large"
                      link
                      @click="export_unknown_countries_CSV($event)"
                    />
                  </div>
                </div>
                <Column field="country" header="Country" sortable></Column>
              </DataTable>
            </div>
          </template>
        </Card>
      </AccordionTab>

      <!-- ################################
          Unknown Fraud Reasons
          ################################ -->

      <AccordionTab header="Unknown Fraud Reasons">
        <Card class="mt-2 shadow-5">
          <template #content>
            <div class="card">
              <DataTable
                :value="this.unknown_fraud_reasons"
                stripedRows
                tableStyle="min-width: 50rem"
                paginator
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50, 100]"
                :class="`p-datatable-sm`"
                resizableColumns
                columnResizeMode="expand"
                ref="unknown_fraud_reasons_table"
                exportFilename="unknown_fraud_reasons"
              >
                <div class="grid w-auto">
                  <h3 class="ml-3 mr-3">Unknown Fraud Reasons</h3>
                  <div style="text-align: right">
                    <Button
                      icon="pi pi-external-link"
                      size="large"
                      link
                      @click="export_unknown_fraud_reasons_CSV($event)"
                    />
                  </div>
                </div>
                <Column field="brand" header="Fraud Reason" sortable></Column>
              </DataTable>
            </div>
          </template>
        </Card>
      </AccordionTab>

      <!-- ################################
          Unknown Model Reasons
          ################################ -->

      <AccordionTab header="Unknown Models">
        <Card class="mt-2 shadow-5">
          <template #content>
            <div class="card">
              <DataTable
                :value="this.unknown_models"
                stripedRows
                tableStyle="min-width: 50rem"
                paginator
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50, 100]"
                :class="`p-datatable-sm`"
                resizableColumns
                columnResizeMode="expand"
                ref="unknown_model_table"
                exportFilename="unknown_models"
              >
                <div class="grid w-auto">
                  <h3 class="ml-3 mr-3">Unknown Models</h3>
                  <div style="text-align: right">
                    <Button
                      icon="pi pi-external-link"
                      size="large"
                      link
                      @click="export_unknown_model_CSV($event)"
                    />
                  </div>
                </div>
                <Column field="brand" header="Brand" sortable></Column>
                <Column field="model" header="Model" sortable></Column>
                <Column field="cpu" header="CPU" sortable></Column>
                <Column field="entry" header="Entry" sortable></Column>
              </DataTable>
            </div>
          </template>
        </Card>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
// @ is an alias to /src
import { toRaw } from "vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Card from "primevue/card";
import Tag from "primevue/tag";

import * as API from "./../api";

const INSIGHTS = new API.insights();

export default {
  name: "InsitesCard",
  components: { Accordion, AccordionTab, DataTable, Column, Button, Card, Tag },
  data() {
    return {
      unknown_browsers: [],
      unknown_os: [],
      unknown_countries: [],
      unknown_fraud_reasons: [],
      unknown_models: [],
      users_with_unknown_devices: [],
      insight_table: [],
      entry: 0,
      report_label: "",
      selected_columns: null,
      alertable_fields: ["fraud_score", "vpn", "bot_status", "proxy"],
    };
  },
  mounted() {
    if (this.$route.query.entry) {
      this.entry = this.$route.query.entry;
    }
    this.set_data();
  },
  // created: {},
  computed: {
    totalRecords() {
      return toRaw(this.$store.getters.get_reports.length);
    },
  },
  watch: {},
  methods: {
    is_alertable_field(key) {
      return this.alertable_fields.includes(key);
    },
    is_alertable(data) {
      for (let key = 0; key < this.selected_columns.length; key++) {
        // if (data[key] || parseInt(data[key]) > 90) {
        return [
          "",
          {
            "bg-red-100 text-black-900": parseInt(data.fraud_score) >= 90,
            "bg-yellow-100 text-black-900":
              parseInt(data.fraud_score) >= 80 &&
              parseInt(data.fraud_score) < 90,
          },
        ];
        // }
      }
    },
    update_page(page) {
      this.update_page_count();
      this.set_data();
      this.entry = page;
    },
    update_page_count() {
      if (this.$store.getters.get_reports) {
        this.totalRecords = toRaw(this.$store.getters.get_reports).length;
      }
    },
    is_selected(key) {
      if (key === this.entry) {
        return " bg-primary";
      } else {
        return "";
      }
    },
    export_unknown_model_CSV() {
      this.$refs.unknown_model_table.exportCSV();
    },
    export_users_with_unknown_model_table() {
      this.$refs.users_with_unknown_model_table.exportCSV();
    },
    export_insight_CSV() {
      this.$refs.insight_table.exportCSV();
    },
    export_unknown_browser_CSV() {
      this.$refs.unknown_browsers_table.exportCSV();
    },
    export_unknown_os_CSV() {
      this.$refs.unknown_os_table.exportCSV();
    },
    export_unknown_countries_CSV() {
      this.$refs.unknown_countries_table.exportCSV();
    },
    export_unknown_fraud_reasons_CSV() {
      this.$refs.unknown_fraud_reasons_table.exportCSV();
    },
    set_data() {
      if (this.$store.getters.get_reports.length > 0) {
        this.update_page_count();
        if (this.$store.getters.get_reports[this.entry].ip_reports) {
          this.report_label = toRaw(this.$store.getters.get_reports)[
            this.entry
          ].ip_reports.label;
          this.unknown_browsers = [];
          this.unknown_os = [];
          this.unknown_browsers = toRaw(
            this.$store.getters.get_reports[this.entry].ip_reports
              .unknown_browsers
          );
          this.unknown_os = toRaw(
            this.$store.getters.get_reports[this.entry].ip_reports.unknown_os
          );
          this.unknown_countries = toRaw(
            this.$store.getters.get_reports[this.entry].ip_reports
              .unknown_countries
          );
          this.unknown_fraud_reasons = toRaw(
            this.$store.getters.get_reports[this.entry].ip_reports
              .unknown_fraud_reasons
          );
          this.unknown_models = toRaw(
            this.$store.getters.get_reports[this.entry].ip_reports.unknown_model
          );
          this.users_with_unknown_devices = toRaw(
            this.$store.getters.get_reports[this.entry].ip_reports
              .users_with_unknown_devices
          );
          if (this.users_with_unknown_devices.length > 0) {
            this.selected_columns = Object.keys(
              this.users_with_unknown_devices[0]
            );
          }
        }
      }
    },
    async get_insights() {
      const PAYLOAD = {
        reports: toRaw(this.$store.getters.get_reports[this.entry].ip_reports),
        raw_data: toRaw(
          this.$store.getters.get_reports[this.entry].ip_reports.raw_data
        ),
      };
      this.insight_table = await INSIGHTS.get_insights(PAYLOAD);
    },
  },
};
</script>

<style scoped>
.tableHover:hover {
  background-color: #e6e6e6;
}
</style>
