<template>
  <div>
    <div class="card relative z-2">
      <Menubar :model="items">
        <template #end>
          <div class="flex flex-row-reverse flex-wrap card-container w-auto">
            <Button
              class="flex align-items-center justify-content-center mr-1"
              label="Search"
              text
              @click="validate"
            ></Button>
            <InputText
              class="flex-grow-1 align-items-center justify-content-center"
              type="text"
              placeholder="Fast Lookup"
              v-model="validation_data"
            />
            <h3 class="flex align-items-center justify-content-center mr-3">
              IPQS Support <b v-tooltip.bottom="'Common-Sense Seer'">(CSS)</b>
            </h3>
          </div>
        </template>
      </Menubar>
      <div class="card m-2" v-if="is_progress_bar_visible">
        <ProgressBar :value="progress_bar_value"></ProgressBar>
      </div>
    </div>
    <template>
      <input
        id="csv_selector"
        type="file"
        @change="trigger_processing"
        accept="*.csv"
      />
    </template>
    <div class="card flex justify-content-center">
      <Dialog
        v-model:visible="is_quick_validation_visible"
        modal
        header="IPQS Data"
        :style="{ width: '50vw' }"
      >
        <h3>{{ this.validation_data }}</h3>
        <table style="text-align: left">
          <tr v-for="(line, id) in this.ipqs_response[0]" :key="id">
            <th class="w-6 m-1">{{ id }}</th>
            <th class="w-6 m-1">{{ line }}</th>
          </tr>
        </table>
      </Dialog>
    </div>
    <Toast />
    <!-- modals -->
    <DiffTableCard />
    <ParseCSVCard />
    <CombineTablesCard />
    <APIKeyCard />
    <ListCard />
    <IPQSValidationCard />
    <ValidateCSVCard />
  </div>
</template>

<script>
import { toRaw } from "vue";

// @ is an alias to /src
import Menubar from "primevue/menubar";
import * as API from "./../api";
import Toast from "primevue/toast";
import ProgressBar from "primevue/progressbar";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";

// modals
import DiffTableCard from "@/components/modals/DiffTableCard.vue";
import ParseCSVCard from "@/components/modals/ParseCSVCard.vue";
import CombineTablesCard from "@/components/modals/CombineTablesCard.vue";
import APIKeyCard from "@/components/modals/APIKeyCard.vue";
import ListCard from "@/components/modals/ListCard.vue";
import IPQSValidationCard from "@/components/modals/IPQSValidationCard.vue";
import ValidateCSVCard from "@/components/modals/ValidateCSVCard.vue";

const CSV = new API.csv();
const REPORTS = new API.reports();
const LOGS = new API.logs();
const IPQS = new API.ipqs();
const IP = new API.ip();

export default {
  name: "NavBar",
  components: {
    Menubar,
    Toast,
    IPQSValidationCard,
    DiffTableCard,
    ParseCSVCard,
    CombineTablesCard,
    APIKeyCard,
    ListCard,
    ValidateCSVCard,
    ProgressBar,
    Button,
    InputText,
    Dialog,
  },
  setup() {},
  data() {
    return {
      validation_data: "",
      is_quick_validation_visible: false,
      ipqs_response: [],
      report_modal: false,
      report_label: "",
      api_key: "",
      items: [
        {
          label: "New",
          items: [
            {
              label: "Import",
              items: [
                {
                  label: "Advanced Report",
                  items: [
                    {
                      label: "Raw Report",
                      command: this.process_csv,
                    },
                    {
                      label: "Parsed Report",
                      command: this.parse_csv,
                    },
                  ],
                },
                {
                  label: "IPQS API",
                  items: [
                    {
                      label: "List",
                      command: this.trigger_list_modal,
                    },
                    {
                      label: "Validation",
                      command: this.trigger_email_validation_card,
                    },
                  ],
                },
                {
                  label: "CSV",
                  command: this.trigger_validate_csv_card,
                },
              ],
            },
          ],
        },
        {
          label: "View",
          items: [
            {
              label: "Dashboard",
              command: this.trigger_dashboard_page_view,
            },
            {
              separator: true,
            },
            {
              label: "Base Reports",
              command: this.trigger_basic_metrics_page_view,
            },
            {
              label: "Connection Reports",
              command: this.trigger_connection_type_page_view,
            },
            {
              label: "OS Reports",
              command: this.trigger_os_metrics_page_view,
            },
            {
              label: "Browser Reports",
              command: this.trigger_browser_metrics_page_view,
            },
            {
              label: "Country Reports",
              command: this.trigger_country_metrics_page_view,
            },
            {
              label: "Fraud Reasons Reports",
              command: this.trigger_fraud_reasons_page_view,
            },
            {
              label: "Device Connection Reports",
              command: this.trigger_device_connection_page_view,
            },
            {
              separator: true,
            },
            {
              label: "Raw Data",
              command: this.trigger_raw_data_page_view,
            },
            {
              label: "Data Insights",
              command: this.trigger_insight_data_page_view,
            },
          ],
        },
        {
          label: "Options",
          items: [
            {
              label: "Save API Key",
              command: this.trigger_api_key_card,
            },
            {
              label: "Enable Diff Table",
              command: this.trigger_diff_table_card,
            },
            // {
            //   label: "Combine Reports",
            //   command: this.trigger_combine_table_card,
            // },
          ],
        },
      ],
    };
  },
  mounted() {},
  // created: {},
  computed: {
    is_progress_bar_visible() {
      return this.$store.getters.get_is_progress_bar_visible;
    },
    progress_bar_value() {
      return this.$store.getters.get_progress_bar;
    },
    fingerprint() {
      return this.$store.getters.get_fingerprint;
    },
    store_api_key() {
      return toRaw(this.$store.getters.get_api_key);
    },
  },
  watch: {
    store_api_key(new_value, old_value) {
      if (new_value !== old_value) {
        this.api_key = new_value;
      }
    },
  },
  methods: {
    trigger_list_modal() {
      this.$store.commit("set_is_list_modal_visible");
    },
    trigger_email_validation_card() {
      this.$store.commit("set_is_ipqs_validation_card_visible");
    },
    trigger_combine_table_card() {
      this.$store.commit("set_is_combine_reports_card_visible");
    },
    trigger_diff_table_card() {
      this.$store.commit("set_is_diff_table_visible");
    },
    trigger_api_key_card() {
      this.$store.commit("set_is_api_key_card_visible");
    },
    trigger_basic_metrics_page_view() {
      this.$router.push({ path: "BaseMetricsCard" });
    },
    trigger_connection_type_page_view() {
      this.$router.push({ path: "ConnectionTypeMetricsCard" });
    },
    trigger_dashboard_page_view() {
      this.$router.push({ path: "/" });
    },
    trigger_os_metrics_page_view() {
      this.$router.push({ path: "OSMetricsCard" });
    },
    trigger_browser_metrics_page_view() {
      this.$router.push({ path: "BrowserMetricsCard" });
    },
    trigger_device_connection_page_view() {
      this.$router.push({ path: "DeviceConnectionTypeCard" });
    },
    trigger_country_metrics_page_view() {
      this.$router.push({ path: "CountryMetricsCard" });
    },
    trigger_fraud_reasons_page_view() {
      this.$router.push({ path: "FraudReasonsCard" });
    },
    trigger_raw_data_page_view() {
      this.$router.push({ path: "RawDataCard" });
    },
    trigger_insight_data_page_view() {
      this.$router.push({ path: "InsitesCard" });
    },
    trigger_validate_csv_card() {
      this.$store.commit("set_is_validate_csv_modal_visible");
    },
    process_csv() {
      document.getElementById("csv_selector").value = null;
      document.getElementById("csv_selector").click();
    },
    parse_csv() {
      this.$store.commit("set_is_csv_parse_card_visible");
    },
    async trigger_processing(event) {
      this.report_modal = false;
      this.$toast.add({
        severity: "info",
        detail: "Working... ",
        life: 3000,
      });
      const RESULT = await CSV.convert_csv_to_object(event);
      this.$store.commit("set_raw_data", RESULT);
      LOGS.log_to_console("Data", RESULT);
      const REPORT = await REPORTS.create_reports(RESULT);
      LOGS.log_to_console("REPORT", REPORT);
      REPORT.ip_reports.raw_data = RESULT;
      REPORT.ip_reports.label = event.target.files[0].name;
      this.$store.commit("set_reports", REPORT);
      let message = "";
      if (REPORT.ip_reports.unknown_browsers.length > 0) {
        message = `Unknown Browsers Found`;
      }
      if (REPORT.ip_reports.unknown_countries.length > 0) {
        message = `${message}, Unknown Countries Found`;
      }
      if (REPORT.ip_reports.unknown_fraud_reasons.length > 0) {
        message = `${message}, Unknown Fraud Reasons Found`;
      }
      if (REPORT.ip_reports.unknown_os.length > 0) {
        message = `${message}, Unknown OS Found`;
      }
      if (REPORT.ip_reports.unknown_brand.length > 0) {
        message = `${message}, Unknown Brand Found`;
      }
      if (REPORT.ip_reports.unknown_model.length > 0) {
        message = `${message}, Unknown Model Found`;
      }
      if (message === "") {
        message = `Processing Complete`;
      } else {
        message = `Processing Complete: ${message}`;
      }
      this.$toast.add({
        severity: "success",
        detail: message,
        life: 3200,
      });
    },
    async validate() {
      console.log(this.store_api_key);
      if (this.store_api_key != "") {
        const IS_IPV4 = await IP.is_ipv4_address(this.validation_data);
        const IS_EMAIL = this.validation_data.includes("@");
        let endpoint = "phone";
        if (IS_IPV4) endpoint = "ip";
        if (IS_EMAIL) endpoint = "email";
        const PAYLOAD = {
          data: [this.validation_data],
          api_key: this.store_api_key,
          endpoint: endpoint,
          fingerprint: this.fingerprint,
        };
        this.ipqs_response = await IPQS.validate_data(PAYLOAD);
        this.is_quick_validation_visible = true;
      } else {
        this.$toast.add({
          severity: "success",
          detail: "API key missing. enter in Settings first.",
          life: 3200,
        });
      }
    },
  },
};
</script>

<style scoped>
.navBarBuffer {
  margin-bottom: 50px;
}
.menu-item-width {
  width: 50px;
}
</style>
