// const SERVICE = require("./../services/ip_service");
/* eslint-disable */

function log_to_console(message, data) {
  if (process.env.VUE_APP_API_DEBUG) {
    console.log(`${message}: `, data);
  }
}

module.exports = { log_to_console };
