<template>
  <div class="card flex justify-content-center">
    <Dialog
      modal
      header="Enter CSV Parse Data"
      :style="{ width: '50vw' }"
      :visible="is_visible"
      closeIcon="false"
    >
      <div class="card flex mt-2">
        <InputText
          type="text"
          placeholder="Report Label"
          v-model="report_label"
        />
      </div>
      <div class="mt-2">
        <div class="flex align-items-center mt-2">
          <RadioButton
            v-model="parse_type"
            inputId="keep"
            name="keep"
            value="keep"
          />
          <label for="keep" class="ml-2">Keep Lines in Original Reports</label>
        </div>
        <div class="flex align-items-center mt-2">
          <RadioButton
            v-model="parse_type"
            inputId="remove"
            name="remove"
            value="remove"
          />
          <label for="ip" class="ml-2">Remove Lines from Original Report</label>
        </div>
      </div>
      <div class="flex flex-wrap gap-3 mt-2">
        <Textarea
          v-model="parse_data"
          rows="5"
          placeholder="Data to parse from CSV, one per line"
          cols="30"
        />
      </div>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="this.cancel" text />
        <Button
          label="Select CSV"
          icon="pi pi-check"
          @click="this.process_csv"
        />
      </template>
    </Dialog>
    <template>
      <input
        id="parse_csv_selector"
        type="file"
        @change="submit"
        accept="*.csv"
      />
    </template>
    <Toast />
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Textarea from "primevue/textarea";
import InputText from "primevue/inputtext";
import Toast from "primevue/toast";
import RadioButton from "primevue/radiobutton";

// import { toRaw } from "vue";

import * as API from "../../api";
const CSV = new API.csv();
const REPORTS = new API.reports();

export default {
  name: "ParseCSVCard",
  components: { Dialog, Button, Textarea, InputText, Toast, RadioButton },
  data() {
    return {
      visible: true,
      parse_data: "",
      report_label: "",
      parse_type: "keep",
    };
  },
  mounted() {},
  // created: {},
  computed: {
    is_visible() {
      return this.$store.getters.get_is_csv_parse_card_visible;
    },
  },
  watch: {},
  methods: {
    process_csv() {
      document.getElementById("parse_csv_selector").value = null;
      document.getElementById("parse_csv_selector").click();
    },
    async submit(event) {
      this.$toast.add({
        severity: "info",
        detail: "Working... ",
        life: 3000,
      });
      const data_to_parse = this.parse_data.split("\n");
      const DATA = {
        retention_type: this.parse_type,
        data: data_to_parse,
        file: event,
      };
      const PARSED_REPORT = await CSV.parse_csv(DATA);
      if (PARSED_REPORT.status === "success") {
        const REPORT = await REPORTS.create_reports(PARSED_REPORT.data);
        REPORT.ip_reports.raw_data = PARSED_REPORT.data;
        REPORT.ip_reports.label = this.report_label;
        this.$store.commit("set_reports", REPORT);
        this.display_toast(PARSED_REPORT.message);
      } else {
        this.display_toast("Parscing CSV failed");
      }
      this.$store.commit("set_is_csv_parse_card_visible");
    },
    cancel() {
      this.$store.commit("set_is_csv_parse_card_visible");
    },
    display_toast(message) {
      this.$toast.add({
        severity: "success",
        detail: message,
        life: 3200,
      });
    },
  },
};
</script>

<style></style>
