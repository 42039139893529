const IPQS_CONTROLLER = require("./controllers/ipqs_controller");
// ---------------
const IP_CONTROLLER = require("./controllers/ip_controller");
const CSV_CONTROLLER = require("./controllers/csv_controller");
const REPORTS_CONTROLLER = require("./controllers/reports_controller");
const INSIGHTS_CONTROLLER = require("./controllers/insights");
const LIST_CONTROLLER = require("./controllers/list_controller");
const LOGS_CONTROLLER = require("./controllers/logs");
const LOCAL_STORAGE_CONTROLLER = require("./controllers/local_storage_controller");

const STORE = require("../store/index.js");

export class ip {
  async split_subnet(data) {
    const IP_DATA = await IP_CONTROLLER.split_cidr(data);
    return IP_DATA;
  }
  async format_ip_response(data) {
    const IP_DATA = await IP_CONTROLLER.format_ip_response(data);
    return IP_DATA;
  }
  async is_ipv4_address(ip) {
    const IS_IPV4 = IP_CONTROLLER.is_ipv4_address(ip);
    return IS_IPV4;
  }
}

export class local_storage {
  async save_data(fingerprint, data, key) {
    const RESPONSE = await LOCAL_STORAGE_CONTROLLER.save_data(
      fingerprint,
      data,
      key
    );
    return RESPONSE;
  }
  async get_data(fingerprint, key) {
    const RESPONSE = await LOCAL_STORAGE_CONTROLLER.get_data(fingerprint, key);
    return RESPONSE;
  }
}

export class csv {
  async convert_csv_to_object(event) {
    const file_path = event.target.files[0];
    const RESPONSE = await CSV_CONTROLLER.convert_csv_to_object(file_path);
    return RESPONSE;
  }
  async parse_csv(data) {
    const PARSE_DATA = data.data;
    const FILE = data.file.target.files[0];
    const RETENTION_TYPE = data.retention_type;
    const RESPONSE = await CSV_CONTROLLER.parse_csv(
      PARSE_DATA,
      FILE,
      RETENTION_TYPE
    );
    return RESPONSE;
  }
}

export class ipqs {
  async validate_data(data) {
    const IPQS_DATA = await IPQS_CONTROLLER.validate_data(data);
    return IPQS_DATA;
  }
}

export class logs {
  async log_to_console(message, data) {
    LOGS_CONTROLLER.log_to_console(message, data);
  }
}

export class reports {
  async create_reports(data) {
    const REPORT_DATA = await REPORTS_CONTROLLER.run_ip_reports(data);
    return REPORT_DATA;
  }
}

export class fingerprint {
  save_fingerprint(data) {
    STORE.default.commit("set_fingerprint", data);
  }
}

export class insights {
  async get_insights(data) {
    const REPORT_DATA = await INSIGHTS_CONTROLLER.get_insights(data);
    return REPORT_DATA;
  }
}

export class list {
  async fetch_list_data(data) {
    const REPORT_DATA = await LIST_CONTROLLER.fetch_list_data(data);
    return REPORT_DATA;
  }
}
