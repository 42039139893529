<template>
  <div class="card flex justify-content-center">
    <Dialog
      modal
      header="Select Reports"
      :style="{ width: '50vw' }"
      :visible="is_visible"
      closeIcon="false"
    >
      <div v-if="this.labels.length > 0">
        <div
          v-for="label in this.labels"
          :key="label"
          class="flex align-items-center"
        >
          <RadioButton
            v-model="selected_reports"
            :inputId="label"
            name="category"
            :value="label"
          />
          <label :for="label" class="ml-2 mt-2">{{ label }}</label>
        </div>
      </div>
      <div v-else>
        <h2>No reports available.</h2>
      </div>

      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="this.cancel" text />
        <Button
          label="Get"
          icon="pi pi-check"
          @click="this.submit"
          autofocus
          v-if="this.labels.length > 0"
        />
        <Button
          label="Get"
          icon="pi pi-check"
          @click="this.submit"
          autofocus
          disabled
          v-else
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import RadioButton from "primevue/radiobutton";
import { toRaw } from "vue";

export default {
  name: "DiffTableCard",
  components: { Dialog, Button, RadioButton },
  data() {
    return {
      visible: true,
      ip: "",
      debug: false,
      cache: false,
      labels: [],
      selected_reports: [],
    };
  },
  mounted() {
    this.get_labels();
  },
  // created: {},
  computed: {
    is_visible() {
      return this.$store.getters.get_is_diff_table_visible;
    },
  },
  watch: {
    is_visible: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.get_labels();
        }
      },
      deep: true,
    },
  },
  methods: {
    get_labels() {
      this.labels = [];
      const reports = toRaw(this.$store.getters.get_reports);
      for (let label = 0; label < reports.length; label++) {
        this.labels.push(reports[label].ip_reports.label);
      }
    },
    async submit() {
      const PAYLOAD = { enabled: true, tables: this.selected_reports };
      this.$store.commit("set_is_diff_table_visible");
      this.$store.commit("set_diff_table_info", PAYLOAD);
    },
    cancel() {
      this.$store.commit("set_is_diff_table_visible");
    },
  },
};
</script>

<style></style>
