const axios = require("axios");
const store = require("../../store/index.js");

const INTERVAL_MS = 10;

const api = axios.create({});
let PENDING_REQUESTS = 0;
/* eslint-disable */

/**
 * Axios Request Interceptor
 */
api.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < process.env.VUE_APP_API_THROTTLE) {
        PENDING_REQUESTS++;
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_MS);
  });
});
/**
 * Axios Response Interceptor
 */
api.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response);
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(error);
  }
);

/* eslint-disable */
async function fetch_list_data(data) {
  return new Promise(async (resolve) => {
    const ENDPOINT = store.default.getters.get_endpoint;
    const FINGERPRINT = store.default.getters.get_fingerprint;
    const URL = `${ENDPOINT}/list/LIST_fetch_list_data`;
    const PAYLOAD = {
      fingerprint: FINGERPRINT,
      api_key: data.api_key,
      start_date: data.start_date,
      end_date: data.end_date,
      // min_fraud: data.min_fraud,
      // max_fraud: data.max_fraud,
      condition_type: data.condition_type,
      condition: data.condition,
      type: data.type,
      page: data.page,
    };
    const IPQS_RESPONSE = await axios.post(URL, PAYLOAD);
    resolve(IPQS_RESPONSE.data);
  });
}

// function fetch_bulk_list_data(data, api_key, debug, cache) {
//   return new Promise(async (resolve) => {
//     const ENDPOINT = store.default.getters.get_endpoint;
//     const URL = `${ENDPOINT}/ip/IP_read_ip_entry`;
//     const FINGERPRINT = store.default.getters.get_fingerprint;
//     const IPQS_DATA = await Promise.all(
//       data.map(async (entry) => {
//         const PAYLOAD = {
//           ip: entry,
//           api_key: api_key,
//           debug: debug,
//           cache: cache,
//           fingerprint: FINGERPRINT,
//         };
//         const IPQS_RESPONSE = await axios.post(URL, PAYLOAD);
//         return IPQS_RESPONSE.data;
//       })
//     );
//     resolve(IPQS_DATA);
//   });
// }

module.exports = { fetch_list_data };
