const DESKTOP_OS = [
  "Chrome OS 100.0",
  "Chrome OS 101.0",
  "Chrome OS 102.0",
  "Chrome OS 103.0",
  "Chrome OS 104.0",
  "Chrome OS 105.0",
  "Chrome OS 106.0",
  "Chrome OS 107.0",
  "Chrome OS 108.0",
  "Chrome OS 109.0",
  "Chrome OS 110.0",
  "Chrome OS 111.0",
  "Chrome OS 112.0",
  "Chrome OS 113.0",
  "Chrome OS 114.0",
  "Chrome OS 115.0",
  "Chrome OS 116.0",
  "Chrome OS 58.0",
  "Chrome OS 61.0",
  "Chrome OS 65.0",
  "Chrome OS 69.0",
  "Chrome OS 72.0",
  "Chrome OS 74.0",
  "Chrome OS 75.0",
  "Chrome OS 76.0",
  "Chrome OS 77.0",
  "Chrome OS 79.0",
  "Chrome OS 80.0",
  "Chrome OS 81.0",
  "Chrome OS 84.0",
  "Chrome OS 85.0",
  "Chrome OS 86.0",
  "Chrome OS 87.0",
  "Chrome OS 88.0",
  "Chrome OS 89.0",
  "Chrome OS 90.0",
  "Chrome OS 91.0",
  "Chrome OS 92.0",
  "Chrome OS 93.0",
  "Chrome OS 94.0",
  "Chrome OS 96.0",
  "Chrome OS 97.0",
  "Chrome OS 98.0",
  "Chrome OS 99.0",
  "Chrome OS",
  "Debian 3.0",
  "Fedora ",
  "Gentoo ",
  "GNU/Linux ",
  "GNU/Linux",
  "Google",
  "iMac 10.10",
  "iMac 10.12",
  "iMac 10.13",
  "iMac 10.14",
  "iMac 10.15",
  "iMac 10.16",
  "iMac 10.9",
  "iMac 11.0",
  "iMac 11.5",
  "iMac 12.3",
  "iMac 13.0",
  "Linux",
  "Mac ",
  "Mac 10.0",
  "Mac 10.1",
  "Mac 10.10",
  "Mac 10.11",
  "Mac 10.12",
  "Mac 10.13",
  "Mac 10.14",
  "Mac 10.15",
  "Mac 10.16",
  "Mac 10.17",
  "Mac 10.2",
  "Mac 10.29",
  "Mac 10.29",
  "Mac 10.29",
  "Mac 10.3",
  "Mac 10.4",
  "Mac 10.5",
  "Mac 10.6",
  "Mac 10.7",
  "Mac 10.8",
  "Mac 10.9",
  "Mac 11.0",
  "Mac 11.1",
  "Mac 11.10",
  "Mac 11.11",
  "Mac 11.12",
  "Mac 11.13",
  "Mac 11.14",
  "Mac 11.15",
  "Mac 11.16",
  "Mac 11.17",
  "Mac 11.2",
  "Mac 11.3",
  "Mac 11.4",
  "Mac 11.5",
  "Mac 11.6",
  "Mac 11.7",
  "Mac 11",
  "Mac 12.0",
  "Mac 12.0",
  "Mac 12.1",
  "Mac 12.14",
  "Mac 12.2",
  "Mac 12.3",
  "Mac 12.4",
  "Mac 12.5",
  "Mac 12.6",
  "Mac 13.0",
  "Mac 13.1",
  "Mac 13.2",
  "Mac 13.3",
  "Mac 13.4",
  "Mac 14.11",
  "Mac 9.3",
  "Mac 9.3",
  "Mac",
  "NetBSD ",
  "OpenBSD",
  "PlayStation 4",
  "Ubuntu 10.04",
  "Ubuntu",
  "Ubuntu ",
  "UNK UNK",
  "UNK",
  "UNKUNK",
  "Window XP",
  "Windows 10",
  "Windows 2000",
  "Windows 7",
  "Windows 8.1",
  "Windows 8",
  "Windows 98",
  "Windows NT",
  "Windows Server 2003",
  "Windows Vista",
  "Windows XP",
  "Windows",
  "Xbox 360",
];

exports.Array = DESKTOP_OS;
