<template>
  <div>
    <Card class="mt-2 shadow-5">
      <template #content>
        <div class="card">
          <div
            class="flex justify-content-center flex-wrap card-container mt-4 mb-3"
          >
            <div class="flex justify-content-left mr-4">
              <Tag
                severity="warning"
                class="pr-4 pl-4"
                :value="this.report_label"
              ></Tag>
            </div>
            <div
              v-for="(record, key) in this.totalRecords"
              :key="key"
              class="align-items-center justify-content-center ml-2 mr-2"
            >
              <Button
                label="Primary"
                text
                raised
                @click="update_page(key)"
                :class="this.is_selected(key)"
              >
                {{ record }}
              </Button>
            </div>
          </div>
          <Accordion :activeIndex="0" :multiple="true">
            <!-- Residential Table -->
            <AccordionTab header="Residential Connection Report">
              <DataTable
                :value="this.table_data_residential"
                stripedRows
                tableStyle="min-width: 50rem"
                ref="resi_table"
                exportFilename="Residential Connection Report"
                paginator
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50, 100]"
                :class="`p-datatable-sm`"
                resizableColumns
                columnResizeMode="expand"
              >
                <div class="grid w-auto">
                  <div style="text-align: right">
                    <Button
                      icon="pi pi-external-link"
                      size="large"
                      link
                      @click="
                        (this.export_table = `resi_table`), exportCSV($event)
                      "
                    />
                  </div>
                  <h3 class="ml-3 mr-3" v-if="this.diff_enabled">
                    {{ this.report_label }} | Diff Report:
                    {{ this.diff_report_label }}
                  </h3>
                  <h3 class="ml-3 mr-3" v-else>
                    {{ this.report_label }}
                  </h3>
                </div>
                <Column field="key" header="KEY" sortable></Column>
                <Column field="total" header="Total" sortable></Column>
                <Column
                  field="f90"
                  header="Total W/ Fraud 90 or Greater"
                  sortable
                ></Column>
                <Column
                  field="f80"
                  header="Total W/ Fraud 80-89"
                  sortable
                ></Column>
                <Column
                  field="diff_total"
                  header="Diff Totals"
                  sortable
                ></Column>
                <Column
                  field="diff_f90"
                  header="Diff Fraud 90 or Greater"
                  sortable
                ></Column>
                <Column
                  field="diff_f80"
                  header="Diff W/ Fraud 80-89"
                  sortable
                ></Column>
              </DataTable>
            </AccordionTab>
            <!-- Mobile Table -->
            <AccordionTab header="Mobile Connection Report">
              <DataTable
                :value="this.table_data_mobile"
                stripedRows
                tableStyle="min-width: 50rem"
                ref="mobile_table"
                exportFilename="Mobile Connection Report"
                paginator
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50, 100]"
                :class="`p-datatable-sm`"
                resizableColumns
                columnResizeMode="expand"
              >
                <div class="grid w-auto">
                  <div style="text-align: right">
                    <Button
                      icon="pi pi-external-link"
                      size="large"
                      link
                      @click="
                        (this.export_table = `mobile_table`), exportCSV($event)
                      "
                    />
                  </div>
                  <h3 class="ml-3 mr-3" v-if="this.diff_enabled">
                    {{ this.report_label }} | Diff Report:
                    {{ this.diff_report_label }}
                  </h3>
                  <h3 class="ml-3 mr-3" v-else>
                    {{ this.report_label }}
                  </h3>
                </div>
                <Column field="key" header="KEY" sortable></Column>
                <Column field="total" header="Total" sortable></Column>
                <Column
                  field="f90"
                  header="Total W/ Fraud 90 or Greater"
                  sortable
                ></Column>
                <Column
                  field="f80"
                  header="Total W/ Fraud 80-89"
                  sortable
                ></Column>
                <Column
                  field="diff_f90"
                  header="Diff Fraud 90 or Greater"
                ></Column>
                <Column field="diff_f80" header="Diff W/ Fraud 80-89"></Column>
              </DataTable>
            </AccordionTab>
            <!-- Data Center Table -->
            <AccordionTab header="Data Center Connection Report">
              <DataTable
                :value="this.table_data_data_center"
                stripedRows
                tableStyle="min-width: 50rem"
                ref="data_table"
                exportFilename="Data Center Connection Report"
                paginator
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50, 100]"
                :class="`p-datatable-sm`"
                resizableColumns
                columnResizeMode="expand"
              >
                <div class="grid w-auto">
                  <div style="text-align: right">
                    <Button
                      icon="pi pi-external-link"
                      size="large"
                      link
                      @click="
                        (this.export_table = `data_table`), exportCSV($event)
                      "
                    />
                  </div>
                  <h3 class="ml-3 mr-3" v-if="this.diff_enabled">
                    {{ this.report_label }} | Diff Report:
                    {{ this.diff_report_label }}
                  </h3>
                  <h3 class="ml-3 mr-3" v-else>
                    {{ this.report_label }}
                  </h3>
                </div>
                <Column field="key" header="KEY" sortable></Column>
                <Column field="total" header="Total" sortable></Column>
                <Column
                  field="f90"
                  sortable
                  header="Total W/ Fraud 90 or Greater"
                ></Column>
                <Column
                  field="f80"
                  header="Total W/ Fraud 80-89"
                  sortable
                ></Column>
                <Column
                  field="diff_f90"
                  header="Diff Fraud 90 or Greater"
                ></Column>
                <Column field="diff_f80" header="Diff W/ Fraud 80-89"></Column>
              </DataTable>
            </AccordionTab>
            <!-- Corporate Table -->
            <AccordionTab header="Corporate Connection Report">
              <DataTable
                :value="this.table_data_corporate"
                stripedRows
                tableStyle="min-width: 50rem"
                ref="corporate_table"
                exportFilename="Corporate Connection Report"
                paginator
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50, 100]"
                :class="`p-datatable-sm`"
                resizableColumns
                columnResizeMode="expand"
              >
                <div class="grid w-auto">
                  <div style="text-align: right">
                    <Button
                      icon="pi pi-external-link"
                      size="large"
                      link
                      @click="
                        (this.export_table = `corporate_table`),
                          exportCSV($event)
                      "
                    />
                  </div>
                  <h3 class="ml-3 mr-3" v-if="this.diff_enabled">
                    {{ this.report_label }} | Diff Report:
                    {{ this.diff_report_label }}
                  </h3>
                  <h3 class="ml-3 mr-3" v-else>
                    {{ this.report_label }}
                  </h3>
                </div>
                <Column field="key" header="KEY"></Column>
                <Column field="total" header="Total"></Column>
                <Column
                  field="f90"
                  header="Total W/ Fraud 90 or Greater"
                  sortable
                ></Column>
                <Column
                  field="f80"
                  header="Total W/ Fraud 80-89"
                  sortable
                ></Column>
                <Column
                  field="diff_f90"
                  header="Diff Fraud 90 or Greater"
                ></Column>
                <Column field="diff_f80" header="Diff W/ Fraud 80-89"></Column>
              </DataTable>
            </AccordionTab>
            <!-- Education Table -->
            <AccordionTab header="Education Connection Report">
              <DataTable
                :value="this.table_data_education"
                stripedRows
                tableStyle="min-width: 50rem"
                ref="education_table"
                exportFilename="Education Connection Report"
                paginator
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50, 100]"
                :class="`p-datatable-sm`"
                resizableColumns
                columnResizeMode="expand"
              >
                <div class="grid w-auto">
                  <div style="text-align: right">
                    <Button
                      icon="pi pi-external-link"
                      size="large"
                      link
                      @click="
                        (this.export_table = `education_table`),
                          exportCSV($event)
                      "
                    />
                  </div>
                  <h3 class="ml-3 mr-3" v-if="this.diff_enabled">
                    {{ this.report_label }} | Diff Report:
                    {{ this.diff_report_label }}
                  </h3>
                  <h3 class="ml-3 mr-3" v-else>
                    {{ this.report_label }}
                  </h3>
                </div>
                <Column field="key" header="KEY" sortable></Column>
                <Column field="total" header="Total" sortable></Column>
                <Column
                  field="f90"
                  header="Total W/ Fraud 90 or Greater"
                  sortable
                ></Column>
                <Column
                  field="f80"
                  header="Total W/ Fraud 80-89"
                  sortable
                ></Column>
                <Column
                  field="diff_f90"
                  header="Diff Fraud 90 or Greater"
                ></Column>
                <Column field="diff_f80" header="Diff W/ Fraud 80-89"></Column>
              </DataTable>
            </AccordionTab>
          </Accordion>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
// @ is an alias to /src
import { toRaw } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Card from "primevue/card";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Tag from "primevue/tag";

export default {
  name: "ConnectionTypeMetricsCard",
  components: { DataTable, Column, Button, Card, Accordion, AccordionTab, Tag },
  data() {
    return {
      export_table: "",
      table_data_residential: [],
      table_data_mobile: [],
      table_data_corporate: [],
      table_data_education: [],
      table_data_data_center: [],
      entry: 0,
      report_label: "",
      diff_report_label: "",
      diff_enabled: false,
      diff_table_data_residential: [],
      diff_table_data_mobile: [],
      diff_table_data_corporate: [],
      diff_table_data_education: [],
      diff_table_data_data_center: [],
    };
  },
  mounted() {
    if (this.$route.query.entry) {
      this.entry = this.$route.query.entry;
    }
    this.set_data();
  },
  // created: {},
  computed: {
    selected_data() {
      return toRaw(this.$store.getters.get_reports[this.entry]);
    },
    totalRecords() {
      return toRaw(this.$store.getters.get_reports.length);
    },
  },
  watch: {
    selected_data: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.set_data();
        }
      },
      deep: true,
    },
  },
  methods: {
    get_index(object_array, entry) {
      return new Promise((resolve) => {
        object_array.findIndex((element, key) => {
          if (element.ip_reports.label == entry) {
            resolve(key);
          }
        });
      });
    },
    calculate_diffs(original_table, diff_table) {
      const KEYS = Object.keys(original_table);
      KEYS.forEach((key) => {
        if (diff_table[key]) {
          const ORIGINAL_TOTAL = original_table[key].total;
          const DIFF_TOTAL = diff_table[key].total;
          const DIFF_DIFFERENCE = ORIGINAL_TOTAL - DIFF_TOTAL;

          const ORIGINAL_F90 = original_table[key].f90;
          const ORIGINAL_F90_PERCENT =
            parseInt(Math.abs(ORIGINAL_F90 / ORIGINAL_TOTAL) * 100) || 0;

          const DIFF_F90 = diff_table[key].f90;
          const DIFF_F90_PERCENT =
            parseInt(Math.abs((DIFF_F90 / DIFF_TOTAL) * 100)) || 0;

          const ORIGINAL_F80 = original_table[key].f80;
          const ORIGINAL_F80_PERCENT =
            parseInt(Math.abs(ORIGINAL_F80 / ORIGINAL_TOTAL) * 100) || 0;

          const DIFF_F80 = diff_table[key].f80;
          const DIFF_F80_PERCENT =
            parseInt(Math.abs((DIFF_F80 / DIFF_TOTAL) * 100)) || 0;

          original_table[key].diff_total = `${DIFF_DIFFERENCE}`;
          original_table[
            key
          ].diff_f90 = `${ORIGINAL_F90_PERCENT}% Vs. ${DIFF_F90_PERCENT}%`;
          original_table[
            key
          ].diff_f80 = `${ORIGINAL_F80_PERCENT}% Vs. ${DIFF_F80_PERCENT}%`;
        }
      });
    },

    update_page(page) {
      this.update_page_count();
      this.set_data();
      this.entry = page;
    },
    update_page_count() {
      if (this.$store.getters.get_reports) {
        this.totalRecords = toRaw(this.$store.getters.get_reports).length;
      }
    },
    is_selected(key) {
      if (key === this.entry) {
        return " bg-primary";
      } else {
        return "";
      }
    },
    exportCSV() {
      this.$refs[this.export_table].exportCSV();
    },
    async set_data() {
      if (toRaw(this.$store.getters.get_reports).length > 0) {
        this.update_page_count();
        this.report_label = this.selected_data.ip_reports.label;
        const RESI_REPORT_TABLE =
          this.selected_data.ip_reports.CONNECTION_TYPE.residential;
        const DATA_CENTER_REPORT_TABLE =
          this.selected_data.ip_reports.CONNECTION_TYPE.data_center;
        const MOBILE_REPORT_TABLE =
          this.selected_data.ip_reports.CONNECTION_TYPE.mobile;
        const CORPORATE_REPORT_TABLE =
          this.selected_data.ip_reports.CONNECTION_TYPE.residential;
        const EDUCATION_REPORT_TABLE =
          this.selected_data.ip_reports.CONNECTION_TYPE.residential;
        this.table_data_residential = await this.build_table(RESI_REPORT_TABLE);
        this.table_data_mobile = await this.build_table(
          DATA_CENTER_REPORT_TABLE
        );
        this.table_data_corporate = await this.build_table(MOBILE_REPORT_TABLE);
        this.table_data_education = await this.build_table(
          CORPORATE_REPORT_TABLE
        );
        this.table_data_data_center = await this.build_table(
          EDUCATION_REPORT_TABLE
        );
        // Start diff calc
        this.diff_enabled = this.$store.getters.get_diff_table_info.enabled;
        if (this.diff_enabled) {
          const diff_table = toRaw(
            this.$store.getters.get_diff_table_info.tables
          );
          // Create diff table
          const ARRAYS = toRaw(this.$store.getters.get_reports);
          const index = await this.get_index(ARRAYS, diff_table);
          this.diff_report_label = toRaw(this.$store.getters.get_reports)[
            index
          ].ip_reports.label;

          const DIFF_RESI_REPORT_TABLE =
            this.$store.getters.get_reports[index].ip_reports.CONNECTION_TYPE
              .residential;
          const DIFF_DATA_CENTER_REPORT_TABLE =
            this.$store.getters.get_reports[index].ip_reports.CONNECTION_TYPE
              .data_center;
          const DIFF_MOBILE_REPORT_TABLE =
            this.$store.getters.get_reports[index].ip_reports.CONNECTION_TYPE
              .mobile;
          const DIFF_ORPORATE_REPORT_TABLE =
            this.$store.getters.get_reports[index].ip_reports.CONNECTION_TYPE
              .residential;
          const DIFF_EDUCATION_REPORT_TABLE =
            this.$store.getters.get_reports[index].ip_reports.CONNECTION_TYPE
              .residential;
          this.diff_table_data_residential = await this.build_table(
            DIFF_RESI_REPORT_TABLE
          );
          this.diff_table_data_mobile = await this.build_table(
            DIFF_DATA_CENTER_REPORT_TABLE
          );
          this.diff_table_data_corporate = await this.build_table(
            DIFF_MOBILE_REPORT_TABLE
          );
          this.diff_table_data_education = await this.build_table(
            DIFF_ORPORATE_REPORT_TABLE
          );
          this.diff_table_data_data_center = await this.build_table(
            DIFF_EDUCATION_REPORT_TABLE
          );

          this.calculate_diffs(
            this.table_data_residential,
            this.diff_table_data_residential
          );

          this.calculate_diffs(
            this.table_data_mobile,
            this.diff_table_data_mobile
          );

          this.calculate_diffs(
            this.table_data_corporate,
            this.diff_table_data_corporate
          );

          this.calculate_diffs(
            this.table_data_education,
            this.diff_table_data_corporate
          );

          this.calculate_diffs(
            this.table_data_data_center,
            this.diff_table_data_data_center
          );
        }
      }
    },
    build_table(report) {
      return new Promise((resolve) => {
        const KEYS = Object.keys(report);
        const TABLE = [];
        KEYS.forEach((key) => {
          if (report[key].total > 0) {
            TABLE.push(report[key]);
          }
        });
        resolve(TABLE);
      });
    },
  },
};
</script>

<style scoped>
.dataAlert {
  background-color: red;
}
.tableHover:hover {
  background-color: #e6e6e6;
}
</style>
