const OTHER_BROWSERS = [
  "AhrefsBot",
  "aiohttp 3.8",
  "Client 22.5.0",
  "curl 7.54",
  "Go-http-client 1.1",
  "Go-http-client 2.0",
  "Google AdsBot",
  "Googlebot",
  "Guzzle (PHP HTTP Client)",
  "Java 1.8",
  "MajoritySalesTool 20.6.0",
  "MajoritySalesTool 21.5.0",
  "MajoritySalesTool 21.6.0",
  "MajoritySalesTool 22.0.0",
  "MajoritySalesTool 22.1.0",
  "MajoritySalesTool 22.2.0",
  "MajoritySalesTool 22.3.0",
  "MajoritySalesTool 22.4.0",
  "MajoritySalesTool 22.5.0",
  "Minority 20.0.0",
  "Minority 20.1.0",
  "Minority 20.2.0",
  "Minority 20.3.0",
  "Minority 20.4.0",
  "Minority 20.5.0",
  "Minority 20.6.0",
  "Minority 21.0.0",
  "Minority 21.1.0",
  "Minority 21.2.0",
  "Minority 21.3.0",
  "Minority 21.4.0",
  "Minority 21.5.0",
  "Minority 21.6.0",
  "Minority 22.0.0",
  "Minority 22.1.0",
  "Minority 22.2.0",
  "Minority 22.3.0",
  "Minority 22.4.0",
  "Minority 22.5.0",
  "Minority 22.6.0",
  "Minority 23.0.0",
  "OkHttp 3.12,",
  "OkHttp 3.12",
  "OkHttp 4.10",
  "OkHttp 4.7",
  "OkHttp 4.9",
  "Python Requests 2.26",
  "Python Requests 2.27",
  "Python Requests 2.28",
  "Python Requests 2.30",
  "Python Requests 2.31",
  "SEMrushBot",
  "UC Browser 11.6",
  "UNK UNK",
];

exports.Array = OTHER_BROWSERS;
