<template>
  <div>
    <Card class="mt-2 shadow-5">
      <template #content>
        <div class="card">
          <div
            class="flex justify-content-center flex-wrap card-container mt-4 mb-3"
          >
            <div class="flex justify-content-left mr-4">
              <Tag
                severity="warning"
                class="pr-4 pl-4"
                :value="this.report_label"
              ></Tag>
            </div>
            <div
              v-for="(record, key) in this.totalRecords"
              :key="key"
              class="align-items-center justify-content-center ml-2 mr-2"
            >
              <Button
                label="Primary"
                text
                raised
                @click="update_page(key)"
                :class="this.is_selected(key)"
              >
                {{ record }}
              </Button>
            </div>
          </div>
          <DataTable
            :value="this.table_data"
            stripedRows
            tableStyle="min-width: 50rem"
            ref="base_table"
            exportFilename="Basic Report"
          >
            <div class="grid w-auto">
              <div style="text-align: right">
                <Button
                  icon="pi pi-external-link"
                  size="large"
                  link
                  @click="exportCSV($event)"
                />
              </div>
              <h3 class="ml-3 mr-3" v-if="this.diff_enabled">
                Basic Reports : {{ this.report_label }} | Diff Report:
                {{ this.diff_report_label }}
              </h3>
              <h3 class="ml-3 mr-3" v-else>
                Basic Reports : {{ this.report_label }}
              </h3>
            </div>
            <Column field="key" header="KEY"></Column>
            <Column field="total" header="Total"></Column>
            <Column field="f90" header="Total W/ Fraud 90 or Greater"></Column>
            <Column field="f80" header="Total W/ Fraud 80-89"></Column>
            <Column field="diff_total" header="Diff Totals" sortable></Column>
            <Column
              field="diff_f90"
              header="Diff Fraud 90 or Greater"
              sortable
            ></Column>
            <Column
              field="diff_f80"
              header="Diff W/ Fraud 80-89"
              sortable
            ></Column>
          </DataTable>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
// @ is an alias to /src
import { toRaw } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Card from "primevue/card";
import Tag from "primevue/tag";

export default {
  name: "BaseMetricsCard",
  components: { DataTable, Column, Button, Card, Tag },
  data() {
    return {
      table_data: [],
      entry: 0,
      report_label: "",
      diff_report_label: "",
      diff_enabled: false,
      diff_table_data: [],
    };
  },
  mounted() {
    if (this.$route.query.entry) {
      this.entry = this.$route.query.entry;
    }
    this.set_data();
  },
  // created: {},
  computed: {
    selected_data() {
      return toRaw(this.$store.getters.get_reports[this.entry]);
    },
    totalRecords() {
      return toRaw(this.$store.getters.get_reports.length);
    },
  },
  watch: {
    selected_data: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.set_data();
        }
      },
      deep: true,
    },
  },
  methods: {
    print_total(total) {
      console.log("total: ", total);
    },
    exportCSV() {
      this.$refs.base_table.exportCSV();
    },
    async set_data() {
      if (toRaw(this.$store.getters.get_reports).length > 0) {
        this.update_page_count();
        this.report_label = this.selected_data.ip_reports.label;
        this.table_data = [];
        this.table_data = this.create_table(this.entry);
        // Start diff calc
        this.diff_enabled = this.$store.getters.get_diff_table_info.enabled;
        if (this.diff_enabled) {
          const diff_table = toRaw(
            this.$store.getters.get_diff_table_info.tables
          );
          // Create diff table
          const ARRAYS = toRaw(this.$store.getters.get_reports);
          const index = await this.get_index(ARRAYS, diff_table);
          this.diff_report_label = toRaw(this.$store.getters.get_reports)[
            index
          ].ip_reports.label;
          this.diff_table_data = this.create_table(index);
          this.calculate_diffs();
        }
      }
    },
    calculate_diffs() {
      const KEYS = Object.keys(this.table_data);
      let grand_total = 0;
      let diff_grand_total = 0;

      KEYS.forEach((key) => {
        const ORIGINAL_TOTAL = this.table_data[key].total;
        const DIFF_TOTAL = this.diff_table_data[key].total;
        let DIFF_DIFFERENCE = ORIGINAL_TOTAL - DIFF_TOTAL;
        if (this.table_data[key].key === "Total") {
          grand_total = this.table_data[key].total;
          diff_grand_total = this.diff_table_data[key].total;
        } else {
          const ORIGINAL_PERCENT_TOTAL = parseInt(
            Math.abs(ORIGINAL_TOTAL / grand_total) * 100
          );
          const DIFF_PERCENT_TOTAL = parseInt(
            Math.abs(DIFF_TOTAL / diff_grand_total) * 100
          );

          DIFF_DIFFERENCE = `${ORIGINAL_PERCENT_TOTAL}% Vs. ${DIFF_PERCENT_TOTAL}%`;
        }

        const ORIGINAL_F90 = this.table_data[key].f90;
        const ORIGINAL_F90_PERCENT =
          parseInt(Math.abs(ORIGINAL_F90 / ORIGINAL_TOTAL) * 100) || 0;

        const DIFF_F90 = this.diff_table_data[key].f90;
        const DIFF_F90_PERCENT =
          parseInt(Math.abs((DIFF_F90 / DIFF_TOTAL) * 100)) || 0;

        const ORIGINAL_F80 = this.table_data[key].f80;
        const ORIGINAL_F80_PERCENT =
          parseInt(Math.abs(ORIGINAL_F80 / ORIGINAL_TOTAL) * 100) || 0;

        const DIFF_F80 = this.diff_table_data[key].f80;
        const DIFF_F80_PERCENT =
          parseInt(Math.abs((DIFF_F80 / DIFF_TOTAL) * 100)) || 0;

        this.table_data[key].diff_total = `${DIFF_DIFFERENCE}`;
        this.table_data[
          key
        ].diff_f90 = `${ORIGINAL_F90_PERCENT}% Vs. ${DIFF_F90_PERCENT}%`;
        this.table_data[
          key
        ].diff_f80 = `${ORIGINAL_F80_PERCENT}% Vs. ${DIFF_F80_PERCENT}%`;
      });
    },
    get_index(object_array, entry) {
      return new Promise((resolve) => {
        object_array.findIndex((element, key) => {
          if (element.ip_reports.label == entry) {
            resolve(key);
          }
        });
      });
    },
    create_table(entry) {
      const KEYS = Object.keys(this.selected_data.ip_reports.IP);
      const temp_array = [];
      KEYS.forEach((key) => {
        if (
          toRaw(this.$store.getters.get_reports)[entry].ip_reports.IP[key]
            .total > 0
        ) {
          temp_array.push(
            toRaw(this.$store.getters.get_reports)[entry].ip_reports.IP[key]
          );
        }
      });
      return temp_array;
    },
    update_page(page) {
      this.update_page_count();
      this.set_data();
      this.entry = page;
    },
    update_page_count() {
      if (this.$store.getters.get_reports) {
        this.totalRecords = toRaw(this.$store.getters.get_reports).length;
      }
    },
    is_selected(key) {
      if (key === this.entry) {
        return " bg-primary";
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.dataAlert {
  background-color: red;
}
.tableHover:hover {
  background-color: #e6e6e6;
}
</style>
