<template>
  <div>
    <Card class="mt-2 shadow-5">
      <template #title> Dashboard </template>
      <template #content>
        <div class="card flex justify-content-start flex-wrap card-container">
          <Card
            class="mt-2 shadow-5 w-3 m-4"
            v-for="(report, id) in this.reports"
            :key="id"
          >
            <template #title>
              <h2 class="text-base">{{ report.ip_reports.label }}</h2>
            </template>
            <template #content>
              <div class="card">
                <Button
                  label="View"
                  text
                  @click="
                    this.$router.push({
                      path: 'RawDataCard',
                      query: { entry: id },
                    })
                  "
                ></Button>
              </div>
            </template>
          </Card>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { toRaw } from "vue";
// @ is an alias to /src
import Card from "primevue/card";
import Button from "primevue/button";

import * as API from "@/api";
const LOCAL_STORAGE = new API.local_storage();

export default {
  name: "HomeViewCard",
  components: { Card, Button },
  data() {
    return {
      reports: {},
    };
  },
  mounted() {
    this.get_reports();
    this.get_api_key_from_storage(5);
  },
  // created: {},
  computed: {
    store_reports() {
      return toRaw(this.$store.getters.get_reports);
    },
  },
  watch: {
    store_reports(new_value, old_value) {
      if (new_value !== old_value) {
        this.reports = new_value;
      }
    },
  },
  methods: {
    get_reports() {
      this.reports = toRaw(this.$store.getters.get_reports);
    },
    async get_api_key_from_storage(retries) {
      if (retries > 0) {
        const FINGERPRINT = this.$store.getters.get_fingerprint;
        if (FINGERPRINT.device_id) {
          const API_KEY = await LOCAL_STORAGE.get_data(FINGERPRINT, "key");
          if (API_KEY) {
            this.$store.commit("set_api_key", API_KEY);
          }
        } else {
          setTimeout(() => {
            retries = retries - 1;
            this.get_api_key_from_storage(retries);
          }, "1000");
        }
      }
    },
  },
};
</script>

<style>
.dataAlert {
  background-color: red;
}
.tableHover:hover {
  background-color: #e6e6e6;
}
.popOutMenu {
  position: absolute;
  /* left: v-bind(mouseX);
  top: v-bind(mouseY); */
  left: v-bind(mouseX);
  top: v-bind(mouseY);
  z-index: 1500;
}
</style>
