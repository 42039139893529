const DESKTOP_BROWSERS = [
  "Baidu Spark 1.1",
  "Baidu Spark 1.2",
  "Baidu Spark 1.3",
  "Brave ",
  "Brave 75",
  "Chrome ",
  "Chrome 1.0",
  "Chrome 10.0",
  "Chrome 100.0",
  "Chrome 100.5",
  "Chrome 101.0",
  "Chrome 102.0",
  "Chrome 103.0",
  "Chrome 104.0",
  "Chrome 105.0",
  "Chrome 106.0",
  "Chrome 107.0",
  "Chrome 108.0",
  "Chrome 109.0",
  "Chrome 110.0",
  "Chrome 111.0",
  "Chrome 112.0",
  "Chrome 113.0",
  "Chrome 114.0",
  "Chrome 115.0",
  "Chrome 116.0",
  "Chrome 117.0",
  "Chrome 14.0",
  "Chrome 17.0",
  "Chrome 19.0",
  "Chrome 2.0",
  "Chrome 21.0",
  "Chrome 23.0",
  "Chrome 29.0",
  "Chrome 3.0",
  "Chrome 30.0",
  "Chrome 31.0",
  "Chrome 34.0",
  "Chrome 35.0",
  "Chrome 36.0",
  "Chrome 37.0",
  "Chrome 38.0",
  "Chrome 39.0",
  "Chrome 4.0",
  "Chrome 40.0",
  "Chrome 41.0",
  "Chrome 42.0",
  "Chrome 43.0",
  "Chrome 44.0",
  "Chrome 45.0",
  "Chrome 46.0",
  "Chrome 47.0",
  "Chrome 48.0",
  "Chrome 49.0",
  "Chrome 5.0",
  "Chrome 50.0",
  "Chrome 51.0",
  "Chrome 52.0",
  "Chrome 53.0",
  "Chrome 54.0",
  "Chrome 55.0",
  "Chrome 56.0",
  "Chrome 57.0",
  "Chrome 58.0",
  "Chrome 59.0",
  "Chrome 6.0",
  "Chrome 60.0",
  "Chrome 61.0",
  "Chrome 62.0",
  "Chrome 63.0",
  "Chrome 64.0",
  "Chrome 65.0",
  "Chrome 66.0",
  "Chrome 67.0",
  "Chrome 68.0",
  "Chrome 69.0",
  "Chrome 69.2",
  "Chrome 7.0",
  "Chrome 70.0",
  "Chrome 71.0",
  "Chrome 72.0",
  "Chrome 73.0",
  "Chrome 74.0",
  "Chrome 75.0",
  "Chrome 76.0",
  "Chrome 77.0",
  "Chrome 78.0",
  "Chrome 79.0",
  "Chrome 80.0",
  "Chrome 80.2",
  "Chrome 81.0",
  "Chrome 83.0",
  "Chrome 83.6",
  "Chrome 84.0",
  "Chrome 85.0",
  "Chrome 86.0",
  "Chrome 87.0",
  "Chrome 88.0",
  "Chrome 89.0",
  "Chrome 9.0",
  "Chrome 90.0",
  "Chrome 91.0",
  "Chrome 92.0",
  "Chrome 93.0",
  "Chrome 94.0",
  "Chrome 95.0",
  "Chrome 96.0",
  "Chrome 96.1",
  "Chrome 97.0",
  "Chrome 98.0",
  "Chrome 98.3",
  "Chrome 99.0",
  "Chrome 99.112",
  "Chrome 99.113",
  "Chrome Mobile iOS 111.0",
  "Chrome",
  "Chromium 107.0",
  "Chromium 112.0",
  "Chromium 113.0",
  "Chromium 114.0",
  "Chromium 12.0",
  "Chromium 63.0",
  "Chromium 72.0",
  "Chromium 88.0",
  "Coc Coc 50.0",
  "Coc Coc 76.0",
  "Coc Coc 91.0",
  "Coc Coc 94.0",
  "Conkeror 0.9",
  "Facebook 375.0",
  "Facebook 378.0",
  "Firefox 1.0",
  "Firefox 100.0",
  "Firefox 101.0",
  "Firefox 102.0",
  "Firefox 102.11",
  "Firefox 102.12",
  "Firefox 103.0",
  "Firefox 103.010213firefox",
  "Firefox 103.010257firefox",
  "Firefox 103.010469firefox",
  "Firefox 103.010565firefox",
  "Firefox 103.010952firefox",
  "Firefox 103.011070firefox",
  "Firefox 103.012454firefox",
  "Firefox 103.013497firefox",
  "Firefox 103.013758firefox",
  "Firefox 103.014195firefox",
  "Firefox 103.014680firefox",
  "Firefox 103.01475firefox",
  "Firefox 103.014958firefox",
  "Firefox 103.015038firefox",
  "Firefox 103.015139firefox",
  "Firefox 103.015149firefox",
  "Firefox 103.015541firefox",
  "Firefox 103.016085firefox",
  "Firefox 103.01672firefox",
  "Firefox 103.016898firefox",
  "Firefox 103.017109firefox",
  "Firefox 103.01717firefox",
  "Firefox 103.017508firefox",
  "Firefox 103.017876firefox",
  "Firefox 103.018048firefox",
  "Firefox 103.01891firefox",
  "Firefox 103.019367firefox",
  "Firefox 103.019675firefox",
  "Firefox 103.019945firefox",
  "Firefox 103.020142firefox",
  "Firefox 103.020416firefox",
  "Firefox 103.021226firefox",
  "Firefox 103.021985firefox",
  "Firefox 103.022388firefox",
  "Firefox 103.022943firefox",
  "Firefox 103.024058firefox",
  "Firefox 103.024156firefox",
  "Firefox 103.025235firefox",
  "Firefox 103.026596firefox",
  "Firefox 103.026638firefox",
  "Firefox 103.027667firefox",
  "Firefox 103.028114firefox",
  "Firefox 103.028246firefox",
  "Firefox 103.029085firefox",
  "Firefox 103.02952firefox",
  "Firefox 103.031579firefox",
  "Firefox 103.031648firefox",
  "Firefox 103.031954firefox",
  "Firefox 103.032195firefox",
  "Firefox 103.032257firefox",
  "Firefox 103.034115firefox",
  "Firefox 103.034190firefox",
  "Firefox 103.034488firefox",
  "Firefox 103.035085firefox",
  "Firefox 103.035112firefox",
  "Firefox 103.035256firefox",
  "Firefox 103.035413firefox",
  "Firefox 103.035934firefox",
  "Firefox 103.035972firefox",
  "Firefox 103.036088firefox",
  "Firefox 103.036192firefox",
  "Firefox 103.036284firefox",
  "Firefox 103.036290firefox",
  "Firefox 103.036401firefox",
  "Firefox 103.036535firefox",
  "Firefox 103.036564firefox",
  "Firefox 103.036852firefox",
  "Firefox 103.036936firefox",
  "Firefox 103.03702firefox",
  "Firefox 103.038004firefox",
  "Firefox 103.038142firefox",
  "Firefox 103.039090firefox",
  "Firefox 103.039565firefox",
  "Firefox 103.039727firefox",
  "Firefox 103.040772firefox",
  "Firefox 103.040933firefox",
  "Firefox 103.041597firefox",
  "Firefox 103.04213firefox",
  "Firefox 103.042624firefox",
  "Firefox 103.043014firefox",
  "Firefox 103.043520firefox",
  "Firefox 103.04380firefox",
  "Firefox 103.044087firefox",
  "Firefox 103.046036firefox",
  "Firefox 103.046151firefox",
  "Firefox 103.046372firefox",
  "Firefox 103.047611firefox",
  "Firefox 103.047688firefox",
  "Firefox 103.047887firefox",
  "Firefox 103.047924firefox",
  "Firefox 103.04853firefox",
  "Firefox 103.048642firefox",
  "Firefox 103.04900firefox",
  "Firefox 103.049655firefox",
  "Firefox 103.049839firefox",
  "Firefox 103.049920firefox",
  "Firefox 103.050549firefox",
  "Firefox 103.051665firefox",
  "Firefox 103.05173firefox",
  "Firefox 103.052137firefox",
  "Firefox 103.052779firefox",
  "Firefox 103.053281firefox",
  "Firefox 103.053519firefox",
  "Firefox 103.054448firefox",
  "Firefox 103.054567firefox",
  "Firefox 103.054799firefox",
  "Firefox 103.055904firefox",
  "Firefox 103.057182firefox",
  "Firefox 103.05788firefox",
  "Firefox 103.058373firefox",
  "Firefox 103.058490firefox",
  "Firefox 103.059035firefox",
  "Firefox 103.059354firefox",
  "Firefox 103.059415firefox",
  "Firefox 103.059570firefox",
  "Firefox 103.059666firefox",
  "Firefox 103.059673firefox",
  "Firefox 103.059753firefox",
  "Firefox 103.059796firefox",
  "Firefox 103.061266firefox",
  "Firefox 103.061750firefox",
  "Firefox 103.061978firefox",
  "Firefox 103.062510firefox",
  "Firefox 103.062767firefox",
  "Firefox 103.062774firefox",
  "Firefox 103.063303firefox",
  "Firefox 103.064008firefox",
  "Firefox 103.064945firefox",
  "Firefox 103.066286firefox",
  "Firefox 103.06653firefox",
  "Firefox 103.066848firefox",
  "Firefox 103.06703firefox",
  "Firefox 103.067333firefox",
  "Firefox 103.067610firefox",
  "Firefox 103.067645firefox",
  "Firefox 103.067873firefox",
  "Firefox 103.068720firefox",
  "Firefox 103.068740firefox",
  "Firefox 103.068846firefox",
  "Firefox 103.070685firefox",
  "Firefox 103.071476firefox",
  "Firefox 103.07215firefox",
  "Firefox 103.072632firefox",
  "Firefox 103.072763firefox",
  "Firefox 103.073571firefox",
  "Firefox 103.073885firefox",
  "Firefox 103.073978firefox",
  "Firefox 103.074617firefox",
  "Firefox 103.075600firefox",
  "Firefox 103.075831firefox",
  "Firefox 103.07627firefox",
  "Firefox 103.077261firefox",
  "Firefox 103.077309firefox",
  "Firefox 103.077388firefox",
  "Firefox 103.077718firefox",
  "Firefox 103.077829firefox",
  "Firefox 103.078106firefox",
  "Firefox 103.078172firefox",
  "Firefox 103.078426firefox",
  "Firefox 103.078745firefox",
  "Firefox 103.079427firefox",
  "Firefox 103.079500firefox",
  "Firefox 103.081119firefox",
  "Firefox 103.081314firefox",
  "Firefox 103.081644firefox",
  "Firefox 103.082851firefox",
  "Firefox 103.083191firefox",
  "Firefox 103.08351firefox",
  "Firefox 103.084464firefox",
  "Firefox 103.086406firefox",
  "Firefox 103.086526firefox",
  "Firefox 103.087100firefox",
  "Firefox 103.087262firefox",
  "Firefox 103.087489firefox",
  "Firefox 103.087864firefox",
  "Firefox 103.087870firefox",
  "Firefox 103.087960firefox",
  "Firefox 103.088496firefox",
  "Firefox 103.088634firefox",
  "Firefox 103.089060firefox",
  "Firefox 103.089341firefox",
  "Firefox 103.089597firefox",
  "Firefox 103.090591firefox",
  "Firefox 103.091182firefox",
  "Firefox 103.091605firefox",
  "Firefox 103.092781firefox",
  "Firefox 103.093513firefox",
  "Firefox 103.094162firefox",
  "Firefox 103.09496firefox",
  "Firefox 103.095100firefox",
  "Firefox 103.095328firefox",
  "Firefox 103.095395firefox",
  "Firefox 103.096337firefox",
  "Firefox 103.098367firefox",
  "Firefox 103.099356firefox",
  "Firefox 103.099427firefox",
  "Firefox 103.099763firefox",
  "Firefox 103.0esr",
  "Firefox 103.0esrfirefox",
  "Firefox 103.0firefox",
  "Firefox 104.0",
  "Firefox 105.0",
  "Firefox 106.0",
  "Firefox 107.0",
  "Firefox 108.0",
  "Firefox 109.0",
  "Firefox 11.0",
  "Firefox 110.0",
  "Firefox 111.0",
  "Firefox 111.0esr",
  "Firefox 112.0",
  "Firefox 112.0esr",
  "Firefox 113.0",
  "Firefox 113.6",
  "Firefox 114.0",
  "Firefox 114.0esr",
  "Firefox 114.2",
  "Firefox 114.4",
  "Firefox 114.5",
  "Firefox 115.0",
  "Firefox 115.0esr",
  "Firefox 116.0",
  "Firefox 116.0esr",
  "Firefox 117.0",
  "Firefox 117.0esr",
  "Firefox 118.0",
  "Firefox 119.0",
  "Firefox 119.0esr",
  "Firefox 12",
  "Firefox 120.0",
  "Firefox 120.0esr",
  "Firefox 121.0",
  "Firefox 121.0esr",
  "Firefox 122.0",
  "Firefox 123.0",
  "Firefox 123.0esr",
  "Firefox 15.0",
  "Firefox 16.0",
  "Firefox 2.0",
  "Firefox 20.0",
  "Firefox 20.6",
  "Firefox 26.0",
  "Firefox 28.0",
  "Firefox 3.0",
  "Firefox 3.1b2",
  "Firefox 3.5",
  "Firefox 3.6",
  "Firefox 31.0",
  "Firefox 33.0",
  "Firefox 38.0",
  "Firefox 4.0",
  "Firefox 40.0",
  "Firefox 41.0",
  "Firefox 43.0",
  "Firefox 45.0",
  "Firefox 45.63",
  "Firefox 45.70",
  "Firefox 45.74",
  "Firefox 46.0",
  "Firefox 47.0",
  "Firefox 48.0",
  "Firefox 49.0",
  "Firefox 50.0",
  "Firefox 51.0",
  "Firefox 52.0",
  "Firefox 52.3",
  "Firefox 52.4",
  "Firefox 52.6",
  "Firefox 52.7",
  "Firefox 52.9",
  "Firefox 53.0",
  "Firefox 54.0",
  "Firefox 55.0",
  "Firefox 56.0",
  "Firefox 56.2",
  "Firefox 57.0",
  "Firefox 58.0",
  "Firefox 59.0",
  "Firefox 60.0",
  "Firefox 60.8",
  "Firefox 61.0",
  "Firefox 62.0",
  "Firefox 63.0",
  "Firefox 64.0",
  "Firefox 65.0",
  "Firefox 66.0",
  "Firefox 67.0",
  "Firefox 68.0",
  "Firefox 69.0",
  "Firefox 70.0",
  "Firefox 71.0",
  "Firefox 72.0",
  "Firefox 73.0",
  "Firefox 74.0",
  "Firefox 75.0",
  "Firefox 76.0",
  "Firefox 77.0",
  "Firefox 78.0",
  "Firefox 79.0",
  "Firefox 80.0",
  "Firefox 81.0",
  "Firefox 82.0",
  "Firefox 83.0",
  "Firefox 83.1",
  "Firefox 84.0",
  "Firefox 84.1",
  "Firefox 85.0",
  "Firefox 85.1",
  "Firefox 86.0",
  "Firefox 86.1",
  "Firefox 87.0",
  "Firefox 88.0",
  "Firefox 89.0",
  "Firefox 90.0",
  "Firefox 91.0",
  "Firefox 92.0",
  "Firefox 93.0",
  "Firefox 94.0",
  "Firefox 95.0",
  "Firefox 96.0",
  "Firefox 96",
  "Firefox 97.0",
  "Firefox 98.0",
  "Firefox 99.0",
  "Firefox Focus 2.0",
  "Firefox Focus 3.0",
  "Firefox Focus 3.3",
  "Firefox Mobile ",
  "Firefox Mobile iOS 111",
  "GNOME Web 1.2",
  "IceDragon 65.0",
  "Internet Explorer 11.0",
  "Internet Explorer 12.0",
  "Internet Explorer 5.0",
  "Internet Explorer 5.01",
  "Internet Explorer 5.22",
  "Internet Explorer 6.0",
  "Internet Explorer 8.0",
  "Internet Explorer 9.0",
  "Internet Explorer N/A",
  "Iridium 2019.04",
  "Iridium 2019.11",
  "Iron ",
  "Kindle Browser 2.0",
  "Maxthon 5.1",
  "Maxthon 7.1",
  "Microsoft Edge 100.0",
  "Microsoft Edge 101.0",
  "Microsoft Edge 102.0",
  "Microsoft Edge 103.0",
  "Microsoft Edge 104.0",
  "Microsoft Edge 105.0",
  "Microsoft Edge 106.0",
  "Microsoft Edge 107.0",
  "Microsoft Edge 108.0",
  "Microsoft Edge 109.0",
  "Microsoft Edge 110.0",
  "Microsoft Edge 111.0",
  "Microsoft Edge 112.0",
  "Microsoft Edge 113.0",
  "Microsoft Edge 114.0",
  "Microsoft Edge 12.10240",
  "Microsoft Edge 12.246",
  "Microsoft Edge 13.10586",
  "Microsoft Edge 14.14263",
  "Microsoft Edge 14.14393",
  "Microsoft Edge 15.15063",
  "Microsoft Edge 15.7816",
  "Microsoft Edge 16.16299",
  "Microsoft Edge 17.17134",
  "Microsoft Edge 18.14383",
  "Microsoft Edge 18.17763",
  "Microsoft Edge 18.18362",
  "Microsoft Edge 18.18363",
  "Microsoft Edge 18.19041",
  "Microsoft Edge 18.19043",
  "Microsoft Edge 18.19045",
  "Microsoft Edge 18.19582",
  "Microsoft Edge 18.22000",
  "Microsoft Edge 18.22621",
  "Microsoft Edge 20",
  "Microsoft Edge 30",
  "Microsoft Edge 44.18363",
  "Microsoft Edge 78.0",
  "Microsoft Edge 79.0",
  "Microsoft Edge 80.0",
  "Microsoft Edge 81.0",
  "Microsoft Edge 83.17763",
  "Microsoft Edge 84.0",
  "Microsoft Edge 85.0",
  "Microsoft Edge 86.0",
  "Microsoft Edge 87.0",
  "Microsoft Edge 89.0",
  "Microsoft Edge 90.0",
  "Microsoft Edge 91.0",
  "Microsoft Edge 92.0",
  "Microsoft Edge 93.0",
  "Microsoft Edge 94.0",
  "Microsoft Edge 95.0",
  "Microsoft Edge 96.0",
  "Microsoft Edge 97.0",
  "Microsoft Edge 98.0",
  "Microsoft Edge 99.0",
  "Microsoft Edge N/A",
  "Microsoft Edge",
  "MIUI Browser 13.24",
  "Netscape 9.1",
  "Opera 1.170623",
  "Opera 10.9",
  "Opera 100.0",
  "Opera 12.14",
  "Opera 12.17",
  "Opera 28.0",
  "Opera 36.0",
  "Opera 48.0",
  "Opera 50.4",
  "Opera 54.0",
  "Opera 55.0",
  "Opera 56.1",
  "Opera 57.0",
  "Opera 58.0",
  "Opera 58.4",
  "Opera 58.7",
  "Opera 6.170623",
  "Opera 60.0",
  "Opera 60.3",
  "Opera 62.0",
  "Opera 65.2",
  "Opera 66.3",
  "Opera 69.0",
  "Opera 7.54",
  "Opera 70.0",
  "Opera 71.1",
  "Opera 72.3",
  "Opera 73.3",
  "Opera 74.0",
  "Opera 74.1",
  "Opera 74.2",
  "Opera 74.3",
  "Opera 75.0",
  "Opera 75.1",
  "Opera 75.2",
  "Opera 75.3",
  "Opera 75.4",
  "Opera 76.0",
  "Opera 78.0",
  "Opera 79.0",
  "Opera 80.0",
  "Opera 81.0",
  "Opera 82.0",
  "Opera 83.0",
  "Opera 84.0",
  "Opera 85.0",
  "Opera 86.0",
  "Opera 89.0",
  "Opera 9.02",
  "Opera 90.0",
  "Opera 91.0",
  "Opera 92.0",
  "Opera 93.0",
  "Opera 94.0",
  "Opera 95.0",
  "Opera 96.0",
  "Opera 97.0",
  "Opera 98.0",
  "Opera 99.0",
  "Opera Mobile 73.1",
  "Pale Moon 29.4",
  "Pale Moon 32.0",
  "Pale Moon 32.2",
  "Palm Blazer ",
  "Safari ",
  "Safari 10.1",
  "Safari 10.2",
  "Safari 10.3",
  "Safari 10.4",
  "Safari 10.5",
  "Safari 10.6",
  "Safari 10.7",
  "Safari 11.1",
  "Safari 11.3",
  "Safari 11.5",
  "Safari 11.6",
  "Safari 11.7",
  "Safari 12.2",
  "Safari 12.4",
  "Safari 12.6",
  "Safari 12.7",
  "Safari 13.0",
  "Safari 13.1",
  "Safari 13.2",
  "Safari 13.3",
  "Safari 13.4",
  "Safari 13.5",
  "Safari 13.6",
  "Safari 14.0",
  "Safari 14.1",
  "Safari 14.2",
  "Safari 14.3",
  "Safari 14.4",
  "Safari 14.5",
  "Safari 14.7",
  "Safari 14.8",
  "Safari 15.0",
  "Safari 15.1",
  "Safari 15.2",
  "Safari 15.3",
  "Safari 15.4",
  "Safari 15.6",
  "Safari 15.7",
  "Safari 16.1",
  "Safari 16.2",
  "Safari 16.3",
  "Safari 16.4",
  "Safari 16.5",
  "Safari 16.6",
  "Safari 17.0",
  "Safari 312.3",
  "Safari 4.0",
  "Safari 5.0",
  "Safari 5.1",
  "Safari 525.1",
  "Safari 533.3",
  "Safari 7.0",
  "Safari 8.0",
  "Safari 9.0",
  "Samsung Browser 10.1",
  "SeaMonkey 2.53",
  "Sogou Explorer 2",
  "UC Browser 13.4",
  "Vivaldi 2.0",
  "Vivaldi 2.4",
  "Vivaldi 6.1",
  "Waterfox 56.6",
  "Waterfox 91.10",
  "Waterfox 91.11",
  "Waterfox 91.3",
  "Waterfox 91.8",
  "Yandex Browser 19.3",
  "Yandex Browser 20.12",
  "Yandex Browser 20.3",
  "Yandex Browser 21.2",
  "Yandex Browser 21.3",
  "Yandex Browser 22.11",
  "Yandex Browser 22.3",
  "Yandex Browser 22.7",
  "Yandex Browser 22.8",
  "Yandex Browser 23.1",
  "Yandex Browser 23.3",
  "Yandex Browser 23.5",
  "Yandex Browser 23.7",
];

exports.Array = DESKTOP_BROWSERS;
