/* eslint-disable */

async function parse_csv(parse_data, file, retention_type) {
  return new Promise(async (resolve) => {
    const RESPONSE = {
      status: "fail",
      data: [],
      message: "",
    };
    const BUFFER = [];
    let fr = new FileReader();
    fr.readAsText(file);
    fr.onload = async function () {
      const lines = await normalize_lines(fr.result.split("\n"));
      let output = lines.slice(1).map((line) => {
        if (retention_type === "keep") {
          for (let x = 0; x < parse_data.length; x++) {
            if (line.includes(parse_data[x])) {
              BUFFER.push(line);
            }
          }
        }
        if (retention_type === "remove") {
          let found_line = false;
          for (let x = 0; x < parse_data.length; x++) {
            if (line.includes(parse_data[x])) {
              found_line = true;
            }
            if (x === parse_data.length - 1 && !found_line) {
              console.log(found_line);
              BUFFER.push(line);
            }
          }
        }

        if (retention_type === "" && !line.includes(parse_data[x])) {
          BUFFER.push(line);
        }
      });
      const FIXED_LINES = lines.filter((x) => x !== "" || x !== undefined);
      const header = await convert_headers(FIXED_LINES);
      const PARSED_OBJECT = BUFFER.map((line) => {
        const fixed_lines = line.replaceAll(", ", "; ");
        const fields = fixed_lines.split(",");
        const fixed_fields = fields.map((element) => {
          if (element === "") {
            return "-";
          }
          return element;
        });
        const response = Object.fromEntries(
          header.map((h, i) => [h, fixed_fields[i]])
        );
        return response;
      });
      console.log("PARSED_OBJECT: ", PARSED_OBJECT);
      RESPONSE.status = "success";
      RESPONSE.data = PARSED_OBJECT;
      resolve(RESPONSE);
    };
    fr.onerror = function () {
      RESPONSE.message = error;
      resolve(RESPONSE);
    };
  });
}

async function convert_csv_to_object(csv) {
  return new Promise(async (resolve) => {
    let fr = new FileReader();
    fr.readAsText(csv);
    fr.onload = async function () {
      const lines = await normalize_lines(fr.result.split("\n"));
      const FIXED_LINES = lines.filter((x) => x !== "" || x !== undefined);
      const header = await convert_headers(FIXED_LINES);
      let output = lines.slice(1).map((line) => {
        const fixed_lines = line.replaceAll(", ", "; ");
        const fields = fixed_lines.split(",");
        const fixed_fields = fields.map((element) => {
          if (element === "") {
            return "-";
          }
          return element;
        });
        const response = Object.fromEntries(
          header.map((h, i) => [h, fixed_fields[i]])
        );
        return response;
      });
      output = await fix_bools(output);
      resolve(output);
    };
  });
}

function create_object() {
  return new Promise(async (resolve) => {});
}

function convert_headers(lines) {
  return new Promise((resolve) => {
    let header = lines[0].split(",");
    for (let x = 0; x < header.length; x++) {
      header[x] = header[x].toLowerCase();
      header[x] = header[x].replace(" ", "_");
      header[x] = header[x].replace(" ", "_");
      header[x] = header[x].replace(" ", "_");
      header[x] = header[x].replace(/["|']/g, "");
      header[x] = header[x].replaceAll("\r", "");
      header[x] = header[x].replaceAll("\r", "");
      header[x] = header[x].replaceAll("\r", "");
      header[x] = header[x].replaceAll("\r", "");
      header[x] = header[x].replaceAll("\r", "");
      header[x] = header[x].replaceAll("\r", "");
      header[x] = header[x].replaceAll("\r", "");
      header[x] = header[x].replaceAll("\r", "");

      if (x === header.length - 1) resolve(header);
    }
  });
}

function normalize_lines(lines) {
  return new Promise((resolve) => {
    for (let x = 0; x < lines.length; x++) {
      lines[x] = lines[x].replace(/["|']/g, "");
      lines[x] = lines[x].replace(/["\r']/g, "");
      // lines[x] = lines[x].replace(/FALSE/g, false);
      // lines[x] = lines[x].replace(/false/g, false);
      // lines[x] = lines[x].replace(/TRUE/g, true);
      // lines[x] = lines[x].replace(/true/g, true);
      if (x === lines.length - 1) resolve(lines);
    }
  });
}

function fix_bools(output) {
  return new Promise((resolve) => {
    for (let x = 0; x < output.length; x++) {
      const keys = Object.keys(output[x]);
      if (keys.length > 0) {
        for (let y = 0; y < keys.length; y++) {
          if (output[x][keys[y]] === "TRUE") output[x][keys[y]] = true;
          if (output[x][keys[y]] === "true") output[x][keys[y]] = true;
          if (output[x][keys[y]] === "FALSE") output[x][keys[y]] = false;
          if (output[x][keys[y]] === "false") output[x][keys[y]] = false;
        }
      }
    }
    resolve(output);
  });
}

module.exports = { convert_csv_to_object, parse_csv, create_object };
