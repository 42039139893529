<template>
  <div>
    <Card class="mt-2 shadow-5">
      <template #content>
        <div>
          <div
            class="flex justify-content-center flex-wrap card-container mt-4 mb-3"
          >
            <div class="flex justify-content-left mr-4">
              <Tag
                severity="warning"
                class="pr-4 pl-4"
                :value="this.report_label"
              ></Tag>
            </div>
            <div
              v-for="(record, key) in this.totalRecords"
              :key="key"
              class="align-items-center justify-content-center ml-2 mr-2"
            >
              <Button
                label="Primary"
                text
                raised
                @click="update_page(key)"
                :class="this.is_selected(key)"
              >
                {{ record }}
              </Button>
            </div>
          </div>
          <Accordion :activeIndex="0" :multiple="true">
            <AccordionTab header="All Data">
              <DataTable
                :value="this.export_data"
                tableStyle="min-width: 50rem"
                ref="export_data_table"
                exportFilename="Browser Reports"
                paginator
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50, 100]"
                :class="`p-datatable-sm`"
                resizableColumns
                columnResizeMode="expand"
              >
                <div class="grid w-auto">
                  <div style="text-align: right">
                    <Button
                      icon="pi pi-external-link"
                      size="large"
                      link
                      @click="exportCSV($event)"
                    />
                  </div>
                  <h3 class="ml-3 mr-3" v-if="this.diff_enabled">
                    All Browser Data Reports : {{ this.report_label }} | Diff
                    Report:
                    {{ this.diff_report_label }}
                  </h3>
                  <h3 class="ml-3 mr-3" v-else>
                    All Browser Data Reports : {{ this.report_label }}
                  </h3>
                </div>
                <Column field="Browser" sortable header="Browser"></Column>
                <Column
                  sortable
                  field="Connection Type"
                  header="Connection Type"
                ></Column>
                <Column sortable field="key" header="KEY"></Column>
                <Column sortable field="total" header="Total"></Column>
                <Column
                  sortable
                  field="f90"
                  header="Total W/ Fraud 90 or Greater"
                ></Column>
                <Column
                  sortable
                  field="f80"
                  header="Total W/ Fraud 80-89"
                ></Column>
                <Column
                  field="diff_total"
                  header="Diff Totals"
                  sortable
                ></Column>
                <Column
                  field="diff_f90"
                  header="Diff Fraud 90 or Greater"
                ></Column>
                <Column field="diff_f80" header="Diff W/ Fraud 80-89"></Column>
              </DataTable>
            </AccordionTab>
          </Accordion>
        </div>
        <div>
          <Accordion
            :activeIndex="0"
            :multiple="true"
            v-for="(browser_type, id) in this.table_data"
            :key="id"
          >
            <AccordionTab
              v-for="(entry, id2) in browser_type"
              :key="id2"
              :header="id2"
            >
              <div>
                <div v-for="(metrics, id3) in entry" :key="id3">
                  <div v-if="parseInt(metrics.IP[0].total) > 0">
                    <Accordion :activeIndex="0" :multiple="true">
                      <AccordionTab :header="metrics.browser">
                        <div
                          class="card"
                          v-for="(metric, id4) in metrics"
                          :key="id4"
                        >
                          <DataTable
                            v-if="metric[0].total > 0 && id4 !== 'browser'"
                            :value="metric"
                            :rowHover="true"
                            tableStyle="min-width: 50rem"
                            :ref="metrics.browser + '.' + id4"
                            :exportFilename="
                              metrics.browser + '.' + id4 + ' Reports'
                            "
                            paginator
                            :rows="10"
                            :rowsPerPageOptions="[10, 20, 50, 100]"
                            :class="`p-datatable-sm`"
                            resizableColumns
                            columnResizeMode="expand"
                          >
                            <div class="grid w-auto">
                              <h3 class="ml-3 mr-3">
                                {{ metrics.browser }} {{ id4 }} Reports
                              </h3>
                            </div>
                            <Column field="key" header="KEY" sortable></Column>
                            <Column
                              field="total"
                              header="Total"
                              sortable
                            ></Column>
                            <Column
                              field="f90"
                              header="Total W/ Fraud 90 or Greater"
                              sortable
                            ></Column>
                            <Column
                              sortable
                              field="f80"
                              header="Total W/ Fraud 80-89"
                            ></Column>
                          </DataTable>
                        </div>
                      </AccordionTab>
                    </Accordion>
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
// @ is an alias to /src
import { toRaw } from "vue";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Tag from "primevue/tag";

export default {
  name: "BrowserMetricsCard",
  components: { Card, DataTable, Column, Button, Accordion, AccordionTab, Tag },
  data() {
    return {
      table_data: [],
      export_data: [],
      entry: 0,
      report_label: "",
      diff_report_label: "",
      diff_enabled: false,
      diff_table_data: [],
      diff_export_table_data: [],
    };
  },
  mounted() {
    if (this.$route.query.entry) {
      this.entry = this.$route.query.entry;
    }
    this.set_data();
  },
  // created: {},
  computed: {
    selected_data() {
      return toRaw(this.$store.getters.get_reports[this.entry]);
    },
    totalRecords() {
      return toRaw(this.$store.getters.get_reports.length);
    },
  },
  watch: {
    selected_data: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.set_data();
        }
      },
      deep: true,
    },
  },
  methods: {
    get_index(object_array, entry) {
      return new Promise((resolve) => {
        object_array.findIndex((element, key) => {
          if (element.ip_reports.label == entry) {
            resolve(key);
          }
        });
      });
    },
    calculate_diffs() {
      const KEYS = Object.keys(this.export_data);
      KEYS.forEach((key) => {
        if (this.diff_export_table_data[key]) {
          const ORIGINAL_TOTAL = this.export_data[key].total;
          const DIFF_TOTAL = this.diff_export_table_data[key].total;
          const DIFF_DIFFERENCE = ORIGINAL_TOTAL - DIFF_TOTAL;

          const ORIGINAL_F90 = this.export_data[key].f90;
          const ORIGINAL_F90_PERCENT =
            parseInt(Math.abs(ORIGINAL_F90 / ORIGINAL_TOTAL) * 100) || 0;

          const DIFF_F90 = this.diff_export_table_data[key].f90;
          const DIFF_F90_PERCENT =
            parseInt(Math.abs((DIFF_F90 / DIFF_TOTAL) * 100)) || 0;

          const ORIGINAL_F80 = this.export_data[key].f80;
          const ORIGINAL_F80_PERCENT =
            parseInt(Math.abs(ORIGINAL_F80 / ORIGINAL_TOTAL) * 100) || 0;

          const DIFF_F80 = this.diff_export_table_data[key].f80;
          const DIFF_F80_PERCENT =
            parseInt(Math.abs(DIFF_F80 / DIFF_TOTAL) * 100) || 0;

          this.export_data[key].diff_total = `${DIFF_DIFFERENCE}`;
          this.export_data[
            key
          ].diff_f90 = `${ORIGINAL_F90_PERCENT}% Vs. ${DIFF_F90_PERCENT}%`;
          this.export_data[
            key
          ].diff_f80 = `${ORIGINAL_F80_PERCENT}% Vs. ${DIFF_F80_PERCENT}%`;
        }
      });
    },
    update_page(page) {
      this.update_page_count();
      this.set_data();
      this.entry = page;
    },
    update_page_count() {
      if (toRaw(this.$store.getters.get_reports)) {
        this.totalRecords = toRaw(
          toRaw(this.$store.getters.get_reports)
        ).length;
      }
    },
    is_selected(key) {
      if (key === this.entry) {
        return " bg-primary";
      } else {
        return "";
      }
    },
    exportCSV() {
      this.$refs.export_data_table.exportCSV();
    },
    async set_data() {
      if (toRaw(this.$store.getters.get_reports).length > 0) {
        this.update_page_count();
        this.report_label = this.selected_data.ip_reports.label;
        this.table_data = [];
        const DATA_REPORT = toRaw(
          this.selected_data.ip_reports["DESKTOP_BROWSER_ARRAY"]
        );
        const MOBILE_REPORT = toRaw(
          this.selected_data.ip_reports["MOBILE_BROWSER_ARRAY"]
        );
        const OTHER_REPORT = toRaw(
          this.selected_data.ip_reports["OTHER_BROWSER_ARRAY"]
        );
        const DESKTOP_DATA = await this.build_table(false, DATA_REPORT);
        const MOBILE_DATA = await this.build_table(false, MOBILE_REPORT);
        const OTHER_DATA = await this.build_table(false, OTHER_REPORT);
        this.table_data.push({ "Desktop Browser Data": DESKTOP_DATA });
        this.table_data.push({ "Mobile Browser Data": MOBILE_DATA });
        this.table_data.push({ "Other Browser Data": OTHER_DATA });

        // Start diff calc
        this.diff_enabled = this.$store.getters.get_diff_table_info.enabled;
        if (this.diff_enabled) {
          const diff_table = toRaw(
            this.$store.getters.get_diff_table_info.tables
          );
          // Create diff table
          const ARRAYS = toRaw(this.$store.getters.get_reports);
          const index = await this.get_index(ARRAYS, diff_table);
          this.diff_report_label = toRaw(this.$store.getters.get_reports)[
            index
          ].ip_reports.label;
          const DIFF_DATA_REPORT = toRaw(
            toRaw(this.$store.getters.get_reports)[index].ip_reports[
              "DESKTOP_BROWSER_ARRAY"
            ]
          );
          const DIFF_MOBILE_REPORT = toRaw(
            toRaw(this.$store.getters.get_reports)[index].ip_reports[
              "MOBILE_BROWSER_ARRAY"
            ]
          );
          const DIFF_OTHER_REPORT = toRaw(
            toRaw(this.$store.getters.get_reports)[index].ip_reports[
              "OTHER_BROWSER_ARRAY"
            ]
          );
          const DIFF_DESKTOP_DATA = await this.build_table(
            true,
            DIFF_DATA_REPORT
          );
          const DIFF_MOBILE_DATA = await this.build_table(
            true,
            DIFF_MOBILE_REPORT
          );
          const DIFF_OTHER_DATA = await this.build_table(
            true,
            DIFF_OTHER_REPORT
          );
          this.diff_table_data.push({
            "Desktop Browser Data": DIFF_DESKTOP_DATA,
          });
          this.diff_table_data.push({
            "Mobile Browser Data": DIFF_MOBILE_DATA,
          });
          this.diff_table_data.push({ "Other Browser Data": DIFF_OTHER_DATA });
          this.calculate_diffs();
        }
      }
    },
    build_table(is_diff, DATA) {
      return new Promise((resolve) => {
        const TABLE = [];
        DATA.forEach(async (key) => {
          if (key !== "IP" && key !== "CONNECTION_TYPE") {
            const REPORT_KEYS = Object.keys(key.IP);
            const IP = await this.get_key_values(
              REPORT_KEYS,
              key.IP,
              key.browser,
              "All Connection Types",
              is_diff
            );
            const RESI = await this.get_key_values(
              REPORT_KEYS,
              key.CONNECTION_TYPE.residential,
              key.browser,
              "Residential",
              is_diff
            );
            const DATA_CENTER = await this.get_key_values(
              REPORT_KEYS,
              key.CONNECTION_TYPE.data_center,
              key.browser,
              "Data Center",
              is_diff
            );
            const MOBILE = await this.get_key_values(
              REPORT_KEYS,
              key.CONNECTION_TYPE.mobile,
              key.browser,
              "Mobile",
              is_diff
            );
            const CORPORATE = await this.get_key_values(
              REPORT_KEYS,
              key.CONNECTION_TYPE.corporate,
              key.browser,
              "Corporate",
              is_diff
            );
            const EDUCATION = await this.get_key_values(
              REPORT_KEYS,
              key.CONNECTION_TYPE.education,
              key.browser,
              "Education",
              is_diff
            );
            const ENTRY = {
              browser: key.browser,
              IP: IP,
              Residential: RESI,
              Mobile: MOBILE,
              Data_Center: DATA_CENTER,
              Corporate: CORPORATE,
              Education: EDUCATION,
            };
            TABLE.push(ENTRY);
          }
          resolve(TABLE);
        });
      });
    },

    get_key_values(keys, object, browser, connection, is_diff) {
      return new Promise((resolve) => {
        const TEMP_ARRAY = [];
        keys.forEach((key) => {
          TEMP_ARRAY.push(object[key]);
          object[key]["Browser"] = browser;
          object[key]["Connection Type"] = connection;
          if (is_diff) {
            this.diff_export_table_data.push(object[key]);
          } else {
            this.export_data.push(object[key]);
          }
        });
        resolve(TEMP_ARRAY);
      });
    },
  },
};
</script>

<style scoped>
.dataAlert {
  background-color: red;
}
.tableHover:hover {
  background-color: #e6e6e6;
}
</style>
