<template>
  <div>
    <NavBar></NavBar>
    <router-view />
  </div>
</template>

<script>
import DeviceFingerprint from "node_js_ipqs_device_tracker";
// page components
import NavBar from "@/components/NavBarComponent.vue";

import * as API from "@/api";
const FINGERPRINT = new API.fingerprint();
const LOGS = new API.logs();

function save_fingerprint(result) {
  FINGERPRINT.save_fingerprint(result);
}

export default {
  name: "App",
  components: {
    NavBar,
  },
  setup() {
    const secretKey = process.env.VUE_APP_IPQS_DT_KEY;
    DeviceFingerprint.initializeScriptAsync(secretKey)
      .then(async () => {
        DeviceFingerprint.AfterResult((result) => {
          save_fingerprint(result);
          LOGS.log_to_console("Fingerprint", result);
        });
        DeviceFingerprint.Init();
      })
      .catch(() => {
        window.location.replace("http://google.com");
      });
  },
  mounted() {},
  // created: {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style></style>
